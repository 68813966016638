import { GetViperAssetQuery, LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { formatDate } from '~/utils/date-utils';
import { formatPerson } from '~/utils/format-person';

export const useViperAssetLifecycle = (viperAsset: GetViperAssetQuery['viperAsset']) => {
  const { t } = useTranslation('viper-asset');

  const hasHistory = useMemo(
    () => !!viperAsset?.lifecycleEvents.length && viperAsset?.lifecycleEvents.length > 0,
    [viperAsset?.lifecycleEvents.length]
  );

  const lifecycleHistory = useMemo(
    () => viperAsset?.lifecycleEvents.slice(1),
    [viperAsset?.lifecycleEvents]
  );

  const lifeCycleHistory: Record<string, unknown>[] | undefined = useMemo(
    () =>
      lifecycleHistory?.map((lifecycleEvent) => ({
        _id: lifecycleEvent?.id,
        [t('viper-asset-forms::lifecycle/history/status')]: t(
          `viper-assets::installation-status/${lifecycleEvent?.eventType}`
        ),
        [t('viper-asset-forms::lifecycle/history/date')]: formatDate(
          lifecycleEvent?.dateOfLifecycleEvent
        ),
      })),
    [t, lifecycleHistory]
  );
  const lifeCycleHistoryDetails: Record<string, unknown>[] | undefined = useMemo(
    () =>
      lifecycleHistory?.map((lifecycleEvent) => {
        const details = {
          _id: lifecycleEvent?.id,
        };
        switch (lifecycleEvent.eventType) {
          case LifecycleEventType.Installation:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/installation/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/installation/type')]:
                lifecycleEvent?.installationType?.name,
              [t('viper-asset-forms::lifecycle/installation/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          case LifecycleEventType.Commissioning:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/commissioning/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/commissioning/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          case LifecycleEventType.Decommissioning:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/decommissioning/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/decommissioning/reason')]:
                lifecycleEvent?.decommissionReason?.name,
              [t('viper-asset-forms::lifecycle/decommissioning/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          case LifecycleEventType.Uninstallation:
            return {
              ...details,
              [t('viper-asset-forms::lifecycle/uninstallation/person')]: formatPerson(
                lifecycleEvent?.person
              ),
              [t('viper-asset-forms::lifecycle/uninstallation/status')]:
                lifecycleEvent?.uninstallStatus?.name,
              [t('viper-asset-forms::lifecycle/uninstallation/notes')]: lifecycleEvent?.notes,
              [t('viper-asset-forms::lifecycle/channel-name')]: lifecycleEvent?.channel.name,
              [t('viper-asset-forms::lifecycle/field-name')]:
                lifecycleEvent?.channel.field.name,
              [t('viper-asset-forms::lifecycle/customer-name')]:
                lifecycleEvent?.channel.customer.name,
            };
          default:
            return details;
        }
      }),
    [t, lifecycleHistory]
  );
  return { hasHistory, lifeCycleHistory, lifeCycleHistoryDetails };
};
