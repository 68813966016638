import { DataGrid } from '~/components/DataGrid';
import { FilterToolbar } from '~/features/licence-insights/components/FilterToolbar';
import { useState } from 'react';
import { useLicenceInsightsTable } from '~/features/licence-insights/hooks/useLicenceInsightsTable';
import { useFilterCounts } from '~/features/licence-insights/hooks/useFilterCounts';
import { useTranslation } from 'react-i18next';
import { DateRange } from '~/common/types';
import { ToolbarFilters } from '~/features/licence-insights/types';

interface LicenceInsightsTableProps {
  evaluationPeriod: DateRange;
}
export const LicenceInsightsTable = ({ evaluationPeriod }: LicenceInsightsTableProps) => {
  const { t } = useTranslation('licence-insights');

  const [activeFilters, setActiveFilters] = useState<ToolbarFilters>({
    gains: false,
    attrition: false,
    flaggedForDecommission: false,
  });

  const { rows, columns, loading } = useLicenceInsightsTable(evaluationPeriod, activeFilters);

  const filterCounts = useFilterCounts(rows, evaluationPeriod);

  return (
    <DataGrid
      loading={loading}
      statePersistencyId='licence-insights'
      columns={columns}
      rows={rows}
      initialColumnVisibilityModel={{
        id: false,
      }}
      localeText={{
        noRowsLabel: t('no-v-life-licences'),
        toolbarQuickFilterPlaceholder: t('search-v-life-licences'),
      }}
      pageSizeOptions={[5, 10, 25, 50, 100]}
      slotProps={{
        toolbar: {
          activeFilters,
          setActiveFilters,
          filterCounts,
        },
      }}
      slots={{
        toolbar: FilterToolbar,
      }}
    />
  );
};
