import { GridCellParams, GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';

import { useVLifeLicencesFilterOperators } from '~/components/DataGrid/filters/custom/v-life-licences/filter-operators';
import { StatusPill } from '~/components/DataGrid/components/StatusPill';
import { getDate } from '~/components/DataGrid/value-getters';
import { formatBoolean } from '~/components/DataGrid/value-formatters';
import { LicenceInsightsRow } from '~/features/licence-insights/types';
import { GridValueGetterParams } from '@mui/x-data-grid';
import { VLifeStatus } from '~/graphql/generated/asset/graphql';

export const useLicenceInsightsColumns = () => {
  const { t } = useTranslation('licence-insights');

  const {
    negatableNumberFilterOperators,
    negatableStringFilterOperators,
    negatableDateFilterOperators,
    negatableBooleanFilterOperators,
  } = useNegatableFilterOperators();

  const { vLifeStatusFilterOperators } = useVLifeLicencesFilterOperators();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('id'),
        minWidth: 50,
        maxWidth: 100,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        field: 'channelId',
        headerName: t('channel-id'),
        minWidth: 100,
        flex: 1,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        field: 'channelName',
        headerName: t('channel'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('v-life-status'),
        renderCell: ({ value }: GridCellParams<LicenceInsightsRow, VLifeStatus>) => {
          if (value === undefined) {
            return <></>;
          }
          return <StatusPill status={value} />;
        },
        minWidth: 100,
        flex: 1,
        filterOperators: vLifeStatusFilterOperators,
      },
      {
        field: 'startDate',
        headerName: t('start-date'),
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'endDate',
        headerName: t('end-date'),
        type: 'date',
        valueGetter: getDate,
        filterOperators: negatableDateFilterOperators,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'gain',
        headerName: t('gain'),
        type: 'boolean',
        width: 100,
        flex: 1,
        valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        field: 'attrition',
        headerName: t('attrition'),
        type: 'boolean',
        width: 100,
        flex: 1,
        valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        field: 'flaggedForDecommission',
        headerName: t('flagged-for-decommission'),
        type: 'boolean',
        width: 100,
        flex: 1,
        valueFormatter: formatBoolean.bind({}, t('csv::yes'), t('csv::no')),
        filterOperators: negatableBooleanFilterOperators,
      },
      {
        field: 'flaggedForDecommissionDate',
        headerName: t('likely-decommission-date'),
        type: 'date',
        valueGetter: (params: GridValueGetterParams<LicenceInsightsRow>) => {
          const date = params.row.flaggedForDecommissionDate;
          if (date) {
            return new Date(date);
          }
          return undefined;
        },
        filterOperators: negatableDateFilterOperators,
        minWidth: 100,
        flex: 1,
      },
      {
        field: 'customerName',
        headerName: t('customer'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'fieldName',
        headerName: t('field'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        field: 'platformName',
        headerName: t('platform'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
    ],
    [
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      vLifeStatusFilterOperators,
      negatableDateFilterOperators,
      negatableBooleanFilterOperators,
    ]
  );
  return { columns };
};
