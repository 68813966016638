import { getPcsEditSchema } from '~/features/customer-asset/form-schemas/edit/pcs/pcsEditSchema';
import { TFunction } from 'i18next';
import { GetCreatePcsOptionsQuery } from '~/graphql/generated/asset/graphql';

export const getPcsCreateSchema = ({
  t,
  manufacturers,
  electricalDistributionTypes,
  electricalTopologies,
  platformName,
  editing,
}: {
  t: TFunction;
  manufacturers?: GetCreatePcsOptionsQuery['manufacturers'];
  electricalDistributionTypes?: GetCreatePcsOptionsQuery['electricalDistributionTypes'];
  electricalTopologies?: GetCreatePcsOptionsQuery['electricalTopologies'];
  platformName?: string;
  editing?: string;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-platform-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'createPlatform',
            title: t('platforms::create-platform'),
            fields: getPcsEditSchema({
              t,
              manufacturers,
              electricalDistributionTypes,
              electricalTopologies,
              platformName,
              editing,
            }).fields,
          },
        ],
      },
    ],
  };
};
