import React from 'react';
import { componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import { useTranslation } from 'react-i18next';
import { extraComponents } from '~/components/Form/utils/extraComponents';
import { Comments } from '~/components/Form/formFields/Comments/Comments';
import { CreateEditEntityDialog } from '~/components/CreateEditEntityDialog/CreateEditEntityDialog';
import {
  useAddVLifeReportDetailComment,
  useGetVLifeReportDetailComments,
} from '~/features/v-life-reports/queries/v-life-reports';

export const VLifeCommentsDialog: React.FC<{
  vlifeReportFormId: string | undefined;
  onClose: () => void;
}> = ({ onClose, vlifeReportFormId }) => {
  const { t } = useTranslation('v-life-reports');

  return (
    <CreateEditEntityDialog
      open={!!vlifeReportFormId}
      onClose={onClose}
      getSchema={({ entity, t }) => {
        return [
          {
            name: 'comments',
            component: extraComponents.CUSTOM,
            child: <Comments />,
            initialValue: entity?.vlifeReportForm?.comments,
            sx: { gridColumnEnd: 'span 2' },
          },
          {
            name: 'new-comment',
            label: t('v-life-reports::new-comment'),
            isRequired: true,
            validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
            component: componentTypes.TEXTAREA,
            sx: { gridColumnEnd: 'span 2' },
          },
        ];
      }}
      errorMessage={t('comment-failed')}
      title={t('internal-discussion')}
      handleMutate={async (create, values) => {
        create({
          variables: {
            // vlifeReportFormId is always set when the dialog is open
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            vlifeReportFormId: vlifeReportFormId!,
            comment: values['new-comment'] as string,
          },
        });
        return 'ok';
      }}
      successMessage={() => t('comment-added')}
      useMutate={useAddVLifeReportDetailComment}
      useMutateOptions={{}}
      confirmText={t('add-comment')}
      cancelText={t('viper::close')}
      entityId={vlifeReportFormId}
      useGetEntity={useGetVLifeReportDetailComments}
    ></CreateEditEntityDialog>
  );
};
