import {
  componentTypes,
  dataTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetCreateChannelOptionsQuery } from '~/graphql/generated/asset/graphql';

interface GetImdInterfaceConfigurationCreateSchemaProps {
  t: TFunction;
  poweringDevices: GetCreateChannelOptionsQuery['poweringDevices'];
  relayConfigurations: GetCreateChannelOptionsQuery['relayConfigurations'];
  tripAlarmRelays: GetCreateChannelOptionsQuery['tripAlarmRelays'];
  interfaceCards: GetCreateChannelOptionsQuery['interfaceCards'];
}

export function getImdInterfaceConfigurationCreateSchema({
  t,
  poweringDevices,
  relayConfigurations,
  tripAlarmRelays,
  interfaceCards,
}: GetImdInterfaceConfigurationCreateSchemaProps) {
  return {
    fields: [
      {
        name: 'alarm1Value',
        label: t('customer-asset-forms::alarm1-value'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'Ω',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('customer-asset-forms::alarm1-value-message'),
          },
        ],
      },
      {
        name: 'alarm2Value',
        label: t('customer-asset-forms::alarm2-value'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'Ω',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('customer-asset-forms::alarm2-value-message'),
          },
        ],
      },
      {
        name: 'tripAlarmRelayId',
        label: t('customer-asset-forms::trip-alarm-relay'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: tripAlarmRelays,
      },
      {
        name: 'relayConfigurationId',
        label: t('customer-asset-forms::relay-configuration'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: relayConfigurations,
      },
      {
        name: 'couplerUsed',
        label: t('customer-asset-forms::coupler-used'),
        component: componentTypes.TEXT_FIELD,
      },
      {
        name: 'interfaceCardId',
        label: t('customer-asset-forms::interface-card'),
        helperText: t('customer-asset-forms::interface-card-helper'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: interfaceCards,
      },
      {
        name: 'poweringDeviceId',
        label: t('customer-asset-forms::powering-device'),
        sx: { gridColumnEnd: 'span 2' },
        isClearable: true,
        component: componentTypes.SELECT,
        options: poweringDevices,
      },
      {
        name: 'negativeImpactOnRebootNotes',
        label: t('customer-asset-forms::negative-impact-on-reboot-notes'),
        helperText: t('customer-asset-forms::negative-impact-on-reboot-notes-helper'),
        component: componentTypes.TEXTAREA,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
}
