import { useMemo } from 'react';
import { LicenceInsightsRow, ToolbarFilters } from '~/features/licence-insights/types';
import { DateRange } from '~/common/types';
import {
  attritionFallsWithinEvaluationPeriod,
  flaggedForDecommissionFallsWithinEvaluationPeriod,
  gainFallsWithinEvaluationPeriod,
} from '~/features/licence-insights/utils/utils';
import { VLifeStatus } from '~/graphql/generated/asset/graphql';

interface UseFilterRowsProps {
  rows: LicenceInsightsRow[];
  evaluationPeriod: DateRange;
  filters: ToolbarFilters;
}

export const useFilterRows = ({ rows, evaluationPeriod, filters }: UseFilterRowsProps) => {
  // Active statuses to include
  const activeStatuses = useMemo(
    () => [
      VLifeStatus.ActivePaid,
      VLifeStatus.ActiveTrial,
      VLifeStatus.ActiveFoc,
      VLifeStatus.ActiveNonRefundable,
    ],
    []
  );

  // Filter out non-active rows
  const activeRows = useMemo(
    () => rows.filter((row) => activeStatuses.includes(row.status)),
    [activeStatuses, rows]
  );

  // Filter out rows that don't fall in date range
  const evaluationStartDate = new Date(evaluationPeriod.startDate);
  const evaluationEndDate = new Date(evaluationPeriod.endDate);

  const evaluationPeriodRows = activeRows.filter((row) => {
    const parsedStartDate = new Date(row.startDate ?? '');
    const parsedEndDate = new Date(row.endDate ?? '');

    return (
      row.startDate &&
      parsedStartDate.getTime() <= evaluationEndDate.getTime() &&
      row.endDate &&
      parsedEndDate.getTime() >= evaluationStartDate.getTime()
    );
  });

  // Filter out if gains / attrition / flagged for decommission filters are active
  const filteredRows = evaluationPeriodRows.filter((row) => {
    let leftIn = true;
    if (filters.gains && leftIn) {
      leftIn = gainFallsWithinEvaluationPeriod(row, evaluationPeriod);
    }
    if (filters.attrition && leftIn) {
      leftIn = attritionFallsWithinEvaluationPeriod(row, evaluationPeriod);
    }
    if (filters.flaggedForDecommission && leftIn) {
      leftIn = flaggedForDecommissionFallsWithinEvaluationPeriod(row);
    }
    return leftIn;
  });

  return {
    filteredRows,
  };
};
