import { useState } from 'react';
import { usePendingVLifeReports } from '../hooks/usePendingVLifeReports';
import { DataGrid } from '~/components/DataGrid';
import { Toast } from '~/components/Toast/Toast';
import { useTranslation } from 'react-i18next';

// t('v-life-reports::customer');
// t('v-life-reports::field');
// t('v-life-reports::channel');
// t('v-life-reports::latestReportDate');
// t('v-life-reports::action');

export const PendingVLifeReports: React.FC = () => {
  const { t } = useTranslation('v-life-reports');
  const { columns, rows, displayModel, loading, error } = usePendingVLifeReports();
  const [closeError, setCloseError] = useState(false);

  return (
    <>
      <Toast
        severity='error'
        open={Boolean(error) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <DataGrid
        initialColumnVisibilityModel={displayModel}
        columns={columns}
        rows={rows}
        loading={loading}
        statePersistencyId='pending-v-life-reports'
      />
    </>
  );
};
