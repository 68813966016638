import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { extraValidatorTypes } from '~/components/Form/utils/validators';
import { GetDisableVLifeConfigOptionsQuery } from '~/graphql/generated/asset/graphql';

interface GetSchemaProps {
  t: TFunction;
  options?: GetDisableVLifeConfigOptionsQuery;
  editing?: string;
}

export function getVLifeConfigSchemaEditSchema({ t, options }: GetSchemaProps): Schema {
  const schema = {
    fields: [
      {
        name: '__isSuspended',
        label: t('vlife-forms::is-suspended'),
        component: componentTypes.CHECKBOX,
        initialValue: false,
        ignoreSave: true,
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        name: 'disableDate',
        component: componentTypes.DATE_PICKER,
        label: t('vlife-forms::disable-date'),
        isNotRequiredOnTheBackEnd: true,
        isRequired: true,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
      },
      {
        name: 'deactivationReasonId',
        label: t('vlife-forms::deactivation-reason'),
        component: componentTypes.SELECT,
        isRequired: true,
        isNotRequiredOnTheBackEnd: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: options?.deactivationReasons,
        condition: { when: '__isSuspended', is: false },
      },
      {
        name: 'resumptionDate',
        component: componentTypes.DATE_PICKER,
        label: t('vlife-forms::resumption-date'),
        condition: { when: '__isSuspended', is: true },
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        name: 'notes',
        label: t('vlife-forms::general-notes'),
        component: componentTypes.TEXTAREA,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
  return schema;
}
