import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_EDIT } from '~/components/ActionMenu/ActionMenu';
import { mapValuesToVariables } from '~/components/Form/utils/map-variables';
import { withErrorHandling } from '~/components/Form/SaveError/SaveError';
import {
  useGetContact,
  useGetCreateContactOptions,
  useUpdateContact,
} from '~/features/contacts/queries/contact';
import { useGetContacts } from '~/features/contacts/queries/contact';
import { formatPerson } from '~/utils/format-person';
import { InputMaybe, PersonUpdateInput } from '~/graphql/generated/asset/graphql';
import { getContactEditSchema } from '../form-schemas/edit/contactEditSchema';

export type ContactRowData = {
  _id: string;
  name: string;
  company: string;
  email: string;
  primaryTelephone: string | null | undefined;
  secondaryTelephone: string | null | undefined;
  position: string | null | undefined;
};

export const useCompanies = () => {
  const { t } = useTranslation('companies');

  const { data: contacts, loading, error } = useGetContacts();
  const [closeError, setCloseError] = useState(false);
  const [editing, setEditing] = useState<string | undefined>();
  const [saving, setSaving] = useState(false);

  const {
    data: createContactOptions,
    loading: optionsLoading,
    error: optionsError,
  } = useGetCreateContactOptions();

  const {
    data: contact,
    loading: contactLoading,
    error: contactError,
  } = useGetContact(editing ?? '', {
    skip: !editing,
  });

  const [updateContact, updateContactErrorState] = withErrorHandling(useUpdateContact());

  const formFields = useMemo(
    () =>
      getContactEditSchema({
        t,
        editing,
        contact: contact?.person,
        options: createContactOptions,
      }).fields,
    [contact?.person, createContactOptions, editing, t]
  );

  const data: ContactRowData[] = useMemo(() => {
    return (contacts?.people || []).slice().map((person) => ({
      _id: person.id,
      name: formatPerson(person),
      position: person.position,
      company: person.company.name,
      primaryTelephone: person.primaryTelephone,
      secondaryTelephone: person.secondaryTelephone,
      email: person.email,
    }));
  }, [contacts?.people]);

  const onClose = () => {
    setEditing(undefined);
  };

  const handleActionClick = (
    menuItemId: string,
    rowIndex: number,
    row: ContactRowData | undefined
  ) => {
    if (menuItemId === 'edit') {
      setEditing(row?._id);
    }
  };

  const onSave = useCallback(
    async (values: InputMaybe<PersonUpdateInput> | undefined) => {
      setSaving(true);
      try {
        if (contact?.person && values) {
          await updateContact({
            variables: {
              id: contact.person.id,
              personInput: mapValuesToVariables(formFields, values),
            },
          });
          setEditing(undefined);
        }
      } finally {
        setSaving(false);
      }
    },
    [contact?.person, formFields, updateContact]
  );

  const actionMenu = {
    label: 'actions',
    actionMenu: [ACTION_EDIT],
  };

  return {
    data,
    loading: loading || optionsLoading,
    contactLoading,
    error,
    optionsError,
    contactError,
    closeError,
    setCloseError,
    handleActionClick,
    actionMenu,
    editing,
    onClose,
    createContactOptions,
    updateContactErrorState,
    formFields,
    onSave,
    saving,
  };
};
