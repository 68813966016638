import { useMemo } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import { BarChart, TableChart, PieChart } from '@mui/icons-material';
import { IconToggleButtons } from '~/components/IconToggleButtons/IconToggleButtons';
import { useTranslation } from 'react-i18next';
import { TabPanel } from '~/components/TabPanel/TabPanel';
import { NivoPie } from '../charts/NivoPie';
import { Table } from '~/components/Table/Table';
import { NivoBar } from '../charts/NivoBar';
import { BarTooltip, PieTooltip } from '../charts/ChartLabels';
import { DefaultRawDatum, PieTooltipProps } from '@nivo/pie';
import { BarDatum, BarTooltipProps } from '@nivo/bar';
import { useWidgetCard } from '~/features/dashboard/hooks/useWidgetCard';

export interface TableData {
  label: string;
  value: string | number;
}

interface WidgetCardProps {
  loading: boolean;
  title: string;
  data?: Record<string, unknown>;
  indexBy: string;
  dataKey: string;
  defaultChartType?: ChartType;
  dataTypes?: [string, string];
}

export enum ChartType {
  PIE,
  BAR,
  TABLE,
}

export const WidgetCard: React.FC<WidgetCardProps> = ({
  loading,
  data,
  title,
  indexBy,
  dataKey,
  dataTypes,
  defaultChartType,
}) => {
  const { t } = useTranslation('dashboard');
  const buttons: { type: ChartType; Component: React.ElementType; label?: string }[] = useMemo(
    () => [
      { type: ChartType.PIE, Component: PieChart, label: t('pie-chart') },
      { type: ChartType.BAR, Component: BarChart, label: t('bar-chart') },
      { type: ChartType.TABLE, Component: TableChart, label: t('table-chart') },
    ],
    [t]
  );

  const {
    chartType,
    handleTabChange,
    handleToggleChange,
    pieData,
    barData,
    tableData,
    tabId,
    pieColors,
    barColors,
  } = useWidgetCard({ defaultChartType, data, dataTypes, indexBy, dataKey });

  return (
    <Card
      sx={{
        px: 2,
        pt: 1.5,
        pb: 0,
        gridColumnEnd: {
          xs: 'span 6',
          xl: 'span 2',
          placeSelf: 'stretch',
        },
      }}
    >
      <CardHeader title={title} sx={{ pb: 0, m: 0, mb: 3 }} component='h2' />
      <CardContent
        sx={{
          pt: 0,
        }}
      >
        <Box
          alignItems='flex-end'
          justifyContent='space-between'
          width='100%'
          display='flex'
          sx={{
            borderBottom: '1px solid',
            borderBottomColor: 'grey.500',
            mb: 3,
          }}
        >
          <Tabs
            value={tabId}
            onChange={handleTabChange}
            aria-label={`tab-${tabId}`}
            sx={{ borderBottom: 0 }}
          >
            <Tab label={t('paid')} />
            <Tab label={t('active')} />
          </Tabs>
          <IconToggleButtons
            buttons={buttons}
            onChange={handleToggleChange}
            activeType={chartType}
          />
        </Box>

        <Box
          sx={{ backgroundColor: 'white' }}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <TabPanel
                value={chartType}
                index={0}
                data-testid={`pie-${tabId}`}
                key='pie-panel'
              >
                <Box sx={{ width: 500, height: 300 }}>
                  <NivoPie
                    data={pieData}
                    colors={pieColors}
                    tooltip={(props: PieTooltipProps<DefaultRawDatum>) => (
                      <PieTooltip {...props} allData={pieData} />
                    )}
                  />
                </Box>
              </TabPanel>
              <TabPanel
                value={chartType}
                index={1}
                data-testid={`bar-${tabId}`}
                key='bar-panel'
              >
                <Box sx={{ width: 400, height: 300 }}>
                  <NivoBar
                    data={barData}
                    keys={[dataKey]}
                    indexBy={indexBy}
                    colorBy='indexValue'
                    showLegends={false}
                    showBottomAxis={false}
                    tooltip={(props: BarTooltipProps<BarDatum>) => (
                      <BarTooltip {...props} allData={barData} />
                    )}
                    colors={barColors}
                  />
                </Box>
              </TabPanel>
              <TabPanel
                value={chartType}
                index={2}
                data-testid={`table-${tabId}`}
                key='table-panel'
              >
                <Table data={tableData} muted omittedKeys={['_id']} itemsPerPage={3} />
              </TabPanel>
            </>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};
