import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../../../../components/CreateEditEntityDialog/CreateEditEntityDialog';
import { getCustomerAssetMaintenanceLogCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/customerAssetMaintenanceLogCreateSchema';
import { formatDate } from '~/utils/date-utils';
import {
  useCreateCustomerAssetMaintenanceLog,
  useGetCustomerAssetMaintenanceLogOptions,
} from '~/features/customer-asset/queries/customer-asset';

export const CreateCustomerAssetMaintenanceLogDialog: React.FC<{
  id: string;
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose, id }) => {
  const { t } = useTranslation('customer-asset');

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-maintenance-log')}
      errorMessage={t(`error-creating-maintenance-log`)}
      successMessage={(log) => t('maintenance-log-created-successfully', { log })}
      useGetOptions={useGetCustomerAssetMaintenanceLogOptions}
      useMutate={useCreateCustomerAssetMaintenanceLog}
      useMutateOptions={{ id }}
      getSchema={({ t, options }) =>
        getCustomerAssetMaintenanceLogCreateSchema({ t, options }).fields[0].fields[0].fields
      }
      handleMutate={async (create, values) => {
        await create({
          variables: {
            customerAssetMaintenanceLog: {
              channelId: id,
              affectedViperAssetIds: values.affectedViperAssetIds as string[],
              maintenanceStartDate: values.maintenanceStartDate as string,
              maintenanceEndDate: values.maintenanceEndDate as string,
              maintenanceTypeId: values.maintenanceTypeId as string,
              personId: values.personId as string,
              outcomeOfMaintenance: values.outcomeOfMaintenance as string,
            },
          },
        });
        return formatDate(values.maintenanceEndDate as string) as string;
      }}
      validate={({ maintenanceEndDate, maintenanceStartDate }) => {
        if (
          maintenanceEndDate &&
          maintenanceStartDate &&
          maintenanceEndDate < maintenanceStartDate
        ) {
          return {
            maintenanceEndDate: t('end-date-must-be-after-start-date'),
          };
        }
        return undefined;
      }}
    />
  );
};
