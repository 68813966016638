import { useGetInstalledViperAssetPeriodAndMaxIRCaps } from '~/features/customer-asset/queries/customer-asset';
import { useGetStringReadingsForSerialNumbers } from '~/features/v-logs/queries/readings-measurement';
import { StringMeasurementType, StringReading } from '~/graphql/generated/measurement/graphql';
import { VLifeReportLogMessage } from '~/graphql/generated/asset/graphql';

export interface UseGetVLifeEventsForChannelPeriodProps {
  channelId?: string;
  startDate?: string;
  endDate?: string;
}

export interface UseGetVLifeEventsForChannelPeriodResponse {
  loading: boolean;
  data: VLifeReportLogMessage[];
}

export const useGetVLifeEventsForChannelPeriod = ({
  channelId,
  startDate,
  endDate,
}: UseGetVLifeEventsForChannelPeriodProps): UseGetVLifeEventsForChannelPeriodResponse => {
  const { loading: loadingViperAssets, viperAssetPeriods } =
    useGetInstalledViperAssetPeriodAndMaxIRCaps(channelId, startDate, endDate);
  const { data: stringReadings, loading: loadingStringReadings } =
    useGetStringReadingsForSerialNumbers(viperAssetPeriods, [
      StringMeasurementType.Message,
      StringMeasurementType.Softwareversion,
    ]);

  const mappedData = (stringReadings?.getStringReadingsForAssets ?? []).map(
    (stringReading: StringReading, index): VLifeReportLogMessage => ({
      id: `${index}`,
      message: stringReading.value ?? '',
      date: stringReading.time,
    })
  );

  const nonBlankData = mappedData.filter((event) => event.message.trim().length > 0);

  return {
    loading: loadingViperAssets || loadingStringReadings,
    data: nonBlankData,
  };
};
