import React from 'react';
import { Pill } from '~/components/Pill/Pill';
import { LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { useTranslation } from 'react-i18next';

const colors: Record<
  string,
  'error' | 'success' | 'info' | 'warning' | 'primary' | 'secondary' | 'disabled' | undefined
> = {
  [LifecycleEventType.Installation]: 'secondary',
  [LifecycleEventType.Commissioning]: 'success',
  [LifecycleEventType.Decommissioning]: 'warning',
  [LifecycleEventType.Uninstallation]: 'disabled',
};

export const LifecyclePill: React.FC<{ eventType: string | undefined }> = ({
  eventType: eventTypeId,
}) => {
  const { t } = useTranslation('viper-assets');

  // t('viper-assets::installation-status/INSTALLATION')
  // t('viper-assets::installation-status/COMMISSIONING')
  // t('viper-assets::installation-status/DECOMMISSIONING')
  // t('viper-assets::installation-status/UNINSTALLATION')

  return eventTypeId ? (
    <Pill label={t(`installation-status/${eventTypeId}`)} iconColor={colors[eventTypeId]} />
  ) : null;
};
