import { useTranslation } from 'react-i18next';
import { useGetInstalledViperAssetPeriods } from '~/features/customer-asset/queries/customer-asset';
import { useMemo } from 'react';
import { buildPath, Path } from '~/routes/paths';
import { WarningLink } from '~/components/WarningLink/WarningLink';
import { WarningListResponse } from '~/features/v-life-reports/hooks/types';
import { formatDate } from '~/utils/date-utils';

export const useGetViperAssetWarnings = (
  channelId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined
): WarningListResponse => {
  const { t } = useTranslation('warnings');

  const { data, loading } = useGetInstalledViperAssetPeriods(channelId, startDate, endDate);

  const warnings = useMemo<WarningLink[]>((): WarningLink[] => {
    if (loading) {
      return [];
    }

    if (data?.channel?.installedViperAssetPeriods.length === 0) {
      return [
        {
          id: 'no-installed-viper-assets',
          warningMessage: t('no-installed-viper-assets', {
            start: formatDate(startDate),
            end: formatDate(endDate),
          }),
        },
      ];
    }

    return (data?.channel?.installedViperAssetPeriods ?? [])
      .filter(
        (viperAssetPeriod) =>
          !viperAssetPeriod.viperAsset.maxIrCap ||
          !viperAssetPeriod.viperAsset.currentFirmwareRevision
      )
      .map(
        (viperAssetPeriod): WarningLink => ({
          id: viperAssetPeriod.viperAsset.id,
          warningMessage: t('missing-values-in-viper-asset'),
          link: {
            text: `${t('link-to-viper-asset')} ${viperAssetPeriod.viperAsset.serialNumber}`,
            url: buildPath(Path.VIPER_ASSET, { id: viperAssetPeriod.viperAsset.id }),
          },
        })
      );
  }, [t, data, loading, startDate, endDate]);

  return {
    warnings,
    loading,
  };
};
