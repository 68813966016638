import { Schema } from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetCreateChannelOptionsQuery } from '~/graphql/generated/asset/graphql';
import { getIdentificationCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/getIdentificationCreateSchema/getIdentificationCreateSchema';
import { getElectricalCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/getElectricalCreateSchema/getElectricalCreateSchema';
import { getImdInterfaceConfigurationCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/getImdInterfaceConfigurationCreateSchema/getImdInterfaceConfigurationCreateSchema';
import { getSystemInformationCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/getSystemInformationCreateSchema/getSystemInformationCreateSchema';
import { SelectActionHandler } from '~/components/Form/formFields/select-action-handler';

interface GetCustomerAssetCreateSchemaProps {
  t: TFunction;
  createOptions: GetCreateChannelOptionsQuery;
  onAddField: SelectActionHandler;
  onAddPlatform: SelectActionHandler;
  onAddUmbilical: SelectActionHandler;
  onAddProductionControlSystem: SelectActionHandler;
}

export function getCustomerAssetCreateSchema({
  t,
  createOptions,
  onAddProductionControlSystem,
  onAddUmbilical,
  onAddField,
  onAddPlatform,
}: GetCustomerAssetCreateSchemaProps): Schema {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-customer-wizard',
        fields: [
          {
            name: 'identification',
            title: t('customer-asset-forms::identification'),
            nextStep: 'systemInformation',
            fields: getIdentificationCreateSchema({
              t,
              fields: createOptions.fields,
              customers: createOptions.customers,
              onAddField,
            }).fields,
          },
          {
            name: 'systemInformation',
            title: t('customer-asset-forms::system-information'),
            nextStep: 'electrical',
            fields: getSystemInformationCreateSchema({
              t,
              umbilicals: createOptions.umbilicals,
              platforms: createOptions.platforms,
              productionControlSystems: createOptions.productionControlSystems,
              onAddPlatform,
              onAddProductionControlSystem,
              onAddUmbilical,
            }).fields,
          },
          {
            name: 'electrical',
            title: t('customer-asset-forms::electrical'),
            nextStep: 'imdInterfaceConfiguration',
            fields: getElectricalCreateSchema({
              t,
              couplingTypes: createOptions.couplingTypes,
            }).fields,
          },
          {
            name: 'imdInterfaceConfiguration',
            title: t('customer-asset-forms::imd-interface-configuration'),
            fields: getImdInterfaceConfigurationCreateSchema({
              t,
              poweringDevices: createOptions.poweringDevices,
              relayConfigurations: createOptions.relayConfigurations,
              tripAlarmRelays: createOptions.tripAlarmRelays,
              interfaceCards: createOptions.interfaceCards,
            }).fields,
          },
        ],
      },
    ],
  };
}
