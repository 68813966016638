import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useGetCreateFieldOptions,
  useGetField,
  useUpdateField,
} from '~/features/customer-asset/queries/customer-asset';
import { CreateEditEntityDialog } from '../../../../components/CreateEditEntityDialog/CreateEditEntityDialog';
import { getFieldEditSchema } from '~/features/customer-asset/form-schemas/edit/field/fieldEditSchema';
import { mapValuesToVariables } from '~/components/Form/utils/map-variables';

export const UpdateFieldDialog: React.FC<{
  editId: string | undefined;
  onClose: () => void;
}> = ({ editId, onClose }) => {
  const { t } = useTranslation('fields');

  return (
    <CreateEditEntityDialog
      open={!!editId}
      onClose={onClose}
      title={t('edit-field')}
      errorMessage={t(`error-updating`)}
      successMessage={(field) => t('field-updated-successfully', { field })}
      editWarning={t('customer-assets::affect-multiple-channels')}
      entityId={editId}
      useGetOptions={useGetCreateFieldOptions}
      useGetEntity={useGetField}
      useMutate={useUpdateField}
      getSchema={({ t, options, entity }) =>
        getFieldEditSchema({
          t,
          countries: options?.countries,
          customers: options?.customers,
          field: entity?.field,
        }).fields
      }
      handleMutate={async (update, values, fields) => {
        await update({
          variables: {
            id: editId as string,
            fieldInput: mapValuesToVariables(fields, values),
          },
        });
        return values.name as string;
      }}
    />
  );
};
