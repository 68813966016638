import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../../../../components/CreateEditEntityDialog/CreateEditEntityDialog';
import {
  useGetCreateUmbilicalOptions,
  useGetUmbilical,
  useUpdateUmbilical,
} from '~/features/customer-asset/queries/customer-asset';
import { getUmbilicalEditSchema } from '~/features/customer-asset/form-schemas/edit/umbilical/umbilicalEditSchema';
import { mapValuesToVariables } from '~/components/Form/utils/map-variables';
import { UmbilicalUpdateInput } from '~/graphql/generated/asset/graphql';

export const UpdateUmbilicalDialog: React.FC<{
  editId: string | undefined;
  onClose: () => void;
}> = ({ editId, onClose }) => {
  const { t } = useTranslation('umbilicals');

  return (
    <CreateEditEntityDialog
      open={!!editId}
      onClose={onClose}
      title={t('edit-umbilical')}
      errorMessage={t('error-creating')}
      successMessage={(platform) => t('updated-successfully', { platform })}
      editWarning={t('customer-assets::affect-multiple-channels')}
      useGetEntity={useGetUmbilical}
      useMutate={useUpdateUmbilical}
      useGetOptions={useGetCreateUmbilicalOptions}
      entityId={editId}
      getSchema={({ t, options, entity }) =>
        getUmbilicalEditSchema({
          t,
          umbilical: entity?.umbilical,
          platformName: entity?.umbilical?.platform.name,
          manufacturers: options?.manufacturers,
          materialTypes: options?.materialTypes,
        }).fields
      }
      handleMutate={async (update, values, fields) => {
        const variables = mapValuesToVariables(fields, values);
        const umbilicalInput: UmbilicalSaveInput = {
          ...variables,
          approxInstallationDate: variables.approxInstallationDateId as number,
        };
        delete umbilicalInput.approxInstallationDateId;
        await update({
          variables: {
            id: editId as string,
            umbilicalInput,
          },
        });
        return '-';
      }}
    />
  );
};

interface UmbilicalSaveInput extends UmbilicalUpdateInput {
  approxInstallationDateId?: number;
}
