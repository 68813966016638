import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getNotEarlierThanChannelDateFormValidator,
  getNotEarlierThanReferenceDateFormValidator,
} from '~/components/Form/utils/date-validators';
import { DateTime } from 'luxon';
import { LifecycleEventType } from '~/graphql/generated/asset/graphql';

export type ValidateViperAsset = {
  lifecycleEvents: {
    dateOfLifecycleEvent: string;
    channel: {
      id: string;
      lifecycleEvents: {
        dateOfLifecycleEvent: string;
      }[];
    };
  }[];
};

export type ValidateChannels = {
  id: string;
  latestLifecycleEvent?: {
    dateOfLifecycleEvent: string;
  } | null;
}[];

export const useValidateLifecycleEvent = (
  eventType: LifecycleEventType | undefined,
  viperAsset: ValidateViperAsset | undefined | null,
  channels: ValidateChannels | undefined,
  editing: string | undefined
) => {
  const { t } = useTranslation('viper-asset');

  // When creating a new lifecycle event we need to make sure that we don't set the
  // date to be earlier than the latest event associated with this asset
  const latestAssetLifecycleDate = useMemo(() => {
    const endDate = viperAsset?.lifecycleEvents?.[0]?.dateOfLifecycleEvent;
    return endDate ? DateTime.fromISO(endDate) : undefined;
  }, [viperAsset?.lifecycleEvents]);

  // When editing we need to make sure that we don't set the date to be earlier than
  // the previous event associated with this asset
  const previousAssetLifecycleDate = useMemo(() => {
    const endDate = viperAsset?.lifecycleEvents?.[1]?.dateOfLifecycleEvent;
    return endDate ? DateTime.fromISO(endDate) : undefined;
  }, [viperAsset?.lifecycleEvents]);

  // If the current event is 'installed' and we're editing the lifecycle event, we need
  // to check the edited date against the date the channel last had an asset uninstalled
  // from it. (If the current event isn't 'installed' it's still ok to check this, but
  // the date will be the same as the asset's latest event date)
  const previousChannelLifecycleDate = useMemo(() => {
    const endDate =
      viperAsset?.lifecycleEvents?.[0]?.channel.lifecycleEvents?.[1]?.dateOfLifecycleEvent;
    return endDate ? DateTime.fromISO(endDate) : undefined;
  }, [viperAsset?.lifecycleEvents]);

  // When we're installing an asset on a new channel, we need to also validate the date
  // against the latest event on that channel, as it is likely to be different from the
  // date on the asset.

  const assetValidator = useMemo(
    () =>
      getNotEarlierThanReferenceDateFormValidator(
        'dateOfLifecycleEvent',
        editing ? previousAssetLifecycleDate : latestAssetLifecycleDate,
        t('date-validation/not-earlier-than-reference-date')
      ),
    [editing, latestAssetLifecycleDate, previousAssetLifecycleDate, t]
  );

  const channelValidator = useMemo(
    () =>
      editing
        ? getNotEarlierThanReferenceDateFormValidator(
            'dateOfLifecycleEvent',
            previousChannelLifecycleDate,
            t('date-validation/not-earlier-than-reference-date')
          )
        : getNotEarlierThanChannelDateFormValidator(
            'dateOfLifecycleEvent',
            channels,
            t('date-validation/not-earlier-than-reference-date')
          ),
    [channels, editing, previousChannelLifecycleDate, t]
  );

  const validate = useMemo(() => {
    return (values: Record<string, unknown>) => {
      return {
        ...assetValidator(values),
        ...channelValidator(values),
      };
    };
  }, [assetValidator, channelValidator]);

  return validate;
};
