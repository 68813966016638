import { Path } from './paths';
import { CreateVLifeLicence } from '~/features/customer-asset/pages/create-v-life-licence/CreateVLifeLicence';
import { ListViperAssets } from '~/features/viper-asset/pages/list/ListViperAssets';
import { ViewViperAsset } from '~/features/viper-asset/pages/view/ViewViperAsset';
import { CreateViperAsset } from '~/features/viper-asset/pages/create/CreateViperAsset';
import { Navigate, RouteObject } from 'react-router-dom';
import { ListCompanies } from '~/features/companies/pages/list/ListCompanies';
import { CreateCompany } from '~/features/companies/pages/create/CreateCompany';
import { ViewUserProfile } from '~/features/user-profile/pages/ViewUserProfile';
import { ListContactsPage } from '~/features/contacts/pages/list/ListContactsPage';
import { CreateContactPage } from '~/features/contacts/pages/create/CreateContactPage';
import { CreateVLifeReport } from '~/features/v-life-reports/pages/create/CreateVLifeReport';
import { ReviewVLifeReport } from '~/features/v-life-reports/pages/review/ReviewVLifeReport';
import { PublishVLifeReport } from '~/features/v-life-reports/pages/publish/PublishVLifeReport';
import { StaticContent } from '~/components/StaticContent/StaticContent';
import { ApproveVLifeReport } from '~/features/v-life-reports/pages/approve/ApproveVLifeReport';
import { LicenceInsightsPage } from '~/features/licence-insights/pages/LicenceInsightsPage';
import { ListCustomerAssets } from '~/features/customer-asset/pages/list/ListCustomerAssets';
import { CreateCustomerAsset } from '~/features/customer-asset/pages/create/CreateCustomerAsset';
import { ViewCustomerAsset } from '~/features/customer-asset/pages/view/ViewCustomerAsset';
import { ListVLifeReports } from '~/features/v-life-reports/pages/list/ListVLifeReports';
import { Dashboard } from '~/features/dashboard/pages/Dashboard';
import { VLogs } from '~/features/v-logs/pages/VLogs';

export const appRoutes: RouteObject[] = [
  {
    path: Path.HOME,
    element: <Dashboard />,
    id: 'Dashboard',
  },
  {
    id: 'Assets',
    children: [
      {
        path: Path.VIPER_ASSETS,
        element: <ListViperAssets />,
        id: 'ViperAssets',
      },
      {
        path: Path.CUSTOMER_ASSETS,
        element: <ListCustomerAssets />,
        id: 'CustomerAssets',
      },
      {
        path: Path.CREATE_CUSTOMER_ASSET,
        element: <CreateCustomerAsset />,
        id: 'CreateCustomerAsset',
      },
      {
        path: Path.CREATE_VIPER_ASSET,
        element: <CreateViperAsset />,
        id: 'CreateViperAsset',
      },
      {
        path: Path.CUSTOMER_ASSET,
        element: <ViewCustomerAsset />,
        id: 'CustomerAsset',
      },
      {
        path: Path.COMMON_CUSTOMER_ASSET,
        element: <ViewCustomerAsset />,
        id: 'CustomerAssetLogs',
      },
      {
        path: Path.COMMON_CUSTOMER_ASSET,
        element: <ViewCustomerAsset />,
        id: 'CustomerAssetReports',
      },
      {
        path: Path.COMMON_CUSTOMER_ASSET,
        element: <ViewCustomerAsset />,
        id: 'CustomerAssetGraph',
      },
      {
        path: Path.CREATE_LICENCE,
        element: <CreateVLifeLicence />,
        id: 'CreateLicence',
      },
      {
        path: Path.VIPER_ASSET,
        element: <ViewViperAsset />,
        id: 'ViperAsset',
      },
    ],
  },
  {
    id: 'VLifeService',
    children: [
      {
        path: Path.V_LIFE_REPORTS,
        element: <ListVLifeReports />,
        id: 'VLifeReports',
      },
      {
        path: Path.LICENCE_INSIGHTS,
        element: <LicenceInsightsPage />,
        id: 'LicenceInsights',
      },
    ],
  },
  {
    path: `${Path.V_LIFE_REPORTS}/create`,
    element: <Navigate to={Path.V_LIFE_REPORTS} replace />,
    id: 'CreateVLifeReportsRedirect',
  },
  {
    path: Path.COMMON_V_LIFE_REPORTS,
    element: <ListVLifeReports />,
    id: 'ReviewVLifeReports',
  },
  {
    path: Path.COMMON_V_LIFE_REPORTS,
    element: <ListVLifeReports />,
    id: 'ApproveVLifeReports',
  },
  {
    path: Path.COMMON_V_LIFE_REPORTS,
    element: <ListVLifeReports />,
    id: 'PublishVLifeReports',
  },
  {
    path: Path.CREATE_V_LIFE_REPORT,
    element: <CreateVLifeReport />,
    id: 'CreateVLifeReport',
  },
  {
    path: Path.REVIEW_V_LIFE_REPORT,
    element: <ReviewVLifeReport />,
    id: 'ReviewVLifeReport',
  },
  {
    path: Path.APPROVE_V_LIFE_REPORT,
    element: <ApproveVLifeReport />,
    id: 'ApproveVLifeReport',
  },
  {
    path: Path.PUBLISH_V_LIFE_REPORT,
    element: <PublishVLifeReport />,
    id: 'PublishVLifeReport',
  },
  {
    path: Path.V_LOGS,
    element: <VLogs />,
    id: 'VLogs ',
  },
  {
    path: Path.COMPANIES,
    element: <ListCompanies />,
    id: 'Companies',
  },
  {
    path: Path.CREATE_COMPANY,
    element: <CreateCompany />,
    id: 'CreateCompany',
  },
  {
    path: Path.CONTACTS,
    element: <ListContactsPage />,
    id: 'Contacts',
  },
  {
    path: Path.CREATE_CONTACT,
    element: <CreateContactPage />,
    id: 'CreateContact',
  },
  {
    path: Path.COOKIE_POLICY,
    element: (
      <StaticContent
        content='cookie-policy'
        translationParams={{ userProfileRoute: Path.USER_PROFILE }}
      />
    ),
    id: 'CookiePolicy',
  },
  {
    path: Path.USER_PROFILE,
    element: <ViewUserProfile />,
    id: 'UserProfile',
  },
];
