import { useMemo } from 'react';
import { useGetVlims } from '~/features/viper-asset/queries/useGetVlims';
import { Vlim } from '~/graphql/generated/data-retrieval/graphql';

export const useEnrichViperAssets = (rows: { serialNumber: string }[] | undefined) => {
  // Use lazy query for use inside enrichRowQuery async function
  const { data, loading } = useGetVlims();

  const enrichedRows = useMemo(() => {
    if (loading || !rows) {
      return rows;
    } else {
      return rows.map((row) => {
        const vlim = data?.vlims.find((vlim: Vlim) => vlim.serialNumber === row.serialNumber);
        return {
          ...row,
          remoteAccess: vlim?.remoteAccess ? 'Yes' : 'No',
          pollingIpAddress: vlim?.pollingIpAddress,
        };
      });
    }
  }, [rows, data, loading]);

  return {
    enrichedRows,
    loading,
  };
};
