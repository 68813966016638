import {
  Schema,
  componentTypes,
  dataTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { GetCreateChannelOptionsQuery } from '~/graphql/generated/asset/graphql';
import { extraValidatorTypes } from '~/components/Form/utils/validators';

interface GetElectricalCreateSchemaProps {
  t: TFunction;
  couplingTypes: GetCreateChannelOptionsQuery['couplingTypes'];
}

export function getElectricalCreateSchema({
  t,
  couplingTypes,
}: GetElectricalCreateSchemaProps): Schema {
  return {
    fields: [
      {
        name: 'operatingVoltage',
        label: t('customer-asset-forms::asset-operating-voltage'),
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'V',
        helperText: t('customer-asset-forms::asset-operating-voltage-helper'),
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('customer-asset-forms::asset-operating-voltage-message'),
          },
        ],
      },
      {
        name: 'referenceIR',
        label: t('customer-asset-forms::reference-ir'),
        isRequiredForReporting: true,
        component: componentTypes.TEXT_FIELD,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'Ω',
        helperText: t('customer-asset-forms::reference-ir-helper'),
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('customer-asset-forms::reference-ir-message'),
          },
        ],
      },
      {
        name: 'couplingTypeId',
        label: t('customer-asset-forms::coupling-type'),
        isClearable: true,
        component: componentTypes.SELECT,
        options: couplingTypes,
        helperText: t('customer-asset-forms::coupling-type-helper'),
      },
      {
        name: 'lifetimeStartDate',
        label: t('customer-asset-forms::lifetime-start-date'),
        helperText: t('customer-asset-forms::lifetime-start-date-helper'),
        component: componentTypes.DATE_PICKER,
        isRequiredForReporting: true,
        validate: [
          { type: extraValidatorTypes.NOT_FUTURE_DATE, message: t('viper::not-future-date') },
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
    ],
  };
}
