import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Chip } from '@mui/material';
import colors from '~/themes/colors';
import { useGetVLifeStatusMetadata } from '~/utils/useGetVLifeStatusMetadata';
import { VLifeStatus } from '~/graphql/generated/asset/graphql';

interface StatusPillProps {
  status: VLifeStatus;
}

export const StatusPill = ({ status }: StatusPillProps) => {
  const { color: iconColor, label } = useGetVLifeStatusMetadata(status);

  return (
    <Chip
      size='small'
      label={label}
      color='secondary'
      sx={{ backgroundColor: colors.grey[300] }}
      icon={<FiberManualRecordIcon style={{ color: iconColor }} />}
    />
  );
};
