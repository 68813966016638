import { DataGrid } from '~/components/DataGrid';
import { renderMasterDetail } from '~/components/DataGrid/components/DataGridMasterDetail';
import { useVLifeReportForms } from '~/features/v-life-reports/hooks/useVLifeReportForms';
import { VLifeCommentsDialog } from '~/features/v-life-reports/components/VLifeCommentsDialog';

// t('v-life-reports::period');
// t('v-life-reports::person');
// t('v-life-reports::reviews');

export interface VLifeReportFormsProps {
  statePersistencyId?: string;
}

export const VLifeReportForms = ({ statePersistencyId }: VLifeReportFormsProps) => {
  const {
    columns,
    masterDetailFields,
    rows,
    loading,
    commentsDialogOpen,
    onCloseCommentsDialog,
    displayModel,
  } = useVLifeReportForms();

  return (
    <>
      <DataGrid
        initialColumnVisibilityModel={displayModel}
        columns={columns}
        rows={rows}
        loading={loading}
        data-testid='table'
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={({ row }) =>
          renderMasterDetail({ row, fields: masterDetailFields })
        }
        statePersistencyId={statePersistencyId}
      />
      <VLifeCommentsDialog
        onClose={onCloseCommentsDialog}
        vlifeReportFormId={commentsDialogOpen}
      />
    </>
  );
};
