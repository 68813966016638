import React, { useCallback } from 'react';
import { VLogsRowData } from '../pages/VLogs';
import { Table } from '~/components/Table/Table';
import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '~/utils/date-utils';
import { getVlogFileDownloadEndpoint } from '~/common/graphql-measurement';
import { useGetVLogFileDownloadToken } from '~/features/v-logs/queries/v-log-measurement';

interface VLogExpandableRowProps {
  row: VLogsRowData;
}

export const VLogExpandableRow = ({ row }: VLogExpandableRowProps) => {
  const { t } = useTranslation('v-log');
  const parentRowId = row.id;
  const vLogRowEvents = row['events'];
  const [getDownloadToken] = useGetVLogFileDownloadToken();
  const eventTypeText = t('status');
  const timestampText = t('timestamp');
  const errorMessageText = t('errorMessage');
  const mapVLogConfigToTableRow = (uploadStatus: VLogsRowData['events'][0]) => {
    const { eventType, timestamp, id, message } = uploadStatus;
    return {
      _id: id,
      [eventTypeText]: t(`event-type/${eventType}`),
      [timestampText]: formatDateTime(timestamp),
      [errorMessageText]: message,
    };
  };

  const data = vLogRowEvents?.map(mapVLogConfigToTableRow);

  const handleDownloadClick = useCallback(
    async ({ id, event }: { id: string; event: React.MouseEvent<HTMLButtonElement> }) => {
      event.preventDefault();
      try {
        const result = await getDownloadToken({ variables: { id } });
        location.href = getVlogFileDownloadEndpoint(
          id,
          result.data?.vlogFileDownloadToken || ''
        );
      } catch (error) {
        console.error(error);
      }
    },
    [getDownloadToken]
  );

  return (
    <Box
      sx={{
        padding: 4,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Table
        muted
        embedded={false}
        data={data ?? []}
        omittedKeys={['_id']}
        itemsPerPage={100}
        omittedSortKeys={[eventTypeText, timestampText, errorMessageText]}
      />
      <Box
        sx={{
          pt: 4,
        }}
      >
        <Button
          variant='contained'
          color='neutral'
          onClick={(event) => handleDownloadClick({ id: parentRowId, event })}
          size='small'
        >
          {t('download')}
        </Button>
      </Box>
    </Box>
  );
};
