import React, { useMemo } from 'react';

import FormRenderer from '@data-driven-forms/react-form-renderer/form-renderer';
import { Field, FormTemplateRenderProps } from '@data-driven-forms/react-form-renderer';

import { componentMapper } from '../formFields/mappers/componentMapper';
import { Box } from '@mui/material';
import { validatorMapper } from '../utils/validators';

const EditPaneTemplate: React.FC<
  FormTemplateRenderProps & {
    container: React.FC<{ children: React.ReactNode }>;
  }
> = ({ formFields, container: Container }) => {
  return (
    <Container>
      <Box sx={{ width: '100%', p: 0 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: '0.5rem 1rem',
            placeContent: 'stretch',
            marginLeft: '6px',
          }}
        >
          <>{formFields}</>
        </div>
      </Box>
    </Container>
  );
};

export const EditPane: React.FC<{
  fields: Field[];
  container: React.FC<{ children: React.ReactNode }>;
  onSubmit?: (values: Record<string, unknown>) => void;
  onCancel?: () => void;
  validate?: (values: Record<string, unknown>) => Record<string, string> | undefined;
  disableFields?: boolean;
}> = ({ fields, validate, container: Container, onSubmit, onCancel, disableFields }) => {
  const mappedFields = useMemo(() => {
    if (disableFields) {
      return fields.map((field) => ({
        ...field,
        isDisabled: true,
      }));
    }
    return fields;
  }, [fields, disableFields]);
  return (
    <FormRenderer
      FormTemplate={(props) => <EditPaneTemplate {...props} container={Container} />}
      validate={validate}
      validatorMapper={validatorMapper}
      schema={{ fields: mappedFields }}
      componentMapper={{ ...componentMapper }}
      subscription={{ values: true }}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};
