import FormTemplate from '@data-driven-forms/mui-component-mapper/form-template';
import { FormTemplateRenderProps, useFormApi } from '@data-driven-forms/react-form-renderer';
import { Box, Button as MuiButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '~/components/Card/Card';
import { FormSpyWithBlocker } from '~/components/Form/FormSpyWithBlocker/FormSpyWithBlocker';

export const Button: React.FC<ButtonProps> = ({ label, buttonType, ...props }) => (
  <MuiButton {...props} variant='contained' color='primary' data-buttontype={buttonType}>
    {label}
  </MuiButton>
);

interface ButtonProps {
  label: React.ReactNode;
  buttonType: string;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({ children, ...props }) => (
  <Box
    sx={{
      pt: 2,
      gap: 1.5,
      gridColumnEnd: 'span 2',
      display: 'flex',
      justifyContent: 'flex-end',
    }}
    {...props}
  >
    {children}
  </Box>
);

interface ButtonGroupProps {
  children: React.ReactNode;
}

export const Title: React.FC<TitleProps> = ({ children, ...props }) => (
  <h1 {...props}>{children}</h1>
);

export const SubTitle: React.FC<TitleProps> = ({ children, ...props }) => (
  <Typography variant='h3' component='h3' sx={{ mb: 2 }} {...props}>
    {children}
  </Typography>
);

interface TitleProps {
  children: React.ReactNode;
}

export const Description: React.FC<DescriptionProps> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

interface DescriptionProps {
  children: React.ReactNode;
}

export const Form: React.FC<FormProps> = ({ children, ...props }) => (
  <form noValidate {...props}>
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: '0.5rem 1rem',
        placeContent: 'stretch',
      }}
    >
      {children}
    </div>
  </form>
);

interface FormProps {
  children: React.ReactNode;
}

const WrappedFormTemplate: React.FC<FormTemplateRenderProps> = (props) => (
  <FormTemplate
    FormWrapper={Form}
    Button={Button}
    ButtonGroup={ButtonGroup}
    Title={Title}
    Description={Description}
    {...props}
  />
);

interface StandaloneFormTemplateProps<T> extends FormTemplateRenderProps {
  setValues?: (values: T) => void;
}

export const StandaloneFormTemplate = <T,>({
  setValues,
  ...props
}: StandaloneFormTemplateProps<T>) => {
  const { getState } = useFormApi();

  useEffect(() => {
    if (setValues) {
      setValues(getState().values as T);
    }
  });

  return (
    <FormTemplate
      FormWrapper={Form}
      Title={Title}
      Description={Description}
      showFormControls={false}
      {...props}
    />
  );
};

export default WrappedFormTemplate;

export const GroupForm: React.FC<FormProps> = ({ children, ...props }) => (
  <form noValidate {...props}>
    <Stack spacing={2}>{children}</Stack>
  </form>
);

export const GroupFormTemplate: React.FC<
  FormTemplateRenderProps & {
    extraFooterContent?: React.ReactNode;
    additionalSubmitButtonDisabled?: (invalid: boolean) => boolean;
  }
> = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, onCancel, getState } = useFormApi();
  const {
    setValues,
    saving,
    submitButtonText,
    onAdditionalSubmit,
    additionalSubmitButtonDisabled,
    additionalSubmitButtonText,
    extraFooterContent,
  } = props;

  return (
    <Card
      footerContent={
        <FormSpyWithBlocker
          onCancel={({ values }) => onCancel?.(values)}
          disableBlocker={saving}
        >
          {({ handleCancel }) => {
            return (
              <>
                {extraFooterContent}
                <MuiButton color='neutral' variant='contained' onClick={handleCancel}>
                  {t('cancel')}
                </MuiButton>
                {onAdditionalSubmit && (
                  <MuiButton
                    color='neutral'
                    variant='contained'
                    onClick={() => {
                      onAdditionalSubmit();
                      setValues(getState().values);
                    }}
                    disabled={
                      additionalSubmitButtonDisabled
                        ? additionalSubmitButtonDisabled(getState().invalid)
                        : getState().invalid
                    }
                  >
                    {additionalSubmitButtonText}
                  </MuiButton>
                )}
                <MuiButton
                  color='secondary'
                  variant='contained'
                  onClick={() => {
                    handleSubmit();
                    setValues(getState().values);
                  }}
                  disabled={getState().invalid}
                >
                  {submitButtonText}
                </MuiButton>
              </>
            );
          }}
        </FormSpyWithBlocker>
      }
    >
      <FormTemplate
        Title={SubTitle}
        showFormControls={false}
        FormWrapper={GroupForm}
        Description={Description}
        Button={Button}
        ButtonGroup={ButtonGroup}
        {...props}
      />
    </Card>
  );
};
