import { getUmbilicalEditSchema } from '~/features/customer-asset/form-schemas/edit/umbilical/umbilicalEditSchema';
import { TFunction } from 'i18next';
import { GetCreateUmbilicalOptionsQuery } from '~/graphql/generated/asset/graphql';

export const getUmbilicalCreateSchema = ({
  t,
  manufacturers,
  materialTypes,
  platformName,
  editing,
}: {
  t: TFunction;
  manufacturers?: GetCreateUmbilicalOptionsQuery['manufacturers'];
  materialTypes?: GetCreateUmbilicalOptionsQuery['materialTypes'];
  platformName?: string;
  editing?: string;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-platform-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'createPlatform',
            title: t('platforms::create-platform'),
            fields: getUmbilicalEditSchema({
              t,
              manufacturers,
              materialTypes,
              platformName,
              editing,
            }).fields,
          },
        ],
      },
    ],
  };
};
