import { useGetReportWarnings } from '~/features/v-life-reports/hooks/useGetReportWarnings';

export const usePendingVLifeReportWarnings = (
  channelId: string,
  startDate: string,
  endDate: string
) => {
  const { warnings, loading } = useGetReportWarnings(channelId, startDate, endDate);
  return { warnings, loading };
};
