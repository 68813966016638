import React, { ReactNode } from 'react';
import { Help as HelpIcon } from '@mui/icons-material';
import { Tooltip } from '../Tooltip/Tooltip';
import { SvgIconProps } from '@mui/material';

export const Help: React.FC<{ tooltipText: ReactNode } & SvgIconProps> = ({
  tooltipText,
  ...iconProps
}) => {
  return (
    <Tooltip title={tooltipText}>
      <HelpIcon {...iconProps} />
    </Tooltip>
  );
};
