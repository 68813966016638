import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_EDIT } from '~/components/ActionMenu/ActionMenu';
import { withErrorHandling } from '~/components/Form/SaveError/SaveError';
import {
  useGetCompany,
  useGetCreateCompanyOptions,
  useGetCustomers,
  useUpdateCompany,
} from '~/features/companies/queries/company';
import { CustomerUpdateInput, InputMaybe } from '~/graphql/generated/asset/graphql';
import { getCompanyCreateSchema } from '~/features/companies/form-schemas/create/companyCreateSchema';

export type CompanyRowData = {
  _id: string;
  name: string;
  headquarters: string | null | undefined;
  address: string | null | undefined;
  country: string;
  continent: string;
  channelCount: number;
};

export const useCompanies = () => {
  const { t } = useTranslation('companies');

  const { data: customers, loading, error } = useGetCustomers();
  const [closeError, setCloseError] = useState(false);
  const [editing, setEditing] = useState<string | undefined>();
  const [saving, setSaving] = useState(false);

  const {
    data: createCompanyOptions,
    loading: optionsLoading,
    error: optionsError,
  } = useGetCreateCompanyOptions();

  const {
    data: company,
    loading: companyLoading,
    error: companyError,
  } = useGetCompany(editing ?? '', {
    skip: !editing,
  });

  const [updateCompany, updateCompanyErrorState] = withErrorHandling(useUpdateCompany());

  const formFields = getCompanyCreateSchema({
    t,
    editing,
    createCompanyOptions,
    company: company?.customer,
  }).fields[0].fields[0].fields;

  const data: CompanyRowData[] = useMemo(() => {
    return (customers?.customers || []).slice().map((customer) => ({
      _id: customer.id,
      name: customer.name,
      headquarters: customer.headquarters,
      address: customer.address,
      country: customer.country.name,
      continent: customer.country.continent.name,
      channelCount: customer.channelCount,
    }));
  }, [customers?.customers]);

  const onClose = () => {
    setEditing(undefined);
  };

  const handleActionClick = (
    menuItemId: string,
    rowIndex: number,
    row: CompanyRowData | undefined
  ) => {
    if (menuItemId === 'edit') {
      setEditing(row?._id);
    }
  };

  const onSave = useCallback(
    async (values: InputMaybe<CustomerUpdateInput> | undefined) => {
      setSaving(true);
      try {
        if (company?.customer && values) {
          await updateCompany({
            variables: {
              id: company.customer.id,
              customerInput: {
                address: values?.address,
                countryId: { value: values.countryId as unknown as string },
                name: { value: values.name as unknown as string },
                headquarters: values?.headquarters,
              },
            },
          });
          setEditing(undefined);
        }
      } finally {
        setSaving(false);
      }
    },
    [company?.customer, updateCompany]
  );

  const actionMenu = {
    label: 'actions',
    actionMenu: [ACTION_EDIT],
  };

  return {
    data,
    loading: loading || optionsLoading,
    companyLoading,
    error,
    optionsError,
    companyError,
    closeError,
    setCloseError,
    handleActionClick,
    actionMenu,
    editing,
    onClose,
    createCompanyOptions,
    updateCompanyErrorState,
    formFields,
    onSave,
    saving,
  };
};
