import Home from '@mui/icons-material/Home';
import Memory from '@mui/icons-material/Memory';
import {
  Business,
  MonitorHeartOutlined,
  PersonOutline,
  RocketLaunchOutlined,
} from '@mui/icons-material';
import { Path } from '~/routes/paths';

// t('nav/dashboard')
// t('nav/assets')
// t('nav/customer-assets')
// t('nav/create')
// t('nav/viper-assets')
// t('nav/create-licence')
// t('nav/companies')
// t('nav/contacts')
// t('nav/v-logs')
// t('nav/v-life-reports')
// t('nav/v-life-service')
// t('nav/review')
// t('nav/approve')
// t('nav/publish')
// t('nav/cookie-policy')
// t('nav/user-profile')
// t('nav/graph')
// t('nav/logs')
// t('nav/reports')

/* Static contents */
// t('static::cookie-policy')
// t('nav/licence-insights')

export type NavigationItem = {
  key: string | null;
  icon: JSX.Element | null;
  children?: NavigationItem[];
  path?: string;
};

export const useNavigationItems = () => {
  const items: NavigationItem[] = [
    {
      key: 'nav/dashboard',
      icon: <Home />,
      path: Path.HOME,
    },
    {
      key: 'nav/assets',
      icon: <Memory />,
      children: [
        {
          key: 'nav/viper-assets',
          icon: null,
          path: Path.VIPER_ASSETS,
        },
        {
          key: 'nav/customer-assets',
          icon: null,
          path: Path.CUSTOMER_ASSETS,
        },
      ],
    },
    {
      key: 'nav/v-life-service',
      icon: <MonitorHeartOutlined />,
      children: [
        {
          key: 'nav/v-life-reports',
          icon: null,
          path: Path.V_LIFE_REPORTS,
        },
        {
          key: 'nav/licence-insights',
          icon: null,
          path: Path.LICENCE_INSIGHTS,
        },
      ],
    },
    {
      key: 'nav/v-logs',
      icon: <RocketLaunchOutlined />,
      path: Path.V_LOGS,
    },
    {
      key: 'nav/companies',
      icon: <Business />,
      path: Path.COMPANIES,
    },
    {
      key: 'nav/contacts',
      icon: <PersonOutline />,
      path: Path.CONTACTS,
    },
  ];

  return {
    items,
  };
};
