import { useState } from 'react';
import { useGetVoltageAndIRForSerialNumbers } from '~/features/v-logs/queries/v-log-measurement';
import { useGetInstalledViperAssetPeriodAndMaxIRCaps } from '~/features/customer-asset/queries/customer-asset';

export function useIrAndVoltageGraph(
  channelId?: string,
  startDate?: string,
  endDate?: string
) {
  const [yAxisScale, setYAxisScale] = useState<'logarithmic' | 'linear'>('logarithmic');
  const {
    loading: loadingViperAssets,
    viperAssetPeriods,
    viperAssetMaxIRCaps,
  } = useGetInstalledViperAssetPeriodAndMaxIRCaps(channelId, startDate, endDate);

  const {
    data: voltageAndIRReadings,
    loading: readingsLoading,
    error,
  } = useGetVoltageAndIRForSerialNumbers(viperAssetPeriods, viperAssetMaxIRCaps, 8000);

  const maxIrCapError = !!error?.graphQLErrors.find(
    (error) => error.extensions['errorCode'] == 'NO_INFINITY_VALUE'
  );

  return {
    yAxisScale,
    setYAxisScale,
    loadingViperAssets,
    loading: readingsLoading,
    csv: voltageAndIRReadings?.getFloatReadingsForAssets?.csv,
    error,
    maxIrCapError,
  };
}
