export const getChannelDisplayName = (
  channel:
    | {
        name: string;
        customer: {
          name: string;
        };
        field: {
          name: string;
        };
      }
    | null
    | undefined
) => {
  if (!channel) {
    return undefined;
  }
  return `${channel.customer.name} : ${channel.field.name} : ${channel.name}`;
};
