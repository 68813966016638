import { Field } from '@data-driven-forms/react-form-renderer';
import { ExpandLess, ExpandMore, MoreHoriz } from '@mui/icons-material';
import { Collapse, Box, IconButton } from '@mui/material';
import React, { useState } from 'react';
import { ActionMenu, ActionMenuItem } from '../ActionMenu/ActionMenu';
import { DataList } from '../DataList/DataList';
import { StyledTableRow, StyledTableCell } from './components/Styled';
import { ComplexValueType, RowDataType, ValueType } from './Table';
import { ActionMenuColumn } from './useTable';
import { useTranslation } from 'react-i18next';

interface CollapsibleTableRowProps<T> {
  rowIndex: number;
  row: T;
  columnLabels: string[];
  handleRowClick?: (rowData: T | undefined) => void;
  noDataCharacter?: string;
  omittedKeys?: Array<keyof T>;
  nestedFields?: Field[];
  actionMenu?: ActionMenuColumn<T>;
  onActionMenuClick?: (
    rowIndex: number,
    rowData: T | undefined,
    menuItems: ActionMenuItem[]
  ) => ActionMenuItem[];
  onActionClick?: (menuItemId: string, rowIndex: number, rowData: T | undefined) => void;
}

export function CollapsibleTableRow<T extends RowDataType>({
  rowIndex,
  row,
  handleRowClick,
  omittedKeys,
  columnLabels,
  noDataCharacter = '–',
  nestedFields,
  actionMenu,
  onActionMenuClick,
  onActionClick,
}: CollapsibleTableRowProps<T>) {
  const [open, setOpen] = useState(false);
  const toggleCollapse = () => setOpen((state) => !state);
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuItems, setMenuItems] = useState<ActionMenuItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element>();

  return (
    <React.Fragment key={row._id}>
      <StyledTableRow
        key='main'
        data-testid='main-row'
        onClick={() => {
          handleRowClick?.(row);
        }}
        sx={{ background: (theme) => (open ? theme.palette.grey['300'] : 'inherit') }}
        $clickable={!!handleRowClick}
      >
        {columnLabels.map((columnLabel, index: number) => (
          <StyledTableCell
            key={columnLabel}
            component={index === 0 && columnLabels.length > 1 ? 'th' : 'td'}
            scope='row'
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              {columnLabel === actionMenu?.label ? (
                row._actions === false ? null : (
                  <>
                    {/* Spurious looking non-breaking space makes all the alignment work */}
                    <span style={{ flex: 1 }}>&nbsp;</span>
                    {nestedFields && index === columnLabels.length - 1 && (
                      <CollapseButton open={open} onClick={toggleCollapse} />
                    )}
                    <TableButton
                      icon={<MoreHoriz />}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                        const menuItems =
                          typeof actionMenu.actionMenu === 'function'
                            ? actionMenu.actionMenu(row)
                            : actionMenu.actionMenu;
                        if (onActionMenuClick) {
                          setMenuItems(onActionMenuClick(rowIndex, row, menuItems));
                        } else {
                          setMenuItems(menuItems);
                        }
                        setMenuOpen(true);
                      }}
                    />
                    <ActionMenu
                      menuItems={menuItems}
                      onClick={(id) => {
                        onActionClick?.(id, rowIndex, row);
                      }}
                      open={menuOpen}
                      onClose={() => setMenuOpen(false)}
                      anchorEl={anchorEl}
                    />
                  </>
                )
              ) : (
                <>
                  {getLabel(
                    row[columnLabel as keyof T],
                    noDataCharacter,
                    open,
                    toggleCollapse
                  )}
                  {nestedFields && index === columnLabels.length - 1 && (
                    <CollapseButton open={open} onClick={toggleCollapse} />
                  )}
                </>
              )}
            </Box>
          </StyledTableCell>
        ))}
      </StyledTableRow>
      {nestedFields && (
        <tr key='nested'>
          <td colSpan={columnLabels.length} style={{ padding: 0 }}>
            <Collapse in={open} unmountOnExit>
              <Box
                sx={{
                  padding: '1rem 1.5rem',
                  borderBottomWidth: 1,
                  borderBottomStyle: 'solid',
                  borderBottomColor: (theme) => theme.palette.divider,
                }}
              >
                <DataList
                  fields={nestedFields.filter((field) => !omittedKeys?.includes(field.name))}
                  nested
                />
              </Box>
            </Collapse>
          </td>
        </tr>
      )}
    </React.Fragment>
  );
}

const TableButton: React.FC<{
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: JSX.Element;
  ariaExpanded?: boolean;
  label?: string;
}> = ({ onClick, icon, ariaExpanded, label }) => {
  const { t } = useTranslation();

  return (
    <IconButton
      sx={{
        mt: -10,
        mb: -10,
        height: 40,
        width: 40,
        ml: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={(event) => {
        event.stopPropagation();
        onClick(event);
      }}
      aria-expanded={ariaExpanded}
      aria-label={label ?? t('action-menu')}
    >
      {icon}
    </IconButton>
  );
};

const CollapseButton = ({ open, onClick }: { open: boolean; onClick: () => void }) => {
  const { t } = useTranslation();

  const icon = open ? (
    <ExpandLess sx={{ fontSize: 20 }} />
  ) : (
    <ExpandMore sx={{ fontSize: 20 }} />
  );
  return (
    <TableButton
      onClick={onClick}
      icon={icon}
      ariaExpanded={open}
      label={t('toggle-hidden-fields')}
    />
  );
};

function getLabel(
  cellValue: ValueType,
  noDataCharacter: string,
  open: boolean,
  toggleCollapse: () => void
): ValueType {
  if (typeof cellValue === 'object') {
    const label = (cellValue as ComplexValueType)?.label;
    if (typeof label === 'function') {
      return label({
        rowOpen: open,
        toggleRowOpen: toggleCollapse,
      });
    }
    return label ?? noDataCharacter;
  } else {
    return cellValue ?? noDataCharacter;
  }
}
