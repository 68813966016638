import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getCompanyCreateSchema } from '~/features/companies/form-schemas/create/companyCreateSchema';
import {
  useCreateCompany,
  useGetCreateCompanyOptions,
} from '~/features/companies/queries/company';
import { withErrorHandling } from '~/components/Form/SaveError/SaveError';
import { Path } from '~/routes/paths';

export function useCompany() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: createCompanyOptions, loading: optionsLoading } = useGetCreateCompanyOptions();
  const [createCompany, errorState, { loading: saving }] = withErrorHandling(
    useCreateCompany()
  );

  const createCompanySchema = useMemo(
    () => getCompanyCreateSchema({ t, createCompanyOptions }),
    [createCompanyOptions, t]
  );

  const handleSubmit = useCallback(
    async ({ name, countryId, address, headquarters }: Record<string, unknown>) => {
      const result = await createCompany({
        variables: {
          customerInput: {
            name: name as string,
            countryId: countryId as string,
            address: address as string,
            headquarters: headquarters as string,
          },
        },
      });
      if (!result.errors) {
        navigate(Path.COMPANIES);
      }
    },
    [createCompany, navigate]
  );

  const handleCancel = useCallback(() => {
    navigate(Path.COMPANIES);
  }, [navigate]);

  return {
    optionsLoading,
    saving,
    errorState,
    createCompanySchema,
    handleSubmit,
    handleCancel,
  };
}
