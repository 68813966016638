import React from 'react';
import { Button, Card, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const CreateVLifeLicencePane: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('v-life-setup');
  return (
    <Card sx={{ padding: '32px' }}>
      <Typography variant='h3' component='h3'>
        {t('v-life-service')}
      </Typography>
      <Typography sx={{ paddingTop: 2, paddingBottom: 4 }}>
        {t('v-life-get-started')}
      </Typography>
      <Button variant='contained' color='secondary' onClick={onClick}>
        {t('set-up-now')}
      </Button>
    </Card>
  );
};
