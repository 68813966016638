import { DateStringRange } from '~/common/types';
import { getGainAttritionTotalsForDateRange } from '~/features/dashboard/utils/gain-attrition/utils';
import { GainAttritionStatistics } from '~/features/dashboard/utils/gain-attrition/types';
import { useEffect, useMemo } from 'react';
import { useGetChannelsWithVLifeTimelines } from '~/features/customer-asset/queries/customer-asset';
import { VLifeTimelinePeriod } from '~/graphql/generated/asset/graphql';
import { ChannelWithVLifeTimeline } from '~/features/licence-insights/types';

export interface UseGetGainAttritionValuesProps {
  dateRanges: DateStringRange[];
}

export interface UseGetGainAttritionValuesResponse {
  loading: boolean;
  previousTotal: number;
  gainAttritionWindows: GainAttritionStatistics[];
}

export const useGetGainAttritionValues = ({
  dateRanges,
}: UseGetGainAttritionValuesProps): UseGetGainAttritionValuesResponse => {
  const { data, loading } = useGetChannelsWithVLifeTimelines();

  // Parse string date ranges into Date objects
  const parsedDateRanges = useMemo(
    () =>
      dateRanges.map(({ startDate, endDate }) => ({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      })),
    [dateRanges]
  );

  // Find smallest startDate within dateRanges
  useEffect(() => {
    parsedDateRanges.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
  }, [parsedDateRanges]);

  const dateRangeStart = parsedDateRanges[0].startDate;

  // Flatten out all VLifeStatusWindows for ease of querying
  const flattenedStatusWindows: VLifeTimelinePeriod[] = useMemo(
    () =>
      (data?.channels ?? []).reduce(
        (
          acc: VLifeTimelinePeriod[],
          channel: ChannelWithVLifeTimeline
        ): VLifeTimelinePeriod[] => {
          if (channel.vLifeTimeline?.timeline) {
            return [...acc, ...channel.vLifeTimeline.timeline];
          }
          return acc;
        },
        []
      ),
    [data]
  );

  // Get gains / attrition sum up until the start date
  const { gains: previousGains, attrition: previousAttrition } = useMemo(() => {
    // Remove one millisecond from the start date, to only get everything up until then, and not including, the start date
    const endDateBefore = new Date(dateRangeStart);
    endDateBefore.setTime(endDateBefore.getTime() - 1);

    return getGainAttritionTotalsForDateRange(flattenedStatusWindows, {
      startDate: new Date('1900-01-01'),
      endDate: endDateBefore,
    });
  }, [dateRangeStart, flattenedStatusWindows]);
  const previousTotal = previousGains - previousAttrition;

  // Get values for each date range window
  const gainAttritionWindows: GainAttritionStatistics[] = useMemo(
    () =>
      parsedDateRanges.map((dateRange) =>
        getGainAttritionTotalsForDateRange(flattenedStatusWindows, dateRange)
      ),
    [flattenedStatusWindows, parsedDateRanges]
  );

  return {
    loading,
    previousTotal,
    gainAttritionWindows,
  };
};
