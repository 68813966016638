import { DateRange } from '~/common/types';
import { GainAttritionStatistics } from '~/features/dashboard/utils/gain-attrition/types';
import { VLifeTimelinePeriod } from '~/graphql/generated/asset/graphql';

export const getGainAttritionStatusWindowsWithinDateRange = (
  vLifeStatusWindows: VLifeTimelinePeriod[],
  dateRange: DateRange
): VLifeTimelinePeriod[] => {
  return (
    vLifeStatusWindows
      // Filter out any without gains or attrition
      .filter(({ gain, attrition }) => {
        return (gain && gain === true) || (attrition && attrition === true);
      })
      // Filter out any that don't overlap with the date range
      .filter(({ startDate, endDate }) => {
        const parsedStartDate = new Date(startDate ?? '');
        const parsedEndDate = new Date(endDate ?? '');
        return (
          (!startDate || parsedStartDate.getTime() <= dateRange.endDate.getTime()) &&
          (!endDate || parsedEndDate.getTime() >= dateRange.startDate.getTime())
        );
      })
  );
};
/*
  Returns the gain / attrition totals for the current date range.
  Gains increase if a paid licence with "gain" starts within the date range.
  Attrition increases if a paid licence with "attrition" ends within the date range.
 */
export const getGainAttritionTotalsForDateRange = (
  vLifeStatusWindows: VLifeTimelinePeriod[],
  dateRange: DateRange
): GainAttritionStatistics => {
  // Filter out StatusWindows with no gains / attrition OR not overlapping with date range
  const includedWindows = getGainAttritionStatusWindowsWithinDateRange(
    vLifeStatusWindows,
    dateRange
  );

  // Reduce all remaining windows, counting gains and attrition
  const { gainsTotal, attritionTotal } = includedWindows.reduce(
    (
      { gainsTotal, attritionTotal },
      { startDate, endDate, gain, attrition }: VLifeTimelinePeriod
    ) => {
      const parsedStartDate = new Date(startDate ?? '');
      const parsedEndDate = new Date(endDate ?? '');

      // Only a gain if the licence starts in the current month
      const addGain =
        gain &&
        startDate &&
        dateRange.startDate <= parsedStartDate &&
        parsedStartDate <= dateRange.endDate;
      // Only add to attrition if the licence ends in the current month
      const addAttrition =
        attrition &&
        endDate &&
        dateRange.startDate <= parsedEndDate &&
        parsedEndDate <= dateRange.endDate;
      return {
        gainsTotal: gainsTotal + (addGain ? 1 : 0),
        attritionTotal: attritionTotal + (addAttrition ? 1 : 0),
      };
    },
    {
      gainsTotal: 0,
      attritionTotal: 0,
    }
  );

  return {
    dateRange,
    gains: gainsTotal,
    attrition: attritionTotal,
  };
};
