import React from 'react';
import { Typography } from '@mui/material';

export const Label: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Typography
      component='p'
      sx={{
        marginTop: 0,
        ml: 0.75,
        mb: 0.25,
        gridColumnEnd: 'span 2',
        fontSize: 14,
      }}
    >
      {label}
    </Typography>
  );
};
