import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Box,
  Stack,
  Button,
} from '@mui/material';

import { defaultColors } from './defaults';

import { BarDatum, ComputedDatum, BarLayer } from '@nivo/bar';
import { NivoBar } from './NivoBar';
import { CustomBarTooltip } from './ChartLabels';
import { Trans, useTranslation } from 'react-i18next';
import { Help } from '~/components/Help/Help';
import { useExportDomElementToPng } from '~/common/useExportDomToPng';

const HelpText: React.FC = () => {
  const { t } = useTranslation('dashboard');
  return (
    <Trans
      i18nKey='stacked-bar-chart-help'
      t={t}
      components={{
        br: <br />,
      }}
    />
  );
};

export const StackedBarChartCard: React.FC<{
  data?: BarDatum[];
  title: string;
  subTitle: string;
  colors?: string[];
  loading?: boolean;
  keys: string[];
  indexBy: string;
  topLabels?: BarLayer<BarDatum>;
  onClick?: (
    data: ComputedDatum<BarDatum> & {
      color: string;
    }
  ) => void;
}> = ({
  title,
  subTitle,
  data,
  colors = defaultColors,
  loading,
  indexBy,
  keys,
  onClick,
  topLabels,
}) => {
  const { t } = useTranslation('dashboard');
  const exportDomContainerRef = React.useRef<HTMLDivElement>(null);
  const { handleExport, exportEnabled } = useExportDomElementToPng(
    exportDomContainerRef,
    'v-life-licence-history-graph.png'
  );

  return (
    <Card
      sx={{
        px: 0,
        py: 1.5,
        gridColumnEnd: 'span 6',
      }}
    >
      <CardHeader
        title={title}
        sx={{ my: 0, mx: 2 }}
        component='h2'
        subheader={subTitle}
        subheaderTypographyProps={{ fontSize: 16, color: 'text.secondary' }}
      />
      <CardContent
        sx={{
          pt: 0,
          height: 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <Box ref={exportDomContainerRef} sx={{ my: 1 }} width='100%' height='100%'>
            <NivoBar
              data={data}
              indexBy={indexBy}
              keys={keys}
              colors={colors}
              topLabels={topLabels}
              tooltip={CustomBarTooltip}
              onClick={onClick}
              colorBy='id'
              legendLabel={(legendLabel) => t(`${legendLabel.id}`)}
              barAriaLabel={(item) =>
                item.id + ': ' + item.formattedValue + ' in year: ' + item.indexValue
              }
            />
          </Box>
        )}
        <Box display='flex' justifyContent='space-between' width='100%'>
          <Help tooltipText={<HelpText />} color='info' />
          <Stack direction='row' gap={2}>
            <Button
              variant='contained'
              size='small'
              color='neutral'
              onClick={handleExport}
              disabled={loading || !exportEnabled}
            >
              {t('export')}
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};
