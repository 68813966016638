import {
  CreateChannelDocument,
  CreateCustomerAssetMaintenanceLogDocument,
  CreateFieldDocument,
  CreatePlatformDocument,
  CreateProductionControlSystemDocument,
  CreateUmbilicalDocument,
  DeleteVLifeConfigDocument,
  GetChannelDocument,
  GetChannelNamesAndFieldsDocument,
  GetChannelsDocument,
  GetChannelsVLifeConfigsDocument,
  GetChannelsWithInstalledViperAssetPeriodsDocument,
  GetChannelsWithVLifeTimelinesDocument,
  GetChannelVLifeConfigsDocument,
  GetCreateChannelOptionsDocument,
  GetCreateFieldOptionsDocument,
  GetCreatePcsOptionsDocument,
  GetCreateUmbilicalOptionsDocument,
  GetCustomerAssetMaintenanceLogOptionsDocument,
  GetDisableVLifeConfigOptionsDocument,
  GetFieldDocument,
  GetInstalledViperAssetPeriodsOnChannelDocument,
  GetInstalledViperAssetsOnChannelDocument,
  GetPlatformDocument,
  GetProductionControlSystemDocument,
  GetUmbilicalDocument,
  UpdateChannelDocument,
  UpdateCustomerAssetMaintenanceLogDocument,
  UpdateFieldDocument,
  UpdatePlatformDocument,
  UpdateProductionControlSystemDocument,
  UpdateUmbilicalDocument,
} from '~/graphql/generated/asset/graphql';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import {
  ViperAssetMaxIrCapInput,
  ViperAssetPeriodInput,
} from '~/graphql/generated/measurement/graphql';

export function useGetChannels() {
  return useQuery(GetChannelsDocument);
}

export function useGetChannelsWithInstalledViperAssetPeriods(
  startDate: string | undefined,
  endDate: string | undefined
) {
  return useQuery(GetChannelsWithInstalledViperAssetPeriodsDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      startDate: startDate ?? '',
      endDate: endDate ?? '',
    },
    skip: !startDate || !endDate,
  });
}

export function useGetChannelsWithVLifeConfigs() {
  return useQuery(GetChannelsVLifeConfigsDocument, {
    fetchPolicy: 'no-cache',
  });
}

export function useGetChannelsWithVLifeTimelines() {
  return useQuery(GetChannelsWithVLifeTimelinesDocument, {
    fetchPolicy: 'no-cache',
  });
}

export const useGetChannelNamesByField = () => {
  const result = useQuery(GetChannelNamesAndFieldsDocument);
  const channelNamesByField = useMemo(
    () =>
      // create a map from field id to an array of channel names
      result.data?.channels.reduce((prev, curr) => {
        if (!prev[curr.field.id]) {
          prev[curr.field.id] = [];
        }
        prev[curr.field.id].push(curr.name);
        return prev;
      }, {} as Record<string, string[]>) ?? {},
    [result.data?.channels]
  );

  return {
    ...result,
    channelNamesByField,
  };
};

export const useGetChannel = (id?: string) => {
  return useQuery(GetChannelDocument, { variables: { id: id ?? '' }, skip: !id });
};

export function useGetChannelVLifeConfigs(id?: string) {
  return useQuery(GetChannelVLifeConfigsDocument, {
    variables: {
      id: id ?? '',
    },
    skip: !id,
  });
}

export function useCreateChannel() {
  return useMutation(CreateChannelDocument);
}

export function useUpdateChannel() {
  return useMutation(UpdateChannelDocument);
}

export function useCreateField() {
  return useMutation(CreateFieldDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useUpdateField() {
  return useMutation(UpdateFieldDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useUpdatePlatform() {
  return useMutation(UpdatePlatformDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useUpdateUmbilical() {
  return useMutation(UpdateUmbilicalDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useUpdateProductionControlSystem() {
  return useMutation(UpdateProductionControlSystemDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useGetCreateChannelOptions() {
  return useQuery(GetCreateChannelOptionsDocument, {
    fetchPolicy: 'cache-first',
  });
}

export function useGetCreateFieldOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreateFieldOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetField(id: string, options?: { skip?: boolean }) {
  return useQuery(GetFieldDocument, { variables: { id }, skip: options?.skip });
}

export function useGetPlatform(id: string, options?: { skip?: boolean }) {
  return useQuery(GetPlatformDocument, { variables: { id }, skip: options?.skip });
}

export function useGetUmbilical(id: string, options?: { skip?: boolean }) {
  return useQuery(GetUmbilicalDocument, { variables: { id }, skip: options?.skip });
}

export function useGetProductionControlSystem(id: string, options?: { skip?: boolean }) {
  return useQuery(GetProductionControlSystemDocument, {
    variables: { id },
    skip: options?.skip,
  });
}

export function useCreatePlatform() {
  return useMutation(CreatePlatformDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useCreateUmbilical() {
  return useMutation(CreateUmbilicalDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useCreateProductionControlSystem() {
  return useMutation(CreateProductionControlSystemDocument, {
    refetchQueries: [{ query: GetCreateChannelOptionsDocument }],
  });
}

export function useDeleteVLifeConfig(id: string) {
  return useMutation(DeleteVLifeConfigDocument, {
    refetchQueries: [{ query: GetChannelDocument, variables: { id } }],
  });
}

export function useGetCreateUmbilicalOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreateUmbilicalOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetCreatePcsOptions(options?: { skip?: boolean }) {
  return useQuery(GetCreatePcsOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetDisableVLifeConfigOptions(options?: { skip?: boolean }) {
  return useQuery(GetDisableVLifeConfigOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useGetCustomerAssetMaintenanceLogOptions(options?: { skip?: boolean }) {
  return useQuery(GetCustomerAssetMaintenanceLogOptionsDocument, {
    fetchPolicy: 'cache-first',
    skip: options?.skip,
  });
}

export function useCreateCustomerAssetMaintenanceLog(options: { id?: string } | undefined) {
  return useMutation(CreateCustomerAssetMaintenanceLogDocument, {
    refetchQueries: [{ query: GetChannelDocument, variables: { id: options?.id } }],
  });
}

export function useUpdateCustomerAssetMaintenanceLog(options: { id?: string } | undefined) {
  return useMutation(UpdateCustomerAssetMaintenanceLogDocument, {
    refetchQueries: [{ query: GetChannelDocument, variables: { id: options?.id } }],
  });
}

export function useGetInstalledViperAssetsLazy() {
  return useLazyQuery(GetInstalledViperAssetsOnChannelDocument, {
    fetchPolicy: 'no-cache',
  });
}

export function useGetInstalledViperAssets(id: string | undefined) {
  return useQuery(GetInstalledViperAssetsOnChannelDocument, {
    fetchPolicy: 'cache-first',
    variables: {
      id: id ?? '',
    },
    skip: !id,
  });
}

export function useGetInstalledViperAssetPeriods(
  id: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  useLifetimeStartDate?: boolean | undefined
) {
  return useQuery(GetInstalledViperAssetPeriodsOnChannelDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      id: id ?? '',
      startDate: startDate ?? '',
      endDate: endDate ?? '',
      useLifetimeStartDate: useLifetimeStartDate ?? true,
    },
    skip: !id || !startDate || !endDate,
  });
}

export function useGetInstalledViperAssetPeriodAndMaxIRCaps(
  channelId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  useLifetimeStartDate?: boolean | undefined
) {
  const { data, loading } = useGetInstalledViperAssetPeriods(
    channelId,
    startDate,
    endDate,
    useLifetimeStartDate
  );
  const installedViperAssetPeriods = useMemo(
    () => data?.channel?.installedViperAssetPeriods ?? [],
    [data?.channel?.installedViperAssetPeriods]
  );

  const viperAssetPeriods: ViperAssetPeriodInput[] = useMemo(
    () =>
      installedViperAssetPeriods.map((period): ViperAssetPeriodInput => {
        const { startDate, endDate, viperAsset } = period;
        const { serialNumber } = viperAsset;
        return {
          period: {
            start: startDate,
            end: endDate,
          },
          serialNumber,
        };
      }) ?? [],
    [installedViperAssetPeriods]
  );

  const viperAssetMaxIRCaps: ViperAssetMaxIrCapInput[] = useMemo(
    () =>
      installedViperAssetPeriods.reduce((maxIRCaps: ViperAssetMaxIrCapInput[], period) => {
        const { maxIrCap, serialNumber } = period.viperAsset;
        if (maxIrCap) {
          maxIRCaps.push({
            serialNumber,
            maxIrCap,
          });
        }
        return maxIRCaps;
      }, []) ?? [],
    [installedViperAssetPeriods]
  );
  return {
    data,
    loading,
    viperAssetPeriods: viperAssetPeriods,
    viperAssetMaxIRCaps: viperAssetMaxIRCaps,
  };
}
