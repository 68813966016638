import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { useParams } from 'react-router-dom';
import { Path, PathValue, buildPath } from '~/routes/paths';
import { CellLink } from '~/components/Table/components/CellLink';
import { CellComments } from '~/components/Table/components/CellComments';
import { Link, Typography } from '@mui/material';
import { DataGridCustomExpander } from '~/components/DataGrid/components/DataGridCustomExpander';
import { Value, ValueMapper } from '~/components/DataGrid/components/DataGridMasterDetail';
import { AuthenticatedUser } from '~/graphql/generated/asset/graphql';
import { dateFormatter, personFormatter } from '~/components/DataGrid/value-formatters';

const baseColumn: Partial<GridColDef> = {
  minWidth: 200,
  maxWidth: 350,
  flex: 1,
};

const narrowColumn: Partial<GridColDef> = {
  minWidth: 100,
  maxWidth: 150,
  flex: 0.5,
};

const formStatuses: Record<string, { path: PathValue; label: string; field: string }> = {
  review: { path: Path.REVIEW_V_LIFE_REPORT, label: 'review', field: 'suggestedReviewer' },
  approve: { path: Path.APPROVE_V_LIFE_REPORT, label: 'approve', field: 'approver' },
  publish: { path: Path.PUBLISH_V_LIFE_REPORT, label: 'publish', field: 'approver' },
};

export function useVLifeReportFormColumns(
  onCommentsClick: Dispatch<SetStateAction<string | undefined>>
) {
  const { t } = useTranslation('v-life-reports');
  const { formStatus } = useParams();

  const {
    negatableDateFilterOperators,
    negatableStringFilterOperators,
    negatableNumberFilterOperators,
  } = useNegatableFilterOperators();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params: GridRenderCellParams) => <DataGridCustomExpander {...params} />,
      },
      {
        ...narrowColumn,
        field: 'id',
        headerName: t('id'),
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channel.customer.name',
        headerName: t('customer'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'fieldName',
        headerName: t('field'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'channelName',
        headerName: t('channel'),
        filterOperators: negatableStringFilterOperators,
      },
      {
        ...baseColumn,
        field: 'reportPeriodStartDate',
        headerName: t('start-date'),
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...baseColumn,
        field: 'reportPeriodEndDate',
        headerName: t('end-date'),
        type: 'date',
        valueFormatter: dateFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...baseColumn,
        field: formStatus ? formStatuses[formStatus]?.field : 'creator',
        headerName: t('person'),
        type: 'date',
        valueFormatter: personFormatter,
        filterOperators: negatableDateFilterOperators,
      },
      {
        ...narrowColumn,
        field: 'reviewers',
        headerName: t('reviews'),
        valueGetter: (params) => params.row.reviewers.length,
        renderCell: (params) => <ReviewLink id={params.row.id} items={params.row.reviewers} />,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...narrowColumn,
        field: 'comments',
        headerName: t('comments'),
        valueGetter: (params) => params.row.comments.length,
        renderCell: ({ row }) => {
          return (
            <CellComments comments={row.comments} onClick={() => onCommentsClick(row.id)} />
          );
        },
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...narrowColumn,
        field: 'action',
        headerName: t('action'),
        valueGetter: () => formStatus && t(formStatuses[formStatus].label),
        renderCell: ({ row, value }) =>
          formStatus && (
            <CellLink
              content={value}
              path={buildPath(formStatuses[formStatus].path) as PathValue}
              param={{ reportFormId: row.id }}
            />
          ),
      },
    ],
    [
      formStatus,
      negatableDateFilterOperators,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      onCommentsClick,
      t,
    ]
  );

  const nestedColumns: Array<Partial<GridColDef> & { valueMapper?: ValueMapper }> =
    useMemo(() => {
      const columns = [
        {
          field: 'creator',
          headerName: t('created-by'),
          valueMapper: (value: Value) => (value as AuthenticatedUser).name,
        },
        {
          field: 'reviewers',
          headerName: t('reviewed-by'),
          valueMapper: (value: Value) =>
            (value as AuthenticatedUser[]).length > 0
              ? (value as AuthenticatedUser[]).map((user) => user.name).join(', ')
              : '–',
        },
      ];
      return columns;
    }, [t]);

  const displayModel = {
    id: false,
    field: true,
    'channel.customer.name': true,
    fieldName: true,
    channelName: true,
    reportPeriodStartDate: true,
    [formStatus ? formStatuses[formStatus]?.field : 'creator']: true,
    reviewers: true,
    comments: true,
    action: true,
  };

  return { columns, nestedColumns, displayModel };
}

export function ReviewLink({ id, items }: { id: string; items: AuthenticatedUser[] }) {
  const apiRef = useGridApiContext();

  return items.length > 0 ? (
    <Link
      onClick={() => {
        apiRef.current.toggleDetailPanel(id);
      }}
      aria-label='Reviewer count'
      component='button'
    >
      {items.length}
    </Link>
  ) : (
    <Typography variant='body2' color='textSecondary'>
      {items.length}
    </Typography>
  );
}
