import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import { WarningBar } from '../Form/WarningBar/WarningBar';
import './pdfViewer.css';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { ZoomIn, ZoomOut } from '@mui/icons-material';
import { CircularLoadingSkeleton } from '../LoadingSkeleton/LoadingSkeleton';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const PdfViewer: React.FC<{ url?: string }> = ({ url = '' }) => {
  const { t } = useTranslation('pdf-viewer');
  const theme = useTheme();
  const [numPages, setNumPages] = useState<number>();
  const [metadata, setMetadata] = useState<{
    contentDispositionFilename: string;
    info: { Title: string };
  }>();
  const [zoom, setZoom] = useState<number>(1.5); // [0.5, 1.0, 1.5, 2.0

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function onDocumentLoadSuccess(pdf: any): Promise<void> {
    setMetadata(await pdf.getMetadata());
    setNumPages(pdf.numPages);
  }

  return (
    <>
      <Stack
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        direction='row'
        sx={{
          background: theme.palette.grey[700],
          padding: '0 0 0 1rem',
          mb: -2,
        }}
        data-testid='pdf-viewer'
      >
        <Typography variant='h3' sx={{ color: theme.palette.common.white }}>
          {metadata?.info.Title} {numPages && t('pages', { numPages })}
        </Typography>
        <span style={{ flex: 1 }} />
        <IconButton onClick={() => setZoom((s) => Math.min(2.5, s + 0.5))}>
          <ZoomIn sx={{ color: theme.palette.common.white }} />
        </IconButton>
        <IconButton onClick={() => setZoom((s) => Math.max(0.5, s - 0.5))}>
          <ZoomOut sx={{ color: theme.palette.common.white }} />
        </IconButton>
      </Stack>

      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        error={<PdfError message={t('pdf-error')} />}
        loading={<CircularLoadingSkeleton sx={{ color: theme.palette.common.white }} />}
      >
        {Array.from(new Array(numPages), (_el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={zoom}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </>
  );
};

export function PdfError({ message }: { message: string }) {
  return <WarningBar message={message} />;
}
