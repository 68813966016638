import React from 'react';
import { Card, CardContent, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IrAndVoltageGraph } from './IrAndVoltageGraph';
import {
  CompareReportFormGraphProps,
  CompareReportFormGraphs,
} from './CompareReportFormGraphs';

export const ReportFormGraphs: React.FC<CompareReportFormGraphProps> = (props) => {
  const { t } = useTranslation('report-form-graphs');
  const { channelId, currentDates } = props;
  return (
    <Card sx={{ mb: 3 }}>
      <CardContent
        sx={{
          gap: 2,
          p: 4,
        }}
      >
        <CompareReportFormGraphs {...props} />
        <IrAndVoltageGraph
          channelId={channelId}
          startDate={currentDates.startDate}
          endDate={currentDates.endDate}
          title={t('report-period')}
        />
        <Divider sx={{ my: 3 }} />
        <IrAndVoltageGraph
          title={t('all-time')}
          channelId={channelId}
          startDate='1970-01-01'
          endDate={currentDates.endDate}
        />
      </CardContent>
    </Card>
  );
};
