import { useRouteId } from '~/common/useRouteId';
import { useGetViperAsset } from '~/features/viper-asset/queries/viper-asset';

export const useViperAsset = () => {
  const id = useRouteId();
  const { data: viperAsset, loading } = useGetViperAsset(id);

  return {
    id,
    loading,
    viperAsset: viperAsset?.viperAsset,
  };
};
