import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '../../../../components/CreateEditEntityDialog/CreateEditEntityDialog';
import { getCustomerAssetMaintenanceLogCreateSchema } from '~/features/customer-asset/form-schemas/create/customer-asset/customerAssetMaintenanceLogCreateSchema';
import {
  useUpdateCustomerAssetMaintenanceLog,
  useGetCustomerAssetMaintenanceLogOptions,
} from '~/features/customer-asset/queries/customer-asset';
import { formatDate } from '~/utils/date-utils';
import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { mapValuesToVariables } from '~/components/Form/utils/map-variables';

export const UpdateCustomerAssetMaintenanceLogDialog: React.FC<{
  id: string;
  open: boolean;
  // @ts-expect-error type too deep for TS
  maintenanceLogs?: GetViperAssetQuery['viperAsset']['maintenanceLogs'];
  onClose: () => void;
  editId?: string;
}> = ({ open, onClose, id, maintenanceLogs, editId }) => {
  const { t } = useTranslation('customer-asset');
  const maintenanceLog = maintenanceLogs?.find(
    // @ts-expect-error type too deep for TS
    (log: GetViperAssetQuery['viperAsset']['maintenanceLogs']) => log.id === editId
  );

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('edit-maintenance-log')}
      errorMessage={t(`error-editing-maintenance-log`)}
      successMessage={() => t('maintenance-log-edited-successfully')}
      useGetOptions={useGetCustomerAssetMaintenanceLogOptions}
      useMutate={useUpdateCustomerAssetMaintenanceLog}
      useMutateOptions={{ id }}
      getSchema={({ t, options }) =>
        getCustomerAssetMaintenanceLogCreateSchema({ t, options, maintenanceLog }).fields[0]
          .fields[0].fields
      }
      handleMutate={async (update, values, fields) => {
        const variables = mapValuesToVariables(fields, values);
        const customerAssetMaintenanceLog = {
          ...variables,
          affectedViperAssetIds: (variables.affectedViperAssetIdsId as { value: number[] })
            .value,
          affectedViperAssetIdsId: undefined,
        };
        delete customerAssetMaintenanceLog.affectedViperAssetIdsId;
        await update({
          variables: {
            id: editId as string,
            customerAssetMaintenanceLog,
          },
        });
        return formatDate(values.maintenanceEndDate as string) as string;
      }}
      validate={({ maintenanceEndDate, maintenanceStartDate }) => {
        if (
          maintenanceEndDate &&
          maintenanceStartDate &&
          maintenanceEndDate < maintenanceStartDate
        ) {
          return {
            maintenanceEndDate: t('end-date-must-be-after-start-date'),
          };
        }
        return undefined;
      }}
    />
  );
};
