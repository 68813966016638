import { ComponentMapper, componentTypes } from '@data-driven-forms/react-form-renderer';
import { Checkbox } from '../Checkbox/Checkbox';
import { Select } from '../Select/Select';
import { Textarea } from '../../Textarea/Textarea';
import { TextField } from '../TextField/TextField';
import { DatePicker } from '../DatePicker/DatePicker';
import { PlainText } from '../PlainText/PlainText';
import { extraComponents } from '../../utils/extraComponents';
import { withAnnotation } from './withAnnotation';
import { SubForm } from '@data-driven-forms/mui-component-mapper';
import { Button } from '../templates/FormTemplate';
import { CustomFormGroup } from '~/components/Form/formFields/CustomFormField/CustomFormGroup';
import { CustomFormField } from '~/components/Form/formFields/CustomFormField/CustomFormField';
import { Label } from '~/components/Form/formFields/Label/Label';
import { SubHeadingFormComponent } from '~/components/Form/formFields/SubHeading/SubHeading';

export const componentMapper: ComponentMapper = {
  [componentTypes.TEXT_FIELD]: TextField,
  [componentTypes.TEXTAREA]: Textarea,
  [componentTypes.CHECKBOX]: Checkbox,
  [componentTypes.SELECT]: {
    component: withAnnotation(Select),
    clearedValue: null,
  },
  [componentTypes.DATE_PICKER]: DatePicker,
  [componentTypes.PLAIN_TEXT]: PlainText,
  [componentTypes.BUTTON]: Button,
  [componentTypes.SUB_FORM]: SubForm,
  [extraComponents.SUBHEADING]: SubHeadingFormComponent,
  [extraComponents.LABEL]: Label,
  [extraComponents.CUSTOM]: CustomFormField,
  [extraComponents.SUB_FORM]: CustomFormGroup,
};
