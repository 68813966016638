import { useQuery } from '@apollo/client';
import { VdrVersionDocument } from '~/graphql/generated/data-retrieval/graphql';
import { useDataRetrievalApolloClient } from '~/common/graphql-data-retrieval';

export function useGetVdrVersion() {
  const client = useDataRetrievalApolloClient();

  return useQuery(VdrVersionDocument, {
    client,
    fetchPolicy: 'cache-first',
  });
}
