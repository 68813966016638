import { VLifeStatus } from '~/graphql/generated/asset/graphql';

export const isLicenceActive = (vLifeStatus?: VLifeStatus): boolean => {
  if (!vLifeStatus) {
    return false;
  }

  return [
    VLifeStatus.ActivePaid,
    VLifeStatus.ActiveFoc,
    VLifeStatus.ActiveTrial,
    VLifeStatus.ActiveNonRefundable,
  ].includes(vLifeStatus);
};
