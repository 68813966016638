import {
  Schema,
  componentTypes,
  validatorTypes,
  dataTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import {
  GetCreatePcsOptionsQuery,
  GetProductionControlSystemQuery,
} from '~/graphql/generated/asset/graphql';
import { getRecentYears } from '~/utils/date-utils';

interface GetUmbilicalEditSchemaProps {
  t: TFunction;
  manufacturers?: GetCreatePcsOptionsQuery['manufacturers'];
  electricalDistributionTypes?: GetCreatePcsOptionsQuery['electricalDistributionTypes'];
  electricalTopologies?: GetCreatePcsOptionsQuery['electricalTopologies'];
  platformName?: string;
  productionControlSystem?: GetProductionControlSystemQuery['productionControlSystem'];
  editing?: string;
}

/**
 * This schema also serves as the create schema returned by getPlatformCreateSchema
 */
export function getPcsEditSchema({
  t,
  manufacturers,
  electricalDistributionTypes,
  electricalTopologies,
  platformName,
  productionControlSystem,
}: GetUmbilicalEditSchemaProps): Schema {
  const createSchema = {
    fields: [
      {
        name: 'pcsManufacturerId',
        sortAlphabetical: true,
        isSearchable: true,
        label: t('pcs-forms::pcs-manufacturer'),
        initialValue: productionControlSystem?.pcsManufacturer,
        component: componentTypes.SELECT,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: manufacturers,
      },
      {
        name: 'pcsApproxInstallationDate',
        component: componentTypes.SELECT,
        dataType: dataTypes.INTEGER,
        initialValue: {
          id: productionControlSystem?.pcsApproxInstallationDate,
          name: productionControlSystem?.pcsApproxInstallationDate,
        },
        label: t('pcs-forms::pcs-approx-installation-date'),
        options: getRecentYears(),
      },
      {
        name: 'epuManufacturerId',
        label: t('pcs-forms::epu-manufacturer'),
        component: componentTypes.SELECT,
        initialValue: productionControlSystem?.epuManufacturer,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: manufacturers,
      },
      {
        name: 'epuApproxInstallationDate',
        component: componentTypes.SELECT,
        dataType: dataTypes.INTEGER,
        initialValue: {
          id: productionControlSystem?.epuApproxInstallationDate,
          value: productionControlSystem?.epuApproxInstallationDate,
        },
        label: t('pcs-forms::epu-approx-installation-date'),
        options: getRecentYears(),
      },
      {
        name: 'maximumOperatingVoltage',
        label: t('pcs-forms::max-voltage/label'),
        component: componentTypes.TEXT_FIELD,
        initialValue: productionControlSystem?.maximumOperatingVoltage,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'V',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('pcs-forms::max-voltage/message'),
          },
        ],
      },
      {
        name: 'electricalDistributionTypeId',
        label: t('pcs-forms::electrical-distribution-type'),
        initialValue: productionControlSystem?.electricalDistributionType,
        component: componentTypes.SELECT,
        isClearable: true,
        options: electricalDistributionTypes,
      },
      {
        name: 'frequency',
        label: t('pcs-forms::frequency/label'),
        component: componentTypes.TEXT_FIELD,
        initialValue: productionControlSystem?.frequency,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'Hz',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('pcs-forms::frequency/message'),
          },
        ],
      },
      {
        name: 'electricalTopologyId',
        label: t('pcs-forms::electrical-topology'),
        initialValue: productionControlSystem?.electricalTopology,
        component: componentTypes.SELECT,
        isClearable: true,
        options: electricalTopologies,
      },
      {
        name: 'platform',
        label: t('pcs-forms::platform'),
        component: componentTypes.PLAIN_TEXT,
        initialValue: platformName,
        sx: { gridColumnEnd: 'span 2' },
      },
    ],
  };
  return createSchema;
}
