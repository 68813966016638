import { DateTime } from 'luxon';
import { ValidatorFunction } from '@data-driven-forms/react-form-renderer';
import { INVALID_DATE_VALUE } from '../formFields/DatePicker/DatePicker';
import { ValidateChannels } from '~/features/viper-asset/hooks/useValidateLifecycleEvent';

export function getStartDateFormValidator(
  existingEndDate: DateTime | undefined,
  errorMessage: string
) {
  if (!existingEndDate) {
    return () => undefined;
  }
  return (values: Record<string, unknown>): Record<string, string> | undefined => {
    const startDateISO = values.startDate as string | undefined;
    const startDate = startDateISO && DateTime.fromISO(startDateISO);
    if (startDate && startDate <= existingEndDate) {
      return {
        startDate: errorMessage,
      };
    }
    return undefined;
  };
}
export function getEndDateBeforeNextFormValidator(
  nextDate: DateTime | undefined,
  errorMessage: string
) {
  if (!nextDate) {
    return () => undefined;
  }
  return (values: Record<string, unknown>): Record<string, string> | undefined => {
    const endDateISO = values.expiryDateCommToCustomer as string | undefined;
    const endDate = endDateISO && DateTime.fromISO(endDateISO);
    if (endDate && endDate >= nextDate) {
      return {
        expiryDateCommToCustomer: errorMessage,
      };
    }
    return undefined;
  };
}

export function getDisableDateWithinDatesFormValidator(
  startDate: DateTime | undefined,
  endDate: DateTime | undefined,
  errorMessage: string
) {
  if (!endDate && !startDate) {
    return () => undefined;
  }
  return (values: Record<string, unknown>): Record<string, string> | undefined => {
    if (values.disableDate) {
      const disableDate = DateTime.fromISO(values.disableDate as string);
      if (startDate) {
        if (disableDate < startDate) {
          return {
            disableDate: errorMessage,
          };
        }
      }
      if (endDate) {
        if (disableDate > endDate) {
          return {
            disableDate: errorMessage,
          };
        }
      }
    }
    return undefined;
  };
}

export function getEndDateAfterStartFormValidator(errorMessage: string) {
  return (values: Record<string, unknown>): Record<string, string> | undefined => {
    const endDate = DateTime.fromISO(values['expiryDateCommToCustomer'] as string);
    const startDate = DateTime.fromISO(values['startDate'] as string);
    if (endDate < startDate) {
      return {
        expiryDateCommToCustomer: errorMessage,
      };
    }
    return undefined;
  };
}
export function getConfigDateFormValidator(errorMessage: string) {
  return (values: Record<string, unknown>): Record<string, string> | undefined => {
    const endDate = DateTime.fromISO(values['expiryDateCommToCustomer'] as string);
    const configDate = DateTime.fromISO(values['expiryDateInConfig'] as string);
    if (configDate < endDate) {
      return {
        expiryDateInConfig: errorMessage,
      };
    }
    return undefined;
  };
}

export function getNotEarlierThanReferenceDateFormValidator(
  key: string,
  referenceDate: DateTime | undefined,
  errorMessage: string
) {
  return (values: Record<string, unknown>): Record<string, string> | undefined => {
    if (!referenceDate) {
      return undefined;
    }
    const currentDate = DateTime.fromISO(values[key] as string);
    if (currentDate < referenceDate) {
      return {
        [key]: errorMessage,
      };
    }
    return undefined;
  };
}

export function getNotEarlierThanChannelDateFormValidator(
  key: string,
  channels: ValidateChannels | undefined,
  errorMessage: string
) {
  return (values: Record<string, unknown>) => {
    if (values['channel']) {
      const channel = channels?.find((channel) => channel.id === values['channel']);
      const channelDate = DateTime.fromISO(
        channel?.latestLifecycleEvent?.dateOfLifecycleEvent as string
      );
      return getNotEarlierThanReferenceDateFormValidator(
        key,
        channelDate,
        errorMessage
      )(values);
    }
    return {};
  };
}

/**
 * Validate a date which has come from a date picker. As such, we only expect
 * to receive values which luxon's DateTime class can produce, which are either
 * ISO date objects, or the string 'Invalid Date'.
 */
export function createValidDateFieldValidator(options?: object) {
  const validator: ValidatorFunction = (value: string) =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value === INVALID_DATE_VALUE ? (options as any)?.message || INVALID_DATE_VALUE : undefined;
  return validator;
}

export const DEFAULT_FUTURE_DATE_MSG = 'Date must be in the future';

export function createFutureDateFieldValidator(options?: object) {
  const validator: ValidatorFunction = (value: string) => {
    const dueDate = DateTime.fromISO(value);
    const today = DateTime.now();
    return dueDate < today
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (options as any)?.message || DEFAULT_FUTURE_DATE_MSG
      : undefined;
  };
  return validator;
}

export const DEFAULT_NOT_FUTURE_DATE_MSG = 'Date must not be in the future';

export function createNotFutureDateFieldValidator(options?: object) {
  const validator: ValidatorFunction = (value: string) => {
    const dueDate = DateTime.fromISO(value);
    const today = DateTime.now();
    return dueDate > today
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (options as any)?.message || DEFAULT_NOT_FUTURE_DATE_MSG
      : undefined;
  };
  return validator;
}
