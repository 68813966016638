import {
  useFieldApi,
  UseFieldApiConfig,
  useFormApi,
} from '@data-driven-forms/react-form-renderer';
import { validationError } from '../validationError';
import { Box, Link, Stack, Typography } from '@mui/material';
import { FormLabel } from '../FormLabel/FormLabel';
import { formatDate } from '~/utils/date-utils';

interface ActionLinkType {
  label: string;
  action: (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: unknown,
    values: unknown
  ) => void;
  disabled?: boolean | ((value: unknown, values: unknown) => boolean);
}

export const PlainText = (
  props: UseFieldApiConfig & {
    actionLinks: ActionLinkType[];
    isRequiredForReporting?: boolean;
  }
) => {
  const {
    input,
    isRequired,
    label,
    validateOnMount,
    meta,
    sx,
    hiddenField,
    suffix,
    noDataCharacter = '–',
  } = useFieldApi(props);
  const formApi = useFormApi();
  const invalid = validationError(meta, validateOnMount);
  const { actionLinks } = props;

  return (
    <Box sx={{ display: hiddenField ? 'none' : 'inherit', ...sx }}>
      <Stack direction='row' justifyContent={'space-between'}>
        <FormLabel
          sx={{ color: (theme) => (invalid ? theme.palette.error.main : 'inherit') }}
          id={input.name}
          isRequiredForReporting={props.isRequiredForReporting}
        >
          {isRequired && (
            <sup style={{ position: 'absolute', top: -3, left: -6 }}>*&nbsp;</sup>
          )}
          {label}
        </FormLabel>

        {actionLinks && (
          <Stack direction='row' spacing={1}>
            {actionLinks.map(({ label, action, disabled }) => {
              if (typeof disabled === 'function') {
                disabled = disabled(input.value, formApi.getState().values);
              }
              return (
                <Link
                  component='button'
                  type='button'
                  onClick={(event) => {
                    event.preventDefault();
                    !disabled && action(event, input.value, formApi.getState().values);
                  }}
                  key={label}
                  sx={{
                    cursor: disabled ? 'default' : 'pointer',
                    fontSize: 14,
                    textDecorationColor: (theme) =>
                      disabled ? theme.palette.grey[700] : theme.palette.primary.main,
                    color: (theme) =>
                      disabled ? theme.palette.grey[700] : theme.palette.primary.main,
                    '&:hover': {
                      textDecorationColor: (theme) =>
                        disabled ? theme.palette.grey[700] : theme.palette.primary.light,
                      color: (theme) =>
                        disabled ? theme.palette.grey[700] : theme.palette.primary.light,
                    },
                  }}
                  disabled={disabled}
                >
                  {label}
                </Link>
              );
            })}
          </Stack>
        )}
      </Stack>
      <Typography
        sx={{ py: 1.75 }}
        data-testid={`plain-text-${input.name}`}
        aria-labelledby={input.name}
        style={{ whiteSpace: 'pre-line' }}
      >
        {formatValue(input, noDataCharacter, suffix)}
      </Typography>
    </Box>
  );
};

export function formatValue(
  input: { value?: string | number | boolean; type?: string },
  noDataCharacter: string,
  suffix?: string,
  trueLabel = '✓',
  falseLabel = '×'
): React.ReactNode {
  const conditionalSuffix = (input.value || input.value === 0) && suffix ? ` ${suffix}` : '';
  switch (typeof input.value) {
    case 'string':
      if (input.type === 'date') {
        return formatDate(input.value);
      }
      return `${input.value || noDataCharacter}${conditionalSuffix}`;
    case 'boolean':
      return input.value ? trueLabel : falseLabel;
    default:
      return `${input.value ?? noDataCharacter}${conditionalSuffix}`;
  }
}
