import { QueryResult } from '@apollo/client';
import { useEffect } from 'react';
import { useGetAssetVersion } from '~/utils/api-versions/queries/useGetAssetVersion';
import { useGetMeasurementVersion } from '~/utils/api-versions/queries/useGetMeasurementVersion';
import { useGetVdrVersion } from '~/utils/api-versions/queries/useGetVdrVersion';

function useLogApiVersion(
  useVersion: () => QueryResult<
    { version: string },
    {
      [key: string]: never;
    }
  >,
  name: string
) {
  const { data, loading } = useVersion();

  useEffect(() => {
    if (!loading) {
      console.log(`${name} API version: ${data?.version ?? 'Not available'}`);
    }
  }, [data, loading, name]);
}

export function useLogApiVersions() {
  useLogApiVersion(useGetAssetVersion, 'Asset');
  useLogApiVersion(useGetMeasurementVersion, 'Measurement');
  useLogApiVersion(useGetVdrVersion, 'VDR');
}
