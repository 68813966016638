import { useCallback, useEffect, useMemo, useState } from 'react';
import { componentTypes } from '@data-driven-forms/react-form-renderer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { env } from '~/config/environmentVariables';
import { extraComponents } from '~/components/Form/utils/extraComponents';
import { Comments } from '~/components/Form/formFields/Comments/Comments';
import { PdfViewer } from '~/components/PdfViewer/PdfViewer';
import { UploadField } from '~/components/Upload/UploadField';
import { useUpload } from '~/components/Upload/useUpload';
import {
  useGetVLifeReportForm,
  useGetVLifeReportPreviewDownloadToken,
  useRemoveCoverPage,
} from '~/features/v-life-reports/queries/v-life-reports';

// t('date')
// t('message')

export const ReportGrades = [
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
];

export function usePublishVLifeReportFormSchema() {
  const { t } = useTranslation('publish-v-life-report-form');
  const { reportFormId } = useParams();
  const {
    data: reportForm,
    refetch: refetchReport,
    loading: reportLoading,
  } = useGetVLifeReportForm(reportFormId);
  const { uploadFile } = useUpload();

  const [url, setUrl] = useState<string>();
  const [uploading, setUploading] = useState(false);
  const [getDownloadToken] = useGetVLifeReportPreviewDownloadToken();
  const [removeCoverPage, { error: removeCoverPageError, loading: removing }] =
    useRemoveCoverPage(reportFormId);

  const fetchPDF = useCallback(() => {
    getDownloadToken({ variables: { id: reportFormId ?? '' } }).then((res) => {
      setUrl(
        getPdfViewEndpoint(reportFormId ?? '', res.data?.vlifeReportPreviewDownloadToken ?? '')
      );
    });
  }, [getDownloadToken, reportFormId]);

  useEffect(fetchPDF, [fetchPDF]);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      if (files) {
        await Promise.all(
          files.map((file) =>
            uploadFile(
              `${env.REACT_APP_ASSET_API_ENDPOINT}/vlifereport/coverpage/${reportFormId}`,
              file,
              () => {
                refetchReport({ id: reportFormId }).then(() => setUploading(false));
              },
              () => {
                setUploading(true);
                /*noOp*/
              }
            )
          )
        );
      }
    },
    [refetchReport, reportFormId, uploadFile]
  );

  const hasCoverPage = useMemo(
    () => reportForm?.vlifeReportForm?.coverPageProvided,
    [reportForm?.vlifeReportForm?.coverPageProvided]
  );

  const handleRemoveCoverPage = useCallback(async () => {
    await removeCoverPage({ variables: { vlifeReportFormId: reportFormId ?? '' } });
  }, [removeCoverPage, reportFormId]);

  // build schema
  const schema = useMemo(() => {
    return {
      name: 'publishVLifeReportForm',
      title: t('publish-report-form'),
      fields: [
        {
          name: 'pdfPreview',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'pdfPreview',
              component: extraComponents.CUSTOM,
              child: <PdfViewer url={url} />,
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
        {
          name: 'upload',
          label: t('upload-cover-page'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'uploadCoverPage',
              component: extraComponents.CUSTOM,
              child: (
                <UploadField
                  extension='.pdf'
                  accept={{ 'application/pdf': ['.pdf'] }}
                  onUpload={(files: File[]) => {
                    uploadFiles(files);
                  }}
                  onSecondaryAction={hasCoverPage ? handleRemoveCoverPage : undefined}
                  secondaryActionDisabled={removing}
                  uploadText={t('upload-cover')}
                  secondaryActionText={t('remove-cover')}
                />
              ),
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
        {
          name: 'internalDiscussion',
          label: t('internal-discussion'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'comments',
              component: extraComponents.CUSTOM,
              child: <Comments />,
              initialValue: reportForm?.vlifeReportForm?.comments,
              sx: { gridColumnEnd: 'span 2' },
            },
            {
              name: 'comment',
              label: t('comment'),
              component: componentTypes.TEXTAREA,
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
      ],
    };
  }, [
    handleRemoveCoverPage,
    hasCoverPage,
    removing,
    reportForm?.vlifeReportForm?.comments,
    t,
    uploadFiles,
    url,
  ]);
  return {
    schema,
    reportForm,
    loading: reportLoading || uploading || removing,
    removeCoverPageError,
  };
}

export function getPdfViewEndpoint(id: string, token: string) {
  return `${
    env.REACT_APP_ASSET_API_ENDPOINT
  }/vlifereport/preview/${id}?token=${encodeURIComponent(token)}`;
}
