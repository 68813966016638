import { formatDate } from '~/utils/date-utils';
import { StatusProps } from '~/components/Status/Status';
import { GetChannelQuery, VLifeStatus } from '~/graphql/generated/asset/graphql';
import { useTranslation } from 'react-i18next';

export const useStatusProps = ({
  data,
  editing,
  onDeactivatedClick,
  onActivatedClick,
}: {
  data: GetChannelQuery | undefined;
  editing: string | undefined;
  onDeactivatedClick: () => void;
  onActivatedClick: () => void;
}): StatusProps => {
  const { t } = useTranslation('disable-v-life-config');

  const vLifeStatus = data?.channel?.vLifeTimeline?.currentStatus;

  switch (vLifeStatus) {
    case VLifeStatus.ActiveTrial:
      return {
        vLifeStatus: VLifeStatus.ActiveTrial,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('trial-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('trial'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case VLifeStatus.ActivePaid:
      return {
        vLifeStatus: VLifeStatus.ActivePaid,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('paid-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('paid'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case VLifeStatus.ActiveFoc:
      return {
        vLifeStatus: VLifeStatus.ActiveFoc,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('free-of-charge-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('free-of-charge'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case VLifeStatus.ActiveNonRefundable:
      return {
        vLifeStatus: VLifeStatus.ActiveNonRefundable,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('non-refundable-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('non-refundable'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case VLifeStatus.Suspended:
      return {
        vLifeStatus: VLifeStatus.Suspended,
        title: t('v-life'),
        ctaColor: 'secondary',
        ctaCopy: t('renew-licence'),
        description: data?.channel?.mostRecentVLifeConfig?.resumptionDate
          ? t('suspended-with-date', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig?.resumptionDate),
            })
          : t('suspended'),
        buttonDisabled: !!editing,
        onClickCta: onDeactivatedClick,
      };
    case VLifeStatus.Disabled:
      return {
        vLifeStatus: VLifeStatus.Disabled,
        title: t('v-life'),
        description: t('deactivated'),
        ctaColor: 'secondary',
        ctaCopy: t('renew-licence'),
        buttonDisabled: !!editing,
        onClickCta: onDeactivatedClick,
      };
    default:
      return {
        // DEFAULT EXPIRED
        vLifeStatus: VLifeStatus.Expired,
        title: t('v-life'),
        description: t('expired'),
        ctaColor: 'secondary',
        ctaCopy: t('renew-licence'),
        buttonDisabled: !!editing,
        onClickCta: onDeactivatedClick,
      };
  }
};
