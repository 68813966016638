import { DateRange } from '~/common/types';
import { useLicenceInsightsRows } from '~/features/licence-insights/hooks/useLicenceInsightsRows';
import { useLicenceInsightsColumns } from '~/features/licence-insights/hooks/useLicenceInsightsColumns';
import { ToolbarFilters } from '~/features/licence-insights/types';
import { useFilterRows } from '~/features/licence-insights/hooks/useFilterRows';

export const useLicenceInsightsTable = (
  evaluationPeriod: DateRange,
  filters: ToolbarFilters
) => {
  const { rows, loading } = useLicenceInsightsRows();
  const { columns } = useLicenceInsightsColumns();
  const { filteredRows } = useFilterRows({ rows, evaluationPeriod, filters });

  return {
    rows: filteredRows,
    columns,
    loading,
  };
};
