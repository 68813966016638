import { InstalledViperAssetPeriod } from '~/graphql/generated/asset/graphql';

interface FormatInstalledViperAssetPeriodsProps {
  value?: InstalledViperAssetPeriod[];
}

export const formatInstalledViperAssetPeriods = ({
  value,
}: FormatInstalledViperAssetPeriodsProps) => {
  return value && value.length ? value.length : '';
};
