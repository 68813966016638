import React from 'react';
import { WarningBar } from '../Form/WarningBar/WarningBar';
import { Box, BoxProps } from '@mui/material';
import { WarningLink } from '~/components/WarningLink/WarningLink';

export const WarningList: React.FC<{ messages: WarningLink[] } & BoxProps> = ({
  messages,
  ...props
}) => {
  return (
    <Box {...props}>
      {messages.map((warning) => (
        <WarningBar key={warning.id} message={<WarningLink {...warning} />} />
      ))}
    </Box>
  );
};
