import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useCreateProductionControlSystem,
  useGetCreatePcsOptions,
} from '~/features/customer-asset/queries/customer-asset';
import { Platform } from '~/graphql/generated/asset/graphql';
import { CreateEditEntityDialog } from '../../../../components/CreateEditEntityDialog/CreateEditEntityDialog';
import { getPcsCreateSchema } from '~/features/customer-asset/form-schemas/create/pcs/pcsCreateSchema';

export const CreatePcsDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  platform?: Platform;
}> = ({ open, onClose, platform }) => {
  const { t } = useTranslation('production-control-systems');

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('create-production-control-system')}
      errorMessage={t('error-creating')}
      successMessage={() => t('created-successfully')}
      useGetOptions={useGetCreatePcsOptions}
      useMutate={useCreateProductionControlSystem}
      getSchema={({ t, options }) =>
        getPcsCreateSchema({
          t,
          manufacturers: options?.manufacturers,
          electricalDistributionTypes: options?.electricalDistributionTypes,
          electricalTopologies: options?.electricalTopologies,
          platformName: platform?.name,
        }).fields[0].fields[0].fields
      }
      handleMutate={async (create, values) => {
        await create({
          variables: {
            productionControlSystemInput: {
              epuManufacturerId: values.epuManufacturerId as string,
              pcsApproxInstallationDate: values.pcsApproxInstallationDate as number,
              pcsManufacturerId: values.pcsManufacturerId as string,
              electricalDistributionTypeId: values.electricalDistributionTypeId as string,
              electricalTopologyId: values.electricalTopologyId as string,
              epuApproxInstallationDate: values.epuApproxInstallationDate as number,
              frequency: values.frequency as number,
              maximumOperatingVoltage: values.maximumOperatingVoltage as number,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              platformId: platform!.id,
            },
          },
        });
        return '-';
      }}
    />
  );
};
