import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetContacts } from '~/features/contacts/queries/contact';
import { componentTypes, validatorTypes } from '@data-driven-forms/react-form-renderer';
import { extraComponents } from '~/components/Form/utils/extraComponents';
import { useAccount } from '@azure/msal-react';
import {
  useGetChannel,
  useGetInstalledViperAssetPeriodAndMaxIRCaps,
} from '~/features/customer-asset/queries/customer-asset';
import { useGetLatestFloatReadingsForAssets } from '~/features/v-logs/queries/readings-measurement';
import { formatPerson } from '~/utils/format-person';
import { getAllMonthsBetweenStartAndEnd } from '~/utils/date-utils';
import { extraValidatorTypes } from '~/components/Form/utils/validators';
import { VIPER_COMPANY_ID } from '~/common/constants';
import { FloatMeasurementType } from '~/graphql/generated/measurement/graphql';
import { useGetReportWarnings } from '~/features/v-life-reports/hooks/useGetReportWarnings';
import { sortArrayByKey } from '~/utils/sortArrayByKey';
import { useGetMissingReadingsWarnings } from '~/features/v-life-reports/hooks/useGetMissingReadingsWarnings';
import { LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { useGetVLifeReportFormDefaults } from '~/features/v-life-reports/queries/v-life-reports';

export function useCreateVLifeReportFormSchema() {
  const { t, i18n } = useTranslation('create-v-life-report-form');
  const { channelId } = useParams<{ channelId: string }>();
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();

  // get account for logged in user
  const account = useAccount();

  // get channel data for some of the form defaults
  const { data: channelData, loading: loadingChannel } = useGetChannel(channelId);
  const channel = channelData?.channel;

  const vLifeConfigs = useMemo(
    () => [...(channel?.historicalVLifeConfigs ?? []), channel?.mostRecentVLifeConfig],
    [channel?.historicalVLifeConfigs, channel?.mostRecentVLifeConfig]
  );

  const dateSortMapper = (value: unknown) => new Date(`${value}`).getTime();

  const mostRecentViperAssetInstallationDate = useMemo(() => {
    if (!endDate) {
      return undefined;
    }
    return channel?.lifecycleEvents
      .filter((event) => event.eventType === LifecycleEventType.Installation)
      .filter((event) => event.dateOfLifecycleEvent < endDate)?.[0].dateOfLifecycleEvent;
  }, [channel?.lifecycleEvents, endDate]);

  const firstVLifeConfigStartDate = useMemo(() => {
    const sortedVLifeConfigs = sortArrayByKey(vLifeConfigs, 'startDate', true, dateSortMapper);
    return sortedVLifeConfigs[0]?.startDate;
  }, [vLifeConfigs]);

  const vlifeStartDate = channel?.lifetimeStartDate ?? firstVLifeConfigStartDate;

  const lastVLifeConfigEndDate = useMemo(() => {
    const sortedVLifeConfigs = sortArrayByKey(
      vLifeConfigs,
      'expiryDateCommToCustomer',
      false,
      dateSortMapper
    );
    return sortedVLifeConfigs[0]?.expiryDateCommToCustomer;
  }, [vLifeConfigs]);

  const {
    loading: loadingViperAssets,
    viperAssetPeriods,
    viperAssetMaxIRCaps,
  } = useGetInstalledViperAssetPeriodAndMaxIRCaps(channelId, startDate, endDate);

  const { warnings, loading: loadingWarnings } = useGetReportWarnings(
    channelId,
    startDate,
    endDate
  );

  const { warnings: measurementDataWarnings, loading: loadingMeasurementWarnings } =
    useGetMissingReadingsWarnings(channelId, startDate, endDate);

  // get defaults
  const { data, loading: loadingFormDefaults } = useGetVLifeReportFormDefaults(channelId);
  const defaults = data?.vlifeReportFormDefaults;

  // get options
  const { data: contacts, loading: loadingContacts } = useGetContacts();

  const people = useMemo(() => {
    if (!contacts) return [];
    return contacts.people.filter((contact) => contact.company.id === VIPER_COMPANY_ID);
  }, [contacts]);

  // Replace the viper asset period end date with report period end date if the

  // Get latest lim resistance readings
  const {
    data: newIrReadings,
    loading: loadingIRReadings,
    previousData: previousIrReadings,
  } = useGetLatestFloatReadingsForAssets(
    [viperAssetPeriods],
    viperAssetMaxIRCaps,
    FloatMeasurementType.Limresistance
  );
  // Keep old data until new data has loaded so that the screen doesn't turn into a skeleton
  // while the user is typing a date
  const irReadings = loadingIRReadings ? previousIrReadings : newIrReadings;

  // calculate overallIRIncrease
  const insulationResistanceAtEndOfReportingPeriod = useMemo(
    () => irReadings?.getLatestFloatReadingsForAssets?.slice(-1)[0]?.value,
    [irReadings?.getLatestFloatReadingsForAssets]
  );

  const overallIRIncrease = useMemo(
    () =>
      insulationResistanceAtEndOfReportingPeriod &&
      defaults?.insulationResistanceAtCommissioning
        ? Number(insulationResistanceAtEndOfReportingPeriod) -
          defaults?.insulationResistanceAtCommissioning
        : null,
    [defaults?.insulationResistanceAtCommissioning, insulationResistanceAtEndOfReportingPeriod]
  );

  useEffect(() => {
    if (defaults?.reportPeriodStartDate) {
      setStartDate(defaults?.reportPeriodStartDate);
    }
    if (defaults?.reportPeriodEndDate) {
      setEndDate(defaults?.reportPeriodEndDate);
    }
  }, [defaults?.reportPeriodEndDate, defaults?.reportPeriodStartDate]);

  // build schema
  const schema = useMemo(() => {
    return {
      fields: [
        {
          name: 'defaults',
          label: t('create-report'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'warnings',
              type: 'boolean',
              isRequired: true,
              component: componentTypes.CHECKBOX,
              initialValue: measurementDataWarnings.length === 0,
              hideField: true,
              readOnly: true,
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
            },
            {
              name: 'customerName',
              label: t('customer'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: channel?.customer.name,
            },
            {
              name: 'platformName',
              label: t('platform'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: channel?.platform?.name,
            },
            {
              name: 'fieldName',
              label: t('field'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: channel?.field.name,
            },
            {
              name: 'channelName',
              label: t('channel'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: channel?.name,
            },
            {
              name: 'insulationResistanceAtCommissioning',
              label: t('insulation-resistance-at-commissioning'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: channel?.referenceIR,
              type: 'number',
              suffix: 'Ω',
            },
            {
              name: 'insulationResistanceAtEndOfReportingPeriod',
              label: t('insulation-resistance-at-end-of-reporting-period'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: insulationResistanceAtEndOfReportingPeriod,
              type: 'number',
              suffix: 'Ω',
            },
            {
              name: 'vlifeFirstAppliedDate',
              type: 'date',
              label: t('vlife-first-applied-date'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: vlifeStartDate,
            },
            {
              name: 'vlifeLicenceExpiryDate',
              type: 'date',
              label: t('vlife-licence-expiry-date'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: lastVLifeConfigEndDate,
            },
            {
              name: 'currentUnitSerialNumber',
              label: t('unit-serial-number'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: getSerialNumbers(
                t('current'),
                t('previous'),
                defaults?.unitSerialNumbers
              ),
            },
            {
              name: 'unitSerialNumbers',
              component: componentTypes.PLAIN_TEXT,
              initialValue: defaults?.unitSerialNumbers,
              hiddenField: true,
            },
            {
              name: 'currentFirmwareRevision',
              label: t('current-firmware-revision'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: defaults?.currentFirmwareRevision,
            },
          ],
        },
        {
          name: 'irIncrease',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'overallIRIncrease',
              label: t('overall-ir-increase'),
              component: componentTypes.TEXT_FIELD,
              sx: { gridColumnEnd: 'span 2' },
              initialValue: overallIRIncrease,
              isDisabled: irReadings?.getLatestFloatReadingsForAssets?.length === 0,
              isRequired: true,
              suffix: 'Ω',
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
              type: 'number',
              resolveProps: (
                _props: unknown,
                _field: unknown,
                { getState }: { getState: () => { values: Record<string, string> } }
              ): Record<string, unknown> | undefined => {
                const value = getState().values.insulationResistanceAtEndOfReportingPeriod;
                if (!value) {
                  return {
                    helperText: t('change-time-period-to-calculate-default-ir-increase'),
                  };
                }
              },
            },
          ],
        },
        {
          name: 'reportInfo',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'reportPeriodStartDate',
              label: t('report-period-start-date'),
              component: componentTypes.DATE_PICKER,
              isRequired: true,
              validate: [
                { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
                { type: validatorTypes.REQUIRED, message: t('viper::required') },
                (value: string) => {
                  if (value < (channel?.lifetimeStartDate || '')) {
                    return t('report-start-date-must-not-be-before-lifetime-start-date');
                  }
                },
              ],
              initialValue: startDate ?? defaults?.reportPeriodStartDate,
              resolveProps: (
                _props: unknown,
                _field: unknown,
                { getState }: { getState: () => { values: Record<string, string> } }
              ): Record<string, unknown> | undefined => {
                const value = getState().values.reportPeriodStartDate;
                if (value) {
                  setStartDate(value);
                }
                return {
                  value,
                };
              },
            },
            {
              name: 'reportPeriodEndDate',
              label: t('report-period-end-date'),
              component: componentTypes.DATE_PICKER,
              initialValue: endDate ?? defaults?.reportPeriodEndDate,
              isRequired: true,
              validate: [
                { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
                { type: validatorTypes.REQUIRED, message: t('viper::required') },
              ],
              resolveProps: (
                _props: unknown,
                _field: unknown,
                { getState }: { getState: () => { values: Record<string, string> } }
              ): Record<string, unknown> | undefined => {
                const value = getState().values.reportPeriodEndDate;
                if (value) {
                  setEndDate(value);
                }
                return {
                  value,
                };
              },
            },
            {
              name: 'approver',
              label: t('approver'),
              isSearchable: true,
              sortAlphabetical: true,
              component: componentTypes.SELECT, // Person
              options: people?.map((person) => ({
                id: person.id,
                name: formatPerson(person),
              })),
              isRequired: true,
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
            },
            {
              name: 'suggestedReviewer',
              label: t('suggested-reviewer'),
              isSearchable: true,
              sortAlphabetical: true,
              component: componentTypes.SELECT,
              isClearable: true,
              options: people?.map((person) => ({
                id: person.id,
                name: formatPerson(person),
              })),
            },
            {
              name: 'monthCovered',
              label: t('month-covered'),
              component: componentTypes.SELECT,
              resolveProps: (
                _props: unknown,
                _field: unknown,
                { getState }: { getState: () => { values: Record<string, string> } }
              ): Record<string, unknown> | undefined => {
                const startDate = getState().values.reportPeriodStartDate;
                const endDate = getState().values.reportPeriodEndDate;

                return {
                  disabled: !startDate || !endDate,
                  options: getAllMonthsBetweenStartAndEnd(
                    startDate,
                    endDate,
                    i18n?.language ?? 'en-gb'
                  ),
                  helperText:
                    !startDate || !endDate
                      ? t('customer-asset::select-start-and-end-date-first')
                      : undefined,
                };
              },
              isRequired: true,
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
            },
            {
              name: 'creator',
              label: t('creator'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: account?.name,
            },
            {
              name: 'projectCode',
              label: t('project-code'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: defaults?.projectCode,
            },
          ],
        },
        {
          name: 'informationRequest',
          label: t('information-request'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'nextReportDue',
              label: t('next-report-due'),
              component: componentTypes.DATE_PICKER,
              initialValue: defaults?.nextReportDue,
              sx: { gridColumnEnd: 'span 2' },
              isRequired: true,
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
            },
          ],
        },
        {
          name: 'internalDiscussion',
          label: t('internal-discussion'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'comment',
              label: t('add-comment'),
              component: componentTypes.TEXTAREA,
              sx: { gridColumnEnd: 'span 2' },
            },
            {
              name: 'logs',
              component: componentTypes.TEXTAREA,
              initialValue: [],
              hideField: true,
            },
          ],
        },
      ],
    };
  }, [
    t,
    measurementDataWarnings.length,
    channel?.customer.name,
    channel?.platform?.name,
    channel?.field.name,
    channel?.name,
    channel?.referenceIR,
    channel?.lifetimeStartDate,
    insulationResistanceAtEndOfReportingPeriod,
    vlifeStartDate,
    lastVLifeConfigEndDate,
    defaults?.unitSerialNumbers,
    defaults?.currentFirmwareRevision,
    defaults?.reportPeriodStartDate,
    defaults?.reportPeriodEndDate,
    defaults?.projectCode,
    defaults?.nextReportDue,
    overallIRIncrease,
    irReadings?.getLatestFloatReadingsForAssets?.length,
    startDate,
    endDate,
    people,
    account?.name,
    i18n?.language,
  ]);

  /** gettingLogs is here so that we don't show the page as having loaded
   * before the logs have even started to load, as their load is initiated
   * in an effect
   */
  const loading =
    loadingChannel ||
    loadingContacts ||
    loadingFormDefaults ||
    loadingViperAssets ||
    loadingWarnings ||
    loadingMeasurementWarnings;

  return {
    schema,
    loading,
    startDate,
    endDate,
    warnings,
    mostRecentViperAssetInstallationDate,
  };
}

export function getSerialNumbers(
  currentLabel: string,
  previousLabel: string,
  unitSerialNumbers?: string[],
  emptyCharacter = '–'
) {
  const currentSerialNumber = unitSerialNumbers?.slice(-1)[0];
  const previousSerialNumbers = unitSerialNumbers?.slice(0, -1).reverse();

  if (unitSerialNumbers?.length === 0) {
    return emptyCharacter;
  }

  let label = currentLabel + ': ' + currentSerialNumber;
  label += previousSerialNumbers?.length
    ? ' ' + previousLabel + ': ' + previousSerialNumbers.join(', ')
    : '';
  return label;
}
