import {
  Schema,
  componentTypes,
  validatorTypes,
  dataTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { Platform } from '~/graphql/generated/asset/graphql';

interface GetPlatformEditSchemaProps {
  t: TFunction;
  platform?: Platform | null;
  editing?: string;
}

/**
 * This schema also serves as the create schema returned by getPlatformCreateSchema
 */
export function getPlatformEditSchema({ t, platform }: GetPlatformEditSchemaProps): Schema {
  const createSchema = {
    fields: [
      {
        name: 'name',
        label: t('platform-forms::platform-name'),
        component: componentTypes.TEXT_FIELD,
        initialValue: platform?.name,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'waterDepth',
        label: t('platform-forms::water-depth/label'),
        component: componentTypes.TEXT_FIELD,
        initialValue: platform?.waterDepth,
        dataType: dataTypes.INTEGER,
        type: 'number',
        suffix: 'm',
        validate: [
          {
            type: validatorTypes.MIN_NUMBER_VALUE,
            includeThreshold: true,
            value: 0,
            message: t('platform-forms::water-depth/message'),
          },
        ],
      },
    ],
  };
  return createSchema;
}
