import { VLifeReportLogMessage } from '~/graphql/generated/asset/graphql';
import { ChangeEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import { CustomInput } from '~/components/Table/components/CustomInput';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton } from '@mui/material';

interface AddMessageToolbarProps {
  onAddMessage: (message: VLifeReportLogMessage) => void;
}

export const AddMessageToolbar = ({ onAddMessage }: AddMessageToolbarProps) => {
  const { t } = useTranslation('add-message-toolbar');
  const [messageId, setMessageId] = useState(-1);
  const [message, setMessage] = useState<string>();
  const [date, setDate] = useState<string>();

  const handleMessageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  };

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleAddMessage = () => {
    if (message && date) {
      setMessage('');
      setDate('');
      setMessageId(messageId - 1);
      onAddMessage({
        __typename: 'VLifeReportLogMessage',
        id: `${messageId}`,
        message,
        date: `${date}:00Z`,
      });
    }
  };

  return (
    <Grid
      container
      p='4px'
      pb='8px'
      gridTemplateColumns={{ sm: 'auto', md: '1fr auto auto' }}
      columnGap='4px'
      rowGap='4px'
      display='grid'
      justifyContent='stretch'
    >
      <Grid item>
        <CustomInput
          style={{ flex: '1 1 100%', display: 'flex' }}
          type='text'
          placeholder={t('add-custom-message')}
          value={message}
          onChange={handleMessageChange}
        />
      </Grid>
      <Grid item>
        <CustomInput
          type='datetime-local'
          name='datetime'
          placeholder={t('date')}
          value={date}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid item>
        <MuiButton
          sx={{ height: 33 }}
          variant='outlined'
          onClick={handleAddMessage}
          disabled={!message || !date}
        >
          {t('add')}
        </MuiButton>
      </Grid>
    </Grid>
  );
};
