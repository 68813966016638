import React from 'react';
import { Typography } from '@mui/material';
import { useFieldApi, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';

interface SubHeadingProps {
  label: React.ReactNode;
  editing?: boolean;
}

export const SubHeading: React.FC<SubHeadingProps> = ({ label, editing }) => {
  return (
    <Typography
      component='h3'
      variant='h3'
      sx={{
        marginTop: 4,
        ml: editing ? 0 : 0.75,
        mb: 0.25,
        gridColumnEnd: 'span 2',
      }}
    >
      {label}
    </Typography>
  );
};

export const SubHeadingFormComponent: React.FC<UseFieldApiConfig> = (props) => {
  const { label } = useFieldApi(props);

  return <SubHeading label={label} editing={true} />;
};
