import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  AddVLifeReportDetailCommentDocument,
  ApproveVLifeReportFormDocument,
  CreateVLifeReportDocument,
  CreateVLifeReportFormDocument,
  DeleteVLifeReportFormDocument,
  GetPendingVLifeReportsDocument,
  GetVLifeReportDetailCommentsDocument,
  GetVLifeReportDocument,
  GetVLifeReportDownloadTokenDocument,
  GetVLifeReportFormDefaultsDocument,
  GetVLifeReportFormDocument,
  GetVLifeReportFormsForStateDocument,
  GetVLifeReportPreviewDownloadTokenDocument,
  GetVLifeReportsDocument,
  PublishVLifeReportFormDocument,
  RemoveCoverPageDocument,
  ReviewVLifeReportFormDocument,
  VLifeReportFormState,
} from '~/graphql/generated/asset/graphql';

export function useGetPendingVLifeReports() {
  return useQuery(GetPendingVLifeReportsDocument);
}

export function useGetVLifeReportForm(id?: string) {
  return useQuery(GetVLifeReportFormDocument, { variables: { id: id ?? '' }, skip: !id });
}

export function useGetVLifeReportFormDefaults(channelId?: string) {
  return useQuery(GetVLifeReportFormDefaultsDocument, {
    variables: { channelId: channelId ?? '' },
    skip: !channelId,
  });
}

export function useCreateVLifeReportForm() {
  return useMutation(CreateVLifeReportFormDocument);
}

export function useGetVLifeReportFormsForState(state?: VLifeReportFormState) {
  return useQuery(GetVLifeReportFormsForStateDocument, {
    variables: { state },
    skip: !state,
  });
}

export function useGetVLifeReportDetailComments(id: string, options?: { skip?: boolean }) {
  return useQuery(GetVLifeReportDetailCommentsDocument, {
    ...options,
    variables: { vlifeReportFormId: id },
  });
}

export function useAddVLifeReportDetailComment() {
  return useMutation(AddVLifeReportDetailCommentDocument, {
    refetchQueries: [{ query: GetVLifeReportFormsForStateDocument }],
  });
}

export function useReviewVLifeReportForm() {
  return useMutation(ReviewVLifeReportFormDocument);
}

export function useApproveVLifeReportForm() {
  return useMutation(ApproveVLifeReportFormDocument);
}

export function usePublishVLifeReportForm() {
  return useMutation(PublishVLifeReportFormDocument);
}

export function useDeleteVLifeReportForm() {
  return useMutation(DeleteVLifeReportFormDocument);
}

export function useRemoveCoverPage(id?: string) {
  return useMutation(RemoveCoverPageDocument, {
    refetchQueries: [{ query: GetVLifeReportFormDocument, variables: { id } }],
  });
}

export function useGetVLifeReports(channelId?: string) {
  return useQuery(GetVLifeReportsDocument, {
    variables: {
      channelId,
      skip: !channelId,
    },
  });
}

export function useGetVLifeReport(id: string) {
  return useQuery(GetVLifeReportDocument, { variables: { id } });
}

export function useGetVLifeReportPreviewDownloadToken() {
  return useLazyQuery(GetVLifeReportPreviewDownloadTokenDocument, {
    fetchPolicy: 'no-cache',
  });
}

export function useGetVLifeReportDownloadToken() {
  return useLazyQuery(GetVLifeReportDownloadTokenDocument, {
    fetchPolicy: 'no-cache',
  });
}

export function useCreateVLifeReport() {
  return useMutation(CreateVLifeReportDocument, {
    refetchQueries: (result) => {
      return [
        {
          query: GetVLifeReportsDocument,
          variables: {
            channelId: result.data?.createVLifeReport?.channel.id,
            skip: !result.data?.createVLifeReport?.channel.id,
          },
        },
      ];
    },
  });
}
