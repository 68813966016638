import React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { componentTypes, dataTypes, Field } from '@data-driven-forms/react-form-renderer';
import { extraComponents } from '../Form/utils/extraComponents';
import { formatDate } from '~/utils/date-utils';
import { WarningWithTooltip } from '../WarningWithTooltip/WarningWithTooltip';
import { formatBoolean } from '~/components/DataGrid/value-formatters';
import { TFunction } from 'i18next';
import { SubHeading } from '~/components/Form/formFields/SubHeading/SubHeading';
export enum RowType {
  NODE = 'NODE',
}

function formatField(field: Field, t: TFunction) {
  if (field.initialValue === null || typeof field.initialValue === 'undefined') {
    // en-dash
    return '–';
  }
  if (field.component === componentTypes.CHECKBOX) {
    return formatBoolean(t('viper::yes'), t('viper::no'), { value: field.initialValue });
  }
  if (field.component === componentTypes.DATE_PICKER) {
    return formatDate(field.initialValue);
  }
  if (field.component === componentTypes.SELECT) {
    if (field.dataType === dataTypes.BOOLEAN) {
      return formatBoolean(t('viper::yes'), t('viper::no'), { value: field.initialValue });
    }
    return field.initialValue.name;
  }
  if (field.label === RowType.NODE) {
    return null;
  }
  return `${field.initialValue}${field.suffix ? ' ' + field.suffix : ''}`;
}

interface DataListProps {
  fields?: Field[];
  nested?: boolean;
}

const filterOutNonVisibleFields = (field: Field) => {
  return field.hideField !== true;
};

export const DataList: React.FC<DataListProps> = ({ fields, nested = false }) => {
  const { t } = useTranslation('data-list');
  return (
    <TableContainer>
      <Table data-testid='simple-table'>
        <TableBody>
          {fields ? (
            fields.filter(filterOutNonVisibleFields).map((field) => {
              return field.component === extraComponents.SUBHEADING ? (
                <TableRow
                  key={field.name}
                  sx={{
                    '& > td, & > th': {
                      border: 0,
                      paddingX: 0,
                      py: 1,
                    },
                  }}
                >
                  <TableCell component='th' scope='row' colSpan={2}>
                    <SubHeading label={field.label} />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={field.name}
                  sx={{
                    '& > td, & > th': {
                      border: 0,
                      paddingX: 0,
                      py: 1,
                    },
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    width={nested ? '25%' : '33%'}
                    aria-label={field.label}
                  >
                    <Typography
                      sx={{
                        position: 'relative',
                        marginLeft: '6px',
                        fontSize: 14,
                        color: (theme) => (nested ? theme.palette.text.secondary : 'inherit'),
                      }}
                    >
                      {field.isRequired && (
                        <sup style={{ position: 'absolute', left: '-6px' }}>*</sup>
                      )}
                      {field.label === RowType.NODE ? field.initialValue : field.label}
                      {field.isRequiredForReporting && (
                        <WarningWithTooltip title={t('viper::mandatory-for-report')} />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align='left'>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: (theme) => theme.palette.text.secondary,
                      }}
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {formatField(field, t)}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow sx={{ '& > td, & > th': { border: 0, paddingX: 0 } }}>
              <TableCell component='th' scope='row'>
                <strong>{t('no-data')}</strong>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
