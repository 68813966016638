import { useDataRetrievalApolloClient } from '~/common/graphql-data-retrieval';
import { GetVlimDocument } from '~/graphql/generated/data-retrieval/graphql';
import { useQuery } from '@apollo/client';

export const useGetVlim = (serialNumber?: string) => {
  const client = useDataRetrievalApolloClient();

  return useQuery(GetVlimDocument, {
    client,
    fetchPolicy: 'no-cache',
    variables: {
      serialNumber: serialNumber ?? '',
    },
    skip: !serialNumber,
  });
};
