import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CreateWizard } from '~/components/Form/Wizard/CreateWizard';
import { useViperAssetCreateSchema } from '~/features/viper-asset/form-schemas/viperAssetCreateSchema';
import { WizardLoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import {
  useCreateViperAsset,
  useGetCreateViperAssetOptions,
} from '~/features/viper-asset/queries/viper-asset';
import { buildPath, Path } from '~/routes/paths';
import { SaveError, withErrorHandling } from '~/components/Form/SaveError/SaveError';

export const CreateViperAsset: React.FC = () => {
  const { t } = useTranslation('viper-asset-forms');
  const [createViperAsset, errorState, { loading: saving }] = withErrorHandling(
    useCreateViperAsset()
  );
  const navigate = useNavigate();

  const { data, loading } = useGetCreateViperAssetOptions();

  const schema = useViperAssetCreateSchema({
    parts: data?.parts,
    manufacturers: data?.manufacturers,
    commsTypes: data?.commsTypes,
    firmwareRevisions: data?.firmwareRevisions,
    ipAssignmentMethods: data?.ipAssignmentMethods,
    rootRevisionPasswords: data?.rootRevisionPasswords,
    customers: data?.customers,
  });

  if (loading) {
    return <WizardLoadingSkeleton />;
  }

  if (!data) {
    return <p>No data</p>;
  }

  return (
    <>
      <SaveError errorState={errorState}>{t('error-creating-viper-asset')}</SaveError>

      <CreateWizard
        schema={schema}
        loading={loading}
        saving={saving}
        onSubmit={async ({
          adminPassword,
          bomRevisionId,
          currentFirmwareRevisionId,
          customerId,
          dateOfManufacture,
          dateOfPurchase,
          endClientId,
          ipAddress,
          ipAssignmentMethodId,
          macAddress,
          manufacturerId,
          maxIrCap,
          partId,
          primaryCommsId,
          rootRevisionPasswordId,
          serialNumber,
        }: Record<string, unknown>) => {
          const result = await createViperAsset({
            variables: {
              viperAssetInput: {
                adminPassword: adminPassword as string,
                bomRevisionId: bomRevisionId as string,
                currentFirmwareRevisionId: currentFirmwareRevisionId as string,
                customerId: customerId as string,
                dateOfManufacture: dateOfManufacture as string,
                dateOfPurchase: dateOfPurchase as string,
                endClientId: endClientId as string,
                ipAddress: ipAddress as string,
                ipAssignmentMethodId: ipAssignmentMethodId as string,
                macAddress: macAddress as string,
                manufacturerId: manufacturerId as string,
                maxIrCap: maxIrCap as number,
                partId: partId as string,
                primaryCommsId: primaryCommsId as string,
                rootRevisionPasswordId: rootRevisionPasswordId as string,
                serialNumber: serialNumber as string,
              },
            },
          });
          if (!result.errors) {
            const id = result.data?.createViperAsset?.id;
            navigate(buildPath(Path.VIPER_ASSET, { id }));
          }
        }}
        onCancel={() => {
          navigate(Path.VIPER_ASSETS);
        }}
      />
    </>
  );
};
