export const createChannelNameAndFieldFormValidator = (
  channelNamesByField: Record<string, string[]>,
  message: string,
  allowed?: { name: string; fieldId: string }
) => {
  return (values: Record<string, unknown>) => {
    // This validate function is run for *every* change in the form, so it
    // needs to be fast
    if (
      typeof values.fieldId === 'string' &&
      typeof values.name === 'string' &&
      !(allowed && values.fieldId === allowed.fieldId && values.name === allowed.name) &&
      channelNamesByField[values.fieldId]?.includes(values.name.trim())
    ) {
      return {
        name: message,
      };
    }
  };
};
