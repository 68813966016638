import { useMemo } from 'react';

import { useGetViperAssets } from '~/features/viper-asset/queries/viper-asset';
import { useViperAssetColumns } from './useViperAssetColumns';
import { dataGridRowMapper } from '~/components/DataGrid/utils';

export function useViperAssets() {
  const { data: viperAssets, loading, error } = useGetViperAssets();
  const { columns } = useViperAssetColumns();

  const rows = useMemo(() => {
    return viperAssets?.viperAssets.map(dataGridRowMapper(columns));
  }, [columns, viperAssets?.viperAssets]);

  return {
    columns,
    rows,
    loading,
    error,
  };
}
