import {
  FormSpy,
  useFieldApi,
  UseFieldApiConfig,
} from '@data-driven-forms/react-form-renderer';
import { validationError } from '../formFields/validationError';
import MuiTextField from '@mui/material/TextField';

import InputAdornment from '@mui/material/InputAdornment';
import { Box } from '@mui/material';
import { FormLabel } from '../formFields/FormLabel/FormLabel';

export const Textarea = (props: UseFieldApiConfig & { isRequiredForReporting?: boolean }) => {
  const {
    input,
    isReadOnly,
    isDisabled,
    placeholder,
    isRequired,
    label,
    helperText,
    description,
    validateOnMount,
    meta,
    inputProps,
    size = 'small',
    suffix,
    prefix,
    rows = 4,
    isRequiredForReporting,
    sx,
    ...rest
  } = useFieldApi(props);

  const invalid = validationError(meta, validateOnMount);

  return (
    <Box sx={sx}>
      <FormLabel
        sx={{ color: (theme) => (invalid ? theme.palette.error.main : 'inherit') }}
        htmlFor={input.name}
        isRequiredForReporting={isRequiredForReporting}
      >
        {isRequired && <sup style={{ position: 'absolute', top: -3, left: -6 }}>*&nbsp;</sup>}
        {label}
      </FormLabel>
      <MuiTextField
        {...input}
        id={input.name}
        fullWidth
        size={size}
        error={!!invalid}
        helperText={
          invalid ||
          ((meta.touched || validateOnMount) && meta.warning) ||
          helperText ||
          description ||
          ' '
        }
        InputProps={{
          endAdornment: suffix && <InputAdornment position='end'>{suffix}</InputAdornment>,
          startAdornment: prefix && <InputAdornment position='start'>{prefix}</InputAdornment>,
        }}
        hiddenLabel
        InputLabelProps={{ shrink: false }}
        disabled={isDisabled}
        label={null}
        placeholder={placeholder}
        required={isRequired}
        inputProps={{
          readOnly: isReadOnly,
          ...inputProps,
        }}
        FormHelperTextProps={{
          sx: {
            fontSize: 14,
            mx: 0,
          },
        }}
        {...rest}
        multiline
        rows={rows}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
          }
        }}
      />
    </Box>
  );
};

export const EnhancedTextarea = (props: UseFieldApiConfig) => (
  <FormSpy subscription={{ values: true, validating: true }}>
    {() => <Textarea clearedValue={null} {...props} />}
  </FormSpy>
);
