import React from 'react';
import {
  useFieldApi,
  UseFieldApiConfig,
  useFormApi,
} from '@data-driven-forms/react-form-renderer';
import { Box, Stack, Typography } from '@mui/material';

export const CustomFormGroup: React.FC<UseFieldApiConfig> = (props) => {
  const { label, fields } = useFieldApi(props);
  const { renderForm } = useFormApi();

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        sx={{
          '& + &': { borderTop: (theme) => `1px solid ${theme.palette.divider}`, pt: 3 },
          pb: 2,
        }}
      >
        {label && (
          <Typography variant='h3' component='h3' sx={{ mb: 4 }}>
            {label}
          </Typography>
        )}
        <Stack
          display={'grid'}
          gridTemplateColumns={'1fr 1fr'}
          sx={{ '&': { gap: '1.5rem' } }}
        >
          {renderForm(fields)}
        </Stack>
      </Box>
    </>
  );
};
