import { getPlatformEditSchema } from '~/features/customer-asset/form-schemas/edit/platform/platformEditSchema';
import { TFunction } from 'i18next';

export const getPlatformCreateSchema = ({
  t,
  editing,
}: {
  t: TFunction;
  editing?: string;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-platform-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'createPlatform',
            title: t('platforms::create-platform'),
            fields: getPlatformEditSchema({
              t,
              editing,
            }).fields,
          },
        ],
      },
    ],
  };
};
