import { ButtonGroup, IconButton } from '@mui/material';
import { Tooltip } from '../Tooltip/Tooltip';

interface IconToggleButtonProps<T> {
  readonly buttons: { type: T; Component: React.ElementType; label?: string }[];
  readonly activeType?: T;
  readonly onChange: (type: T) => void;
}

export function IconToggleButtons<T>({
  onChange,
  activeType,
  buttons,
}: IconToggleButtonProps<T>) {
  return (
    <ButtonGroup>
      {buttons.map((button) => {
        const { type, Component, label } = button;
        return (
          <Tooltip key={`${type}`} title={label}>
            <IconButton
              onClick={() => onChange(type)}
              sx={{
                color: activeType === type ? 'grey.700' : 'divider',
              }}
              aria-label={label}
            >
              <Component />
            </IconButton>
          </Tooltip>
        );
      })}
    </ButtonGroup>
  );
}
