import { useMemo } from 'react';
import { LicenceInsightsRow } from '~/features/licence-insights/types';
import { flattenVLifeTimelineRows } from '~/features/licence-insights/utils/flattenVLifeTimelineRows';
import { useGetChannelsWithVLifeTimelines } from '~/features/customer-asset/queries/customer-asset';

interface UseGetLicenceInsightsRowsResponse {
  rows: LicenceInsightsRow[];
  loading: boolean;
}

export const useLicenceInsightsRows = (): UseGetLicenceInsightsRowsResponse => {
  // Fetch channels with timelines attached
  const { data, loading } = useGetChannelsWithVLifeTimelines();

  // Flatten out
  const rows: LicenceInsightsRow[] = useMemo(
    () => data?.channels.reduce(flattenVLifeTimelineRows, []) ?? [],
    [data]
  );

  return {
    rows,
    loading,
  };
};
