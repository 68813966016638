import { useEffect, useMemo, useState } from 'react';
import { componentTypes } from '@data-driven-forms/react-form-renderer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { extraComponents } from '~/components/Form/utils/extraComponents';
import { Comments } from '~/components/Form/formFields/Comments/Comments';
import { PdfViewer } from '~/components/PdfViewer/PdfViewer';
import { getPdfViewEndpoint } from '~/features/v-life-reports/form-schemas/publish/usePublishVLifeReportFormSchema';
import {
  useGetVLifeReportForm,
  useGetVLifeReportPreviewDownloadToken,
} from '~/features/v-life-reports/queries/v-life-reports';

// t('date')
// t('message')

export const ReportGrades = [
  { id: 'A', name: 'A' },
  { id: 'B', name: 'B' },
  { id: 'C', name: 'C' },
  { id: 'D', name: 'D' },
  { id: 'E', name: 'E' },
];

export function useApproveVLifeReportFormSchema() {
  const { t } = useTranslation('approve-v-life-report-form');
  const { reportFormId } = useParams();
  const { data: reportForm, loading: reportFormLoading } = useGetVLifeReportForm(reportFormId);

  const [url, setUrl] = useState<string>();
  const [getDownloadToken] = useGetVLifeReportPreviewDownloadToken();

  useEffect(() => {
    getDownloadToken({ variables: { id: reportFormId ?? '' } }).then((res) => {
      setUrl(
        getPdfViewEndpoint(reportFormId ?? '', res.data?.vlifeReportPreviewDownloadToken ?? '')
      );
    });
  }, [getDownloadToken, reportFormId]);

  // build schema
  const schema = useMemo(() => {
    return {
      name: 'approveVLifeReportForm',
      title: t('approve-report-form'),
      fields: [
        {
          name: 'pdfPreview',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'pdfPreview',
              component: extraComponents.CUSTOM,
              child: <PdfViewer url={url} />,
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
        {
          name: 'vlifeGrade',
          label: t('v-life-grade'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'vlifeGrade',
              label: t('v-life-grade'),
              component: componentTypes.SELECT,
              options: ReportGrades,
              isRequired: true,
              validate: [
                {
                  message: t('viper::required'),
                  type: 'required',
                },
              ],
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
        {
          name: 'internalDiscussion',
          label: t('internal-discussion'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'comments',
              component: extraComponents.CUSTOM,
              child: <Comments />,
              initialValue: reportForm?.vlifeReportForm?.comments,
              sx: { gridColumnEnd: 'span 2' },
            },
            {
              name: 'comment',
              label: t('comment'),
              component: componentTypes.TEXTAREA,
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
      ],
    };
  }, [reportForm?.vlifeReportForm?.comments, t, url]);
  return {
    schema,
    reportForm,
    loading: reportFormLoading,
  };
}
