import { useMutation } from '@apollo/client';
import {
  GetVlimDocument,
  UpdateVlimDocument,
} from '~/graphql/generated/data-retrieval/graphql';
import { useDataRetrievalApolloClient } from '~/common/graphql-data-retrieval';

export function useUpdateVlim() {
  const client = useDataRetrievalApolloClient();

  return useMutation(UpdateVlimDocument, { client, refetchQueries: [GetVlimDocument] });
}
