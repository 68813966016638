import React from 'react';
import { useTranslation } from 'react-i18next';
import { EventType } from '~/graphql/generated/measurement/graphql';
import { Pill } from '~/components/Pill/Pill';

export const colors: Record<
  string,
  | 'error'
  | 'success'
  | 'info'
  | 'warning'
  | 'primary'
  | 'secondary'
  | 'disabled'
  | 'action'
  | undefined
> = {
  [EventType.Pending]: 'secondary',
  [EventType.Processing]: 'warning',
  [EventType.Completed]: 'success',
  [EventType.Error]: 'error',
  [EventType.Uploading]: 'action',
};

export const IndicatorPill: React.FC<{ eventType: string | undefined }> = ({
  eventType: eventTypeId = EventType.Pending,
}) => {
  const { t } = useTranslation('v-log');

  // t('v-log::event-type/PENDING')
  // t('v-log::event-type/PROCESSING')
  // t('v-log::event-type/COMPLETED')
  // t('v-log::event-type/ERROR')
  // t('v-log::event-type/UPLOADING')
  return <Pill label={t(`event-type/${eventTypeId}`)} iconColor={colors[eventTypeId]} />;
};
