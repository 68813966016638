import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Actions } from '~/components/Actions/Actions';
import { FormDialog } from '~/components/Form/FormDialog/FormDialog';
import { SaveError } from '~/components/Form/SaveError/SaveError';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { Table } from '~/components/Table/Table';
import { Toast } from '~/components/Toast/Toast';
import { Path } from '~/routes/paths';
import { useCompanies } from '../../hooks/useCompanies';

// t('contacts::name')
// t('contacts::position')
// t('contacts::company')
// t('contacts::primaryTelephone')
// t('contacts::secondaryTelephone')
// t('contacts::email')
// t('contacts::actions')

export const ListContactsPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('contacts');
  const {
    data,
    loading,
    updateContactErrorState,
    optionsError,
    contactError,
    actionMenu,
    handleActionClick,
    formFields,
    onSave,
    contactLoading,
    saving,
    error,
    closeError,
    setCloseError,
    editing,
    onClose,
  } = useCompanies();

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Actions
        actions={[
          {
            label: t('create-contact'),
            onClick: () => {
              navigate(Path.CREATE_CONTACT);
            },
          },
        ]}
      />
      <Toast
        severity='error'
        open={Boolean(error) && !closeError && Boolean(optionsError) && Boolean(contactError)}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>

      <SaveError errorState={updateContactErrorState}>{t(`error-updating`)}</SaveError>

      <Table
        data={data}
        searchable
        omittedKeys={['_id']}
        translationKey={'contacts'}
        actionMenu={actionMenu}
        onActionClick={handleActionClick}
        defaultOrderBy='name'
      />

      <FormDialog
        fields={formFields}
        onCancel={onClose}
        onSave={onSave}
        title={t('edit-contact')}
        open={!!editing && !contactLoading}
        disabled={saving || contactLoading}
      />
    </>
  );
};
