import React from 'react';
import {
  ApolloCache,
  DefaultContext,
  MutationTuple,
  OperationVariables,
} from '@apollo/client';
import { Toast } from '../../Toast/Toast';

type ErrorState = {
  error: boolean;
  reset: () => void;
};

/**
 * Takes the result of a useMutation hook, and returns it with an additional
 * ErrorState object which can be passed to the SaveError component.
 *
 * The returned tuple is as follows:
 * ```text
 *   [
 *      the mutation function,
 *      the ErrorState object,
 *      the second value returned by useMutation
 *   ]
 * ```
 */
export function withErrorHandling<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutationTuple: MutationTuple<TData, TVariables, TContext, TCache>
): [
  MutationTuple<TData, TVariables, TContext, TCache>[0],
  ErrorState,
  MutationTuple<TData, TVariables, TContext, TCache>[1]
] {
  return [
    mutationTuple[0],
    {
      error: Boolean(mutationTuple[1].error),
      reset: mutationTuple[1].reset,
    },
    mutationTuple[1],
  ];
}

export const SaveError: React.FC<{
  children: React.ReactNode;
  errorState: ErrorState;
}> = ({ children, errorState }) => {
  return (
    <Toast open={errorState.error} onClose={errorState.reset} severity='error'>
      {children}
    </Toast>
  );
};

/**
 * Joins multiple error states into one, such that the resulting error value
 * is all the error states ORed together, and calling the resulting reset()
 * function will call reset on all the passed in states.
 */
export function joinErrorStates(...states: ErrorState[]): ErrorState {
  return {
    error: states.reduce((prev, curr) => prev || curr.error, false),
    reset: () => states.forEach((state) => state.reset()),
  };
}
