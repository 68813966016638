import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withErrorHandling } from '~/components/Form/SaveError/SaveError';
import {
  ApolloCache,
  DefaultContext,
  MutationTuple,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import { Exact } from '~/graphql/generated/asset/graphql';
import { TFunction } from 'i18next';
import { Field } from '@data-driven-forms/react-form-renderer';
import { useGetNever } from '~/utils/query-utils';

export type MutateHook<
  TData,
  TVariables,
  TContext = DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>
> = (options?: { id?: string }) => MutationTuple<TData, TVariables, TContext, TCache>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetOptionsHook<TOptions = any> = (options?: { skip?: boolean }) => QueryResult<
  TOptions,
  Exact<{
    [key: string]: never;
  }>
>;

export type GetEntityHook<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEntity = any,
  TVariables extends OperationVariables = OperationVariables
> = (id: string, options?: { skip?: boolean }) => QueryResult<TEntity, TVariables>;

export type MutateFunction<
  TData,
  TVariables,
  TContext extends DefaultContext = DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>
> = (
  mutate: MutationTuple<TData, TVariables, TContext, TCache>[0],
  values: Record<string, unknown>,
  fields: Field[]
) => Promise<string>;

export type UseCreateEditEntityProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TGetVariables extends OperationVariables = OperationVariables,
  TMutateVariables extends OperationVariables = OperationVariables,
  TContext extends DefaultContext = DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TOptions = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TEntity = any
> = {
  open: boolean;
  /**
   * This hook will be called when the dialog is opened. The result of
   * it will be passed to the `getSchema` function.
   */
  useGetOptions?: GetOptionsHook<TOptions>;
  /**
   * This hook will be called when the dialog is opened, and the
   * `entityId` param value will be passed to its `id` argument. The
   * result will be passed to the `getSchema` function.
   */
  useGetEntity?: GetEntityHook<TEntity, TGetVariables>;
  /**
   * This hooks will be called when the save button is clicked.
   */
  useMutate: MutateHook<TData, TMutateVariables, TContext, TCache>;
  /**
   * This function should get a schema to use to display the form.
   * It is passed a translation function, the results of calling `useGetOptions`
   * and the results of calling `useGetEntity`.
   */
  useMutateOptions?: { id?: string };
  /**
   *
   * @param useMutateOptions an object represents the options to pass to the query hook
   */
  getSchema: (options: {
    t: TFunction<'translation', undefined>;
    options: TOptions | undefined;
    entity: TEntity | undefined;
  }) => Field[];
  /**
   * This function handles the updating/saving of the data. It is passed
   * the mutation from `useMutate`. The value it returns will be returned
   * in the `created` field.
   */
  handleMutate: MutateFunction<TData, TMutateVariables, TContext, TCache>;
  /**
   * An entity id which will be passed to the `useGetEntity` hook, if it is
   * present.
   */
  entityId?: string;
};

export function useCreateEditEntityDialog<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TGetVariables extends OperationVariables = OperationVariables,
  TMutateVariables extends OperationVariables = OperationVariables,
  TContext extends DefaultContext = DefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TOptions = any
>({
  open,
  getSchema,
  handleMutate,
  useMutate,
  useMutateOptions,
  entityId,
  useGetOptions = useGetNever,
  useGetEntity = useGetNever<TData, TGetVariables>,
}: UseCreateEditEntityProps<
  TData,
  TGetVariables,
  TMutateVariables,
  TContext,
  TCache,
  TOptions
>) {
  const { data: options, loading: loadingOptions } = useGetOptions({
    skip: !open,
  });
  const { data: entity, loading: loadingEntity } = useGetEntity(entityId ?? '', {
    skip: !open || !entityId,
  });
  const { t } = useTranslation();
  const [mutated, setMutated] = useState<string | undefined>();
  const [mutate, mutateErrorState, { loading: mutating }] = withErrorHandling(
    useMutate(useMutateOptions)
  );

  const fields = getSchema({
    t,
    options,
    entity,
  });

  const saveEntity = useCallback(
    async (values: Record<string, unknown> | undefined) => {
      if (values) {
        const name = await handleMutate(mutate, values, fields);
        setMutated(name);
      }
    },
    [mutate, handleMutate, fields]
  );

  return {
    fields,
    loading: loadingOptions || loadingEntity,
    mutating,
    mutated,
    mutateErrorState,
    setMutated,
    saveEntity,
  };
}
