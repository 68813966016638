import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRouteId } from '~/common/useRouteId';
import { Tabs, Tab } from '@mui/material';
import { TabPanel } from '~/components/TabPanel/TabPanel';
import { CustomerAssetVLifeReports } from '~/features/customer-asset/pages/tabs/reports/CustomerAssetVLifeReports';
import { CustomerAssetGraph } from '~/features/customer-asset/pages/tabs/graphs/CustomerAssetGraph';
import { CustomerAssetOverview } from '~/features/customer-asset/pages/tabs/overview/CustomerAssetOverview';
import { CustomerAssetLogs } from '~/features/customer-asset/pages/tabs/logs/CustomerAssetLogs';
import { getTabs } from '~/features/customer-asset/pages/tabs/getTabs';

export const ViewCustomerAsset: React.FC = () => {
  const { t } = useTranslation('customer-asset-forms');
  const navigate = useNavigate();
  const id = useRouteId();

  const location = useLocation();
  const handleTabChange = useCallback(
    (_e: React.SyntheticEvent, newValue: string) => navigate(newValue),
    [navigate]
  );

  const tabs = getTabs(id);

  return (
    <>
      <Tabs
        value={location.pathname}
        onChange={handleTabChange}
        aria-label={`tab-${location.pathname}`}
        sx={{ mb: 4 }}
      >
        <Tab label={t('customer-asset::overview')} value={tabs['overview'].path} />
        <Tab label={t('customer-asset::logs')} value={tabs['logs'].path} />
        <Tab label={t('customer-asset::reports')} value={tabs['reports'].path} />
        <Tab label={t('customer-asset::graph')} value={tabs['graph'].path} />
      </Tabs>
      <TabPanel index={tabs['graph'].path} value={location.pathname}>
        <CustomerAssetGraph channelId={id} />
      </TabPanel>
      <TabPanel index={tabs['reports'].path} value={location.pathname}>
        <CustomerAssetVLifeReports channelId={id} />
      </TabPanel>
      <TabPanel index={tabs['logs'].path} value={location.pathname}>
        <CustomerAssetLogs channelId={id} />
      </TabPanel>
      <TabPanel index={tabs['overview'].path} value={location.pathname}>
        <CustomerAssetOverview channelId={id} />
      </TabPanel>
    </>
  );
};
