import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { withErrorHandling } from '~/components/Form/SaveError/SaveError';
import { getContactCreateSchema } from '~/features/contacts/form-schemas/create/contactCreateSchema';
import {
  useCreateContact,
  useGetCreateContactOptions,
} from '~/features/contacts/queries/contact';
import { Path } from '~/routes/paths';

export function useContact() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: createContactOptions, loading: optionsLoading } = useGetCreateContactOptions();
  const [createContact, errorState, { loading: saving }] = withErrorHandling(
    useCreateContact()
  );

  const createContactSchema = useMemo(
    () => getContactCreateSchema({ t, createContactOptions }),
    [createContactOptions, t]
  );

  const handleSubmit = useCallback(
    async ({
      forename,
      surname,
      position,
      companyId,
      primaryTelephone,
      secondaryTelephone,
      email,
    }: Record<string, unknown>) => {
      const result = await createContact({
        variables: {
          personInput: {
            forename: forename as string,
            surname: surname as string,
            position: position as string,
            companyId: companyId as string,
            primaryTelephone: primaryTelephone as string,
            secondaryTelephone: secondaryTelephone as string,
            email: email as string,
          },
        },
      });
      if (!result.errors) {
        navigate(Path.CONTACTS);
      }
    },
    [createContact, navigate]
  );

  const handleCancel = useCallback(() => {
    navigate(Path.CONTACTS);
  }, [navigate]);

  return {
    optionsLoading,
    saving,
    errorState,
    createContactSchema,
    handleSubmit,
    handleCancel,
  };
}
