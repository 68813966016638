import { getCompanyEditSchema } from '~/features/companies/form-schemas/edit/companyEditSchema';
import {
  GetCreateCompanyOptionsQuery,
  GetCustomerQuery,
} from '~/graphql/generated/asset/graphql';
import { TFunction } from 'i18next';

export const getCompanyCreateSchema = ({
  t,
  editing,
  company,
  createCompanyOptions,
}: {
  t: TFunction;
  editing?: string;
  company?: GetCustomerQuery['customer'] | undefined;
  createCompanyOptions?: GetCreateCompanyOptionsQuery | undefined;
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-company-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'createCompany',
            title: t('companies::create-company'),
            fields: getCompanyEditSchema({
              t,
              company,
              countries: createCompanyOptions?.countries,
              editing,
            }).fields,
          },
        ],
      },
    ],
  };
};
