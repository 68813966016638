import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Typography, Button, Stack } from '@mui/material';
import { Table } from '~/components/Table/Table';
import { useVLifeReports } from './useVLifeReports';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';
import { ACTION_DOWNLOAD } from '~/components/ActionMenu/ActionMenu';
import { UploadWithFormDialog } from '~/components/Upload/UploadWithFormDialog';
import { SaveError } from '~/components/Form/SaveError/SaveError';
import { Toast } from '~/components/Toast/Toast';
import { getVLifeReportsUploadSchema } from '~/features/customer-asset/form-schemas/create/vlifeReportUploadSchema';
import { useNavigate } from 'react-router-dom';
import { Path, buildPath } from '~/routes/paths';
import { useSetTitle } from '~/components/TitleProvider/TitleProvider';
import { getChannelDisplayName } from '~/utils/getChannelDisplayName';
import { useGetChannel } from '~/features/customer-asset/queries/customer-asset';

// t('customer-asset::documentNumber')
// t('customer-asset::revision')
// t('customer-asset::name')
// t('customer-asset::periodCovered')
// t('customer-asset::source')
// t('customer-asset::dateReleased')
// t('customer-asset::vlifeGrade')
// t('customer-asset::VI')
// t('customer-asset::UPLOADED')

const actionMenu = {
  label: 'actions',
  actionMenu: [ACTION_DOWNLOAD],
};

export const CustomerAssetVLifeReports: React.FC<{
  channelId: string;
}> = ({ channelId }) => {
  const { t, i18n } = useTranslation('customer-asset');
  const {
    vLifeReports,
    vLifeReportDetails,
    loading,
    handleActionClick,
    showUploadDialog,
    setShowUploadDialog,
    handleUpload,
    uploaded,
    setUploaded,
    uploadReportError,
  } = useVLifeReports(channelId);

  const { data: channel } = useGetChannel(channelId);

  useSetTitle(getChannelDisplayName(channel?.channel) ?? '');

  const navigate = useNavigate();

  const fields = useMemo(
    () => getVLifeReportsUploadSchema(t, i18n?.language ?? 'en_GB'),
    [i18n, t]
  );

  if (loading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' sx={{ mb: 2 }}>
        <Typography variant='h3'>{t('v-life-reports')}</Typography>
        <Stack gap={2} direction='row'>
          <Button
            variant='contained'
            color='neutral'
            onClick={() => {
              setShowUploadDialog(true);
            }}
          >
            {t('upload-v-life-report')}
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => navigate(buildPath(Path.CREATE_V_LIFE_REPORT, { channelId }))}
          >
            {t('create-v-life-report')}
          </Button>
        </Stack>
      </Box>
      <Table
        data={vLifeReports}
        nestedData={vLifeReportDetails}
        actionMenu={actionMenu}
        onActionClick={handleActionClick}
        omittedKeys={['_id', '_fileName']}
        translationKey='customer-asset'
      />
      <UploadWithFormDialog
        open={showUploadDialog}
        onUpload={handleUpload}
        accept={{ 'application/pdf': ['.pdf'] }}
        extension='.pdf'
        validationError={{
          code: 'only-pdf',
          message: t('viper::invalid-file-type'),
        }}
        onCancel={function (): void {
          setShowUploadDialog(false);
        }}
        fields={fields}
        title={t('upload-v-life-report')}
        validate={function (values): undefined | Record<string, string> {
          if ((values.startDate as string) > (values.endDate as string)) {
            return {
              endDate: t('end-date-must-be-after-start-date'),
            };
          }
          if ((values.endDate as string) > (values.dateReleased as string)) {
            return {
              dateReleased: t('release-date-must-be-after-end-date'),
            };
          }
        }}
      />
      <SaveError errorState={uploadReportError}>{t('error-uploading-vlife-report')}</SaveError>
      <Toast
        severity='success'
        open={uploaded}
        onClose={() => {
          setUploaded(false);
        }}
        autoHideDuration={5000}
      >
        {t('vlife-report-uploaded-successfully')}
      </Toast>
    </>
  );
};
