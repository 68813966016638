import { useQuery } from '@apollo/client';
import { MeasurementVersionDocument } from '~/graphql/generated/measurement/graphql';
import { useMeasurementApolloClient } from '~/common/graphql-measurement';

export function useGetMeasurementVersion() {
  const client = useMeasurementApolloClient();

  return useQuery(MeasurementVersionDocument, {
    client,
    fetchPolicy: 'cache-first',
  });
}
