import React, { useState } from 'react';
import { useViperAsset } from '../../hooks/useViperAsset';
import { LoadingSkeleton } from '~/components/LoadingSkeleton/LoadingSkeleton';

import { TabPanel } from '~/components/TabPanel/TabPanel';
import { Toast } from '~/components/Toast/Toast';
import { Box, Tabs, Tab } from '@mui/material';
import { MaintenanceLog } from '../../components/MaintenanceLog';
import { FirmwareLog } from '../../components/FirmwareLog';
import { OverviewTab } from '../../components/OverviewTab';
import { AuditLog } from '../../../../components/AuditLog/AuditLog';
import { useTranslation } from 'react-i18next';
import { MessagesLog } from '../../components/MessagesLog';
import { FormOptionsProvider } from '~/features/viper-asset/contexts/FormOptionsContext';

// t('error-saving/INSTALLATION');
// t('error-saving/COMMISSIONING');
// t('error-saving/DECOMMISSIONING');
// t('error-saving/UNINSTALLATION');
// t('viper-asset::actions')

export const LOGS = {
  maintenanceLog: 'maintenanceLog',
};

export const ViewViperAsset = () => {
  const { t } = useTranslation('viper-asset');
  const { id, loading, viperAsset } = useViperAsset();

  const [closeError, setCloseError] = useState(false);
  const [tabId, setTabId] = React.useState(0);

  const handleTabChange = (_e: React.SyntheticEvent, newValue: number) => {
    setTabId(newValue);
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (!viperAsset) {
    return <p>No data</p>;
  }

  return (
    <FormOptionsProvider>
      <Toast
        severity='error'
        open={Boolean(loading) && !closeError}
        onClose={() => setCloseError(true)}
      >
        {t('unable-to-load')}
      </Toast>
      <Tabs
        value={tabId}
        onChange={handleTabChange}
        aria-label={`tab-${tabId}`}
        sx={{ mb: 4 }}
      >
        <Tab label={t('overview')} />
        <Tab label={t('logs')} />
      </Tabs>
      <TabPanel index={0} value={tabId}>
        <OverviewTab viperAsset={viperAsset} />
      </TabPanel>
      <TabPanel index={1} value={tabId}>
        <MessagesLog viperAsset={viperAsset} />
        <Box sx={{ mb: 4 }} />
        <MaintenanceLog viperAsset={viperAsset} />
        <Box sx={{ mb: 4 }} />
        <FirmwareLog viperAsset={viperAsset} />
        <Box sx={{ mb: 4 }} />
        <AuditLog entity='viperasset.ViperAsset' id={id} />
      </TabPanel>
    </FormOptionsProvider>
  );
};
