import { DateTime } from 'luxon';
import writeXlsxFile from 'write-excel-file';
import { GetDashboardDetailsByMonthQuery } from '~/graphql/generated/asset/graphql';

const boldFormat = {
  fontWeight: 'bold',
} as const;

const boxedFormat = {
  borderColor: '#000000',
  borderStyle: 'thin',
} as const;

export async function exportExcel(
  month: string,
  data: NonNullable<GetDashboardDetailsByMonthQuery['reports']>['vlifeLicenceHistoryDetails'],
  writeExcel: typeof writeXlsxFile = writeXlsxFile
) {
  const startDate = DateTime.fromISO(month, { zone: 'UTC' });
  const endDate = startDate.plus({ month: 1 }).minus({ day: 1 });

  const rows = [
    [
      { type: String, ...boldFormat, value: 'Reporting Period' },
      null,
      {
        type: Date,
        ...boldFormat,
        value: startDate.toJSDate(),
      },
      { type: String, ...boldFormat, value: 'to' },
      {
        type: Date,
        ...boldFormat,
        value: endDate.toJSDate(),
      },
    ],
    [],
    [
      { type: String, ...boldFormat, ...boxedFormat, value: 'Customer' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'Field' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'Channel' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'Beginning Date' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'End Date' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'Asset No' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'Viper Assets' },
      { type: String, ...boldFormat, ...boxedFormat, value: 'Licence Type' },
    ],
    ...(data.map((row) => [
      { type: String, ...boxedFormat, value: row.vLifeConfig.channel.customer.name },
      { type: String, ...boxedFormat, value: row.vLifeConfig.channel.field.name },
      { type: String, ...boxedFormat, value: row.vLifeConfig.channel.name },
      DateTime.fromISO(row.vLifeConfig.startDate, { zone: 'UTC' }) < startDate
        ? {
            type: String,
            ...boxedFormat,
            value: 'Ongoing',
          }
        : {
            type: Date,
            ...boxedFormat,
            value: DateTime.fromISO(row.vLifeConfig.startDate, { zone: 'UTC' }).toJSDate(),
          },
      DateTime.fromISO(row.vLifeConfig.expiryDateCommToCustomer, { zone: 'UTC' }) > endDate
        ? { type: String, ...boxedFormat, value: 'Ongoing' }
        : {
            type: Date,
            ...boxedFormat,
            value: DateTime.fromISO(row.vLifeConfig.expiryDateCommToCustomer, {
              zone: 'UTC',
            }).toJSDate(),
          },
      { type: Number, ...boxedFormat, value: Number(row.vLifeConfig.channel.id) },
      {
        type: String,
        ...boxedFormat,
        value: row.installedViperAssets.map((asset) => asset.serialNumber).join(' '),
      },
      {
        type: String,
        ...boxedFormat,
        value: row.vLifeConfig.vlifeLicence.vlifeLicenceType.name,
      },
    ]) ?? []),
  ];

  const columns = [
    {
      width: 14.8,
    },
    {
      width: 8.4,
    },
    {
      width: 9.8,
    },
    {
      width: 13.3,
    },
    {
      width: 9.8,
    },
    {
      width: 8.1,
    },
    {
      width: 13.3,
    },
    {
      width: 12.9,
    },
  ];

  const formattedDate = startDate.toFormat('MM_yyyy');

  await writeExcel(rows, {
    columns,
    fileName: `Confidential_Platform_Vi_Monthly_VLIFE_Licences_${formattedDate}.xlsx`,
    dateFormat: 'dd/mm/yyyy',
    fontSize: 11,
    fontFamily: 'Calibri',
  });
}
