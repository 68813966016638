import { useTranslation } from 'react-i18next';
import colors from '~/themes/colors';
import { VLifeStatus } from '~/graphql/generated/asset/graphql';

interface VLifeStatusMetadata {
  label: string;
  color: string;
}

export const useGetVLifeStatusMetadata = (status: VLifeStatus): VLifeStatusMetadata => {
  const { t } = useTranslation('customer-assets');

  switch (status) {
    case VLifeStatus.ActiveTrial:
      return {
        label: t('trial'),
        color: colors.yellow[500],
      };
    case VLifeStatus.ActiveFoc:
      return {
        label: t('free-of-charge'),
        color: colors.blue[500],
      };
    case VLifeStatus.ActiveNonRefundable:
      return {
        label: t('non-refundable'),
        color: colors.purple[500],
      };
    case VLifeStatus.ActivePaid:
      return {
        label: t('paid'),
        color: colors.green[500],
      };
    case VLifeStatus.Suspended:
      return {
        label: t('suspended'),
        color: colors.orange[500],
      };
    case VLifeStatus.Disabled:
      return {
        label: t('deactivated'),
        color: colors.grey[500],
      };
    case VLifeStatus.Expired:
      return {
        label: t('expired'),
        color: colors.red[500],
      };
    case VLifeStatus.NotSetUp:
      return {
        label: t('unlicenced'),
        color: colors.darkblue[500],
      };
    case VLifeStatus.NotStarted:
      return {
        label: t('not-started'),
        color: colors.lightblue[600],
      };
    default:
      return {
        label: t('unknown-status'),
        color: colors.grey[500],
      };
  }
};
