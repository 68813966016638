import React from 'react';
import { Typography, Stack, Button, Paper } from '@mui/material';
import { ColoredStatusIcon } from '~/components/DataGrid/components/ColoredStatusIcon';
import { useGetVLifeStatusMetadata } from '~/utils/useGetVLifeStatusMetadata';
import { VLifeStatus } from '~/graphql/generated/asset/graphql';

export interface StatusProps {
  vLifeStatus: VLifeStatus;
  title: string;
  description?: string;
  ctaColor?:
    | 'inherit'
    | 'secondary'
    | 'primary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'neutral';
  ctaCopy?: string;
  buttonDisabled?: boolean;
  onClickCta?: () => void;
}

export const Status: React.FC<StatusProps> = ({
  title,
  description,
  ctaCopy,
  ctaColor = 'primary',
  buttonDisabled,
  onClickCta,
  vLifeStatus,
}) => {
  const { color } = useGetVLifeStatusMetadata(vLifeStatus);

  return (
    <Paper sx={{ px: 3, py: 2 }}>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        spacing={3}
        justifyContent='space-between'
        data-testid='status'
      >
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <ColoredStatusIcon color={color} size='small' />
          <Typography sx={{ lineHeight: 1, mt: 0.5 }} variant='h6'>
            {title}
          </Typography>
          {description && (
            <Typography sx={{ lineHeight: 1, mt: 0.5, fontWeight: 400 }} variant='h6'>
              {description}
            </Typography>
          )}
        </Stack>
        {onClickCta && ctaCopy && (
          <Button
            variant='contained'
            color={ctaColor}
            onClick={onClickCta}
            size='medium'
            disabled={buttonDisabled}
            sx={{ justifySelf: 'flex-end', mt: 2 }}
          >
            {ctaCopy}
          </Button>
        )}
      </Stack>
    </Paper>
  );
};
