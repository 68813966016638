import React, { KeyboardEventHandler, useCallback, useContext } from 'react';

import selectNext from '@data-driven-forms/common/wizard/select-next';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { WizardStepper } from './WizardStepper';
import { Card } from '~/components/Card/Card';
import WizardTemplate from '@data-driven-forms/common/wizard';
import WizardContext from '@data-driven-forms/react-form-renderer/wizard-context';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy';
import { WizardProps } from '@data-driven-forms/mui-component-mapper/wizard';
import { Field } from '@data-driven-forms/react-form-renderer';
import { CheckboxWithLabel } from '~/components/Form/formFields/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import { FormSpyWithBlocker } from '../../FormSpyWithBlocker/FormSpyWithBlocker';
import { isEqual } from 'lodash';

const WizardInternalBase: React.FC<
  WizardProps & {
    showMandatory?: boolean;
    saving?: boolean;
    onShowMandatoryClicked?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  }
> = ({ showMandatory, saving, onShowMandatoryClicked }) => {
  const {
    formOptions: { getState, onCancel, handleSubmit, schema, renderForm, initialValues },
    currentStep,
    onKeyDown,
    handleNext,
    activeStepIndex,
    jumpToStep,
    maxStepIndex,
  } = useContext(WizardContext);

  const { t } = useTranslation('wizard');

  const state = getState();
  /**
   * state.dirty/pristine/touched etc only refers to the current wizard step. Also, it's
   * often wrong. So we'll compare the current values with the initial values to see
   * if anything has actually changed.
   */
  const different = !isEqual(state.values, initialValues);

  /**
   * Before you've ever edited anything, invalid is sometimes false when it should be true.
   * So we're going to assume you can't go onto the next step/submit the form if you haven't
   * entered anything anywhere. This will of course not work if the first step has no
   * mandatory fields. Therefore this horrible workaround will probably need to be fixed
   * properly at some point, once we can work out whether the issue is with our code or with
   * data-driven-forms/react-final-forn.
   */
  const disabled = !different || state.invalid;

  const renderButtonGroup = useCallback(
    (cancelButtonLabel: string, nextButtonLabel: string, submitButtonLabel: string) => (
      <FormSpyWithBlocker
        onCancel={({ values }) => onCancel?.(values)}
        disableBlocker={saving}
      >
        {({ handleCancel }) => {
          return (
            <>
              <Button onClick={handleCancel} variant='contained' color='neutral'>
                {cancelButtonLabel}
              </Button>
              {currentStep.nextStep && (
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={disabled}
                  onClick={() => {
                    if (currentStep.nextStep) {
                      handleNext(selectNext(currentStep.nextStep, getState));
                    }
                  }}
                >
                  {nextButtonLabel}
                </Button>
              )}
              {!currentStep.nextStep && (
                <Button
                  variant='contained'
                  color='secondary'
                  disabled={disabled}
                  onClick={handleSubmit}
                >
                  {submitButtonLabel}
                </Button>
              )}
            </>
          );
        }}
      </FormSpyWithBlocker>
    ),
    [currentStep.nextStep, disabled, getState, handleNext, handleSubmit, onCancel, saving]
  );

  const hideStepper = schema.fields[0].hideStepper;

  return (
    <Box
      onKeyDown={onKeyDown as KeyboardEventHandler<HTMLDivElement>}
      sx={{ width: '100%', p: 3 }}
    >
      <Grid container spacing={4}>
        {!hideStepper && (
          <Grid item xs={12} lg={4}>
            <Stack spacing={2}>
              <FormSpy>
                {() => (
                  <WizardStepper
                    {...{
                      schema,
                      activeStepIndex,
                      currentStepName: currentStep.name,
                      maxStepIndex,
                      jumpToStep: jumpToStep as (n: number) => void,
                      showMandatory,
                    }}
                  />
                )}
              </FormSpy>
              <CheckboxWithLabel
                id='show-mandatory'
                label={t('show-mandatory')}
                CheckboxProps={{
                  checked: showMandatory,
                  onClick: onShowMandatoryClicked,
                }}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={12} lg={hideStepper ? 12 : 8}>
          <Card
            footerContent={renderButtonGroup(
              t('cancel-button'),
              t('next-button'),
              t('submit-button')
            )}
          >
            <Stack gap={3}>
              <Typography variant='h3'>{currentStep.title}</Typography>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: '0.5rem 1rem',
                  placeContent: 'stretch',
                }}
              >
                {renderForm(currentStep.fields)}
              </div>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export const WizardInternal = (
  props: WizardProps & {
    fields: Field[];
    showMandatory?: boolean;
    saving?: boolean;
    onShowMandatoryClicked?: (e: React.MouseEvent<HTMLInputElement>) => void;
  }
) => <WizardTemplate Wizard={WizardInternalBase} {...props} />;
