import { GetCreateViperAssetOptionsQuery } from '~/graphql/generated/asset/graphql';
import { useCommercialCreateSchema } from '~/features/viper-asset/form-schemas/create/useCommercialCreateSchema';
import { useConfigurationCreateSchema } from '~/features/viper-asset/form-schemas/create/useConfigurationCreateSchema';
import { useIdentificationCreateSchema } from '~/features/viper-asset/form-schemas/create/useIdentificationCreateSchema';
import { useManufacturingCreateSchema } from '~/features/viper-asset/form-schemas/create/useManufacturingCreateSchema';
import { useTranslation } from 'react-i18next';

interface GetViperAssetCreateSchemaProps {
  parts?: GetCreateViperAssetOptionsQuery['parts'];
  manufacturers?: GetCreateViperAssetOptionsQuery['manufacturers'];
  commsTypes?: GetCreateViperAssetOptionsQuery['commsTypes'];
  firmwareRevisions?: GetCreateViperAssetOptionsQuery['firmwareRevisions'];
  ipAssignmentMethods?: GetCreateViperAssetOptionsQuery['ipAssignmentMethods'];
  rootRevisionPasswords?: GetCreateViperAssetOptionsQuery['rootRevisionPasswords'];
  customers?: GetCreateViperAssetOptionsQuery['customers'];
}

export const useViperAssetCreateSchema = ({
  parts,
  manufacturers,
  commsTypes,
  firmwareRevisions,
  ipAssignmentMethods,
  rootRevisionPasswords,
  customers,
}: GetViperAssetCreateSchemaProps) => {
  const { t } = useTranslation('viper-asset-forms');
  const identificationCreateSchema = useIdentificationCreateSchema({ parts });
  const manufacturingCreateSchema = useManufacturingCreateSchema({ manufacturers, parts });
  const configurationCreateSchema = useConfigurationCreateSchema({
    commsTypes,
    firmwareRevisions,
    ipAssignmentMethods,
    rootRevisionPasswords,
  });
  const commercialCreateSchema = useCommercialCreateSchema({ customers });

  return {
    fields: [
      {
        name: 'create-viper-asset-wizard',
        component: 'wizard',
        fields: [
          {
            name: 'identification',
            title: t('identification'),
            nextStep: 'manufacturing',
            fields: identificationCreateSchema.fields,
          },
          {
            name: 'manufacturing',
            title: t('manufacturing'),
            nextStep: 'configuration',
            fields: manufacturingCreateSchema.fields,
          },
          {
            name: 'configuration',
            title: t('configuration'),
            nextStep: 'commercial',
            fields: configurationCreateSchema.fields,
          },
          {
            name: 'commercial',
            title: t('commercial'),
            fields: commercialCreateSchema.fields,
          },
        ],
      },
    ],
  };
};
