import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { useTranslation } from 'react-i18next';
import { useViperAssetFormOptions } from '~/features/viper-asset/contexts/FormOptionsContext';

interface GetIdentificationEditSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useIdentificationEditSchema = ({
  viperAsset,
}: GetIdentificationEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  const { parts } = useViperAssetFormOptions();

  return {
    fields: [
      {
        name: 'serialNumber',
        label: t('serialNumber'),
        component: componentTypes.TEXT_FIELD,
        sx: { gridColumnEnd: 'span 2' },
        isRequired: true,
        initialValue: viperAsset?.serialNumber,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'partId',
        label: t('part'),
        component: componentTypes.SELECT,
        placeholder: 'VA-00000',
        isRequired: true,
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: viperAsset?.part,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        options: parts,
        annotation: {
          text: t('bom-will-be-reset'),
        },
      },
      {
        isClearable: true,
        component: componentTypes.SELECT,
        name: 'bomRevisionId',
        label: t('bomRevision'),
        sortAlphabetical: true,
        isSearchable: true,
        initialValue: viperAsset?.bomRevision,
        resolveProps: (_props, _field, formOptions) => {
          const selectedFieldId = formOptions.getState().values.partId;
          const selectedField = parts?.find((part) => part.id === selectedFieldId);
          return {
            options: selectedField?.bomRevisions,
          };
        },
      },
    ],
  };
};
