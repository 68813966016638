import { useDataRetrievalApolloClient } from '~/common/graphql-data-retrieval';
import { GetVlimsDocument } from '~/graphql/generated/data-retrieval/graphql';
import { useQuery } from '@apollo/client';

export const useGetVlims = () => {
  const client = useDataRetrievalApolloClient();

  return useQuery(GetVlimsDocument, {
    client,
    fetchPolicy: 'no-cache',
  });
};
