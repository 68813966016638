import React, {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

type TitleContextType = {
  title: string | undefined;
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const TitleContext = createContext<TitleContextType>({
  title: undefined,
  setTitle: () => {
    console.warn('Using context without provider');
  },
});

export const TitleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [title, setTitle] = useState<string | undefined>(undefined);

  const contextValue = useMemo(
    () => ({
      title,
      setTitle,
    }),
    [title, setTitle]
  );

  return <TitleContext.Provider value={contextValue}>{children}</TitleContext.Provider>;
};

/**
 * Set the current page title.
 *
 * When the page is unloaded then the title is cleared.
 */
export const useSetTitle = (title: string | undefined) => {
  const { setTitle } = useContext(TitleContext);
  useLayoutEffect(() => {
    setTitle(title);
    return () => {
      setTitle(undefined);
    };
  }, [setTitle, title]);
};

/**
 * Get the current page title
 */
export const useTitle = () => {
  const { title } = useContext(TitleContext);
  return title;
};
