import { useCallback, useState } from 'react';
import { useRouteId } from '~/common/useRouteId';
import { useGetCreateLifecycleEventOptions } from '~/features/viper-asset/queries/viper-asset';

import { GetViperAssetQuery, LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { useViperAssetEditSchema } from '~/features/viper-asset/form-schemas/useViperAssetEditSchema';
import { useValidateLifecycleEvent } from './useValidateLifecycleEvent';

const nextSteps = {
  [LifecycleEventType.Installation]: LifecycleEventType.Commissioning,
  [LifecycleEventType.Commissioning]: LifecycleEventType.Decommissioning,
  [LifecycleEventType.Decommissioning]: LifecycleEventType.Uninstallation,
  [LifecycleEventType.Uninstallation]: LifecycleEventType.Installation,
};

export const useOverviewTab = (viperAsset: GetViperAssetQuery['viperAsset']) => {
  const id = useRouteId();

  const [creating, setCreating] = useState<LifecycleEventType | undefined>();
  const [editing, setEditing] = useState<string | undefined>();
  const [showLifecycleDialog, setShowLifecycleDialog] = useState(false);
  const [showCreateFirmwareLogDialog, setShowCreateFirmwareLogDialog] = useState(false);

  const { data: lifecycleOptions, loading: lifecycleOptionsLoading } =
    useGetCreateLifecycleEventOptions();

  const getNewLifecycleEvent = useCallback(() => {
    const nextStep =
      nextSteps[
        viperAsset?.latestLifecycleEvent?.eventType ?? LifecycleEventType.Uninstallation
      ];
    setCreating(nextStep);
  }, [setCreating, viperAsset?.latestLifecycleEvent?.eventType]);

  const resetAllForms = useCallback(() => {
    setEditing(undefined);
    setCreating(undefined);
  }, []);

  const validate = useValidateLifecycleEvent(
    creating,
    viperAsset,
    lifecycleOptions?.channels,
    editing
  );

  const handleCreateFirmwareRevisionClick = () => {
    setShowCreateFirmwareLogDialog(true);
  };

  const { schema, hasHistory } = useViperAssetEditSchema({
    viperAsset,
    editing,
    creating,
    getNewLifecycleEvent,
    handleCreateFirmwareRevisionClick,
  });

  return {
    schema,
    optionsLoading: lifecycleOptionsLoading,
    creating,
    editing,
    setEditing,
    showLifecycleDialog,
    hasHistory,
    resetAllForms,
    setShowLifecycleDialog,
    showCreateFirmwareLogDialog,
    setShowCreateFirmwareLogDialog,
    validate,
    id,
  };
};
