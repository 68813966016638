import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNegatableFilterOperators } from '~/components/DataGrid/filters/filter-operators';
import { IndicatorPill } from '../components/IndicatorPill';
import { getDate } from '~/components/DataGrid/value-getters';
import { VLogRowExpander } from '../components/VLogRowExpander';
import { formatDateTime } from '~/utils/date-utils';

const baseColumn: Partial<GridColDef> = {
  minWidth: 200,
  maxWidth: 500,
  flex: 1,
};

export function useVLogColumns() {
  const { t } = useTranslation('v-log');

  const {
    negatableNumberFilterOperators,
    negatableStringFilterOperators,
    negatableDateFilterOperators,
  } = useNegatableFilterOperators();

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: (params) => <VLogRowExpander {...params} />,
        filterable: false,
        sortable: false,
      },
      {
        ...baseColumn,
        field: 'id',
        headerName: t('id'),
        minWidth: 50,
        maxWidth: 100,
        flex: 0.5,
        filterOperators: negatableNumberFilterOperators,
      },
      {
        ...baseColumn,
        field: 'name',
        headerName: t('name'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
      {
        ...baseColumn,
        field: 'size',
        headerName: t('size'),
        filterOperators: negatableStringFilterOperators,
        minWidth: 50,
        flex: 1,
      },
      {
        ...baseColumn,
        field: 'dateUploaded',
        headerName: t('dateUploaded'),
        filterOperators: negatableDateFilterOperators,
        minWidth: 100,
        flex: 1,
        type: 'date',
        valueFormatter: ({ value }) => formatDateTime(value ? value.toISOString() : ''),
        valueGetter: getDate,
      },
      {
        ...baseColumn,
        field: 'status',
        headerName: t('status'),
        renderCell: (params) => <IndicatorPill eventType={params.value} />,
        filterOperators: negatableStringFilterOperators,
        minWidth: 200,
        flex: 1,
      },
    ],
    [
      t,
      negatableNumberFilterOperators,
      negatableStringFilterOperators,
      negatableDateFilterOperators,
    ]
  );

  // const nestedColumns: Array<Partial<GridColDef> & { valueMapper?: ValueMapper }> =
  //   useMemo(() => {
  //     const columns = [
  //       {
  //         field: 'dateUploaded',
  //         headerName: t('Uploaded By'),
  //         valueMapper: (value: Value) => value as any,
  //       },
  //     ];
  //     return columns;
  //   }, [t]);
  return { columns };
}
