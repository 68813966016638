import { TFunction } from 'i18next';
import { getFirmwareLogEditSchema } from '../edit/firmwareLogEditSchema';
import {
  GetFirmwareLogOptionsQuery,
  GetViperAssetQuery,
} from '~/graphql/generated/asset/graphql';

export const getFirmwareLogCreateSchema = ({
  t,
  options,
  firmwareRevision,
  previousFirmwareRevision,
}: {
  t: TFunction;
  options: GetFirmwareLogOptionsQuery | undefined;
  firmwareRevision?: NonNullable<GetViperAssetQuery['viperAsset']>['firmwareUpdates'][0];
  previousFirmwareRevision?: NonNullable<
    GetViperAssetQuery['viperAsset']
  >['firmwareUpdates'][0];
}) => {
  return {
    fields: [
      {
        component: 'wizard',
        name: 'create-firmware-log-wizard',
        hideStepper: true,
        fields: [
          {
            name: 'firmwareLog',
            title: t('viper-asset::firmware-log'),
            fields: getFirmwareLogEditSchema({
              t,
              options,
              firmwareRevision,
              previousFirmwareRevision,
            }).fields,
          },
        ],
      },
    ],
  };
};
