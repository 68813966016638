import { getToken, protectedResources } from './msal';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withScalars } from 'apollo-link-scalars';
import { ApolloLink } from '@apollo/client/core';
import introspectionResult from '~/graphql/generated/asset/graphql.schema.json';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { typesMap } from './graphqlTypesMap';
import { createContext, useContext } from 'react';
import { env } from '~/config/environmentVariables';

const httpLink = createHttpLink({
  uri: `${env.REACT_APP_MEASUREMENT_API_ENDPOINT}/graphql`,
});

const schema = buildClientSchema(introspectionResult as unknown as IntrospectionQuery);

const scalarsLink = withScalars({
  schema,
  typesMap,
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getToken([...protectedResources.apis.scopes.measurement]);
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const link = ApolloLink.from([authLink, scalarsLink, httpLink]);
const cache = new InMemoryCache();

export const measurementApolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const ApolloContext = createContext({
  client: measurementApolloClient,
});

export const MeasurementApolloProvider = ApolloContext.Provider;

export function useMeasurementApolloClient() {
  const client = useContext(ApolloContext).client;
  if (env.NODE_ENV === 'test') {
    return undefined;
  }
  return client;
}

export function getVlogFileDownloadEndpoint(id: string, token: string) {
  return `${env.REACT_APP_MEASUREMENT_API_ENDPOINT}/vlogfile/${id}?token=${encodeURIComponent(
    token
  )}`;
}
