import React from 'react';

import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useRouteError } from 'react-router-dom';
import { ErrorOutline } from '@mui/icons-material';
import { Logo } from '~/components/Logo/Logo';

type RouteError = { status: number; statusText: string };

export const ErrorPage: React.FC<{ error?: RouteError }> = ({ error: incomingError }) => {
  const { t } = useTranslation();
  const routeError = useRouteError() as RouteError;

  const error = incomingError ?? routeError;
  const isUnauthorised = error?.status === 401;

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: (theme) => theme.palette.grey[100],
        color: (theme) => theme.palette.grey[500],
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
      }}
    >
      <Typography
        component='h1'
        variant='h1'
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        {isUnauthorised ? <Logo /> : <ErrorOutline fontSize='inherit' sx={{ mx: 'auto' }} />}
      </Typography>
      <Typography variant='h2' component='h2' sx={{ mb: 2, color: 'inherit' }}>
        <b>{isUnauthorised ? t('not-authorised') : t('unexpected-error')}</b>
      </Typography>
      {!!error?.status && error?.statusText && (
        <Typography variant='h4' component='h3' sx={{ mb: 2, color: 'inherit' }}>
          {isUnauthorised && <b>{t('not-authorised-message')}</b>}
          {!isUnauthorised && (
            <>
              <b>{error.status}</b> - {error.statusText}
            </>
          )}
        </Typography>
      )}
    </Box>
  );
};
