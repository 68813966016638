import { useMemo } from 'react';
import {
  FormOptions,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { extraComponents } from '~/components/Form/utils/extraComponents';
import { useGetFirmwareRevisions } from '~/features/viper-asset/queries/viper-asset';
import { useGetContacts } from '~/features/contacts/queries/contact';
import { MessageList } from '~/features/v-life-reports/components/MessageList';
import { formatPerson } from '~/utils/format-person';
import { Comments } from '~/components/Form/formFields/Comments/Comments';
import { VLifeReportLogMessage } from '~/graphql/generated/asset/graphql';
import { VIPER_COMPANY_ID } from '~/common/constants';
import { useGetVLifeEventsForChannelPeriod } from '~/features/v-life-reports/hooks/useGetVLifeEventsForChannelPeriod';
import { useGetVLifeReportForm } from '~/features/v-life-reports/queries/v-life-reports';

// t('date')
// t('message')

export function useReviewVLifeReportFormSchema() {
  const { t } = useTranslation('review-v-life-report-form');
  const { reportFormId } = useParams();
  const { data: reportForm, loading: reportFormLoading } = useGetVLifeReportForm(reportFormId);
  const { data: firmwareRevisions, loading: firmwareRevisionsLoading } =
    useGetFirmwareRevisions();
  const { data: allLogs, loading: loadingEvents } = useGetVLifeEventsForChannelPeriod({
    channelId: reportForm?.vlifeReportForm?.channel.id,
    startDate: reportForm?.vlifeReportForm?.reportPeriodStartDate,
    endDate: reportForm?.vlifeReportForm?.reportPeriodEndDate,
  });

  // get people
  const { data: contacts, loading: contactsLoading } = useGetContacts();
  const people = useMemo(() => {
    if (!contacts) return [];
    return contacts.people.filter((contact) => contact.company.id === VIPER_COMPANY_ID);
  }, [contacts]);

  // memoise all logs, to pass into the message list
  const logs: VLifeReportLogMessage[] = useMemo(
    () => reportForm?.vlifeReportForm?.logs ?? [],
    [reportForm?.vlifeReportForm?.logs]
  );

  // build schema
  const schema = useMemo(() => {
    const onNoChange = {
      when: 'noChangeRequired',
      is: true,
      then: {
        visible: false,
      },
      else: {
        visible: true,
      },
    };
    return {
      name: 'reviewVLifeReportForm',
      title: t('review-report'),
      fields: [
        {
          name: 'irIncrease',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'overallIRIncrease',
              label: t('overall-ir-increase'),
              component: componentTypes.TEXT_FIELD,
              sx: { gridColumnEnd: 'span 2' },
              initialValue: reportForm?.vlifeReportForm?.overallIRIncrease,
              isRequired: true,
              suffix: 'Ω',
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
              type: 'number',
            },
          ],
        },
        {
          name: 'messages',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'logs',
              component: extraComponents.CUSTOM,
              child: (
                <MessageList
                  name={'logs'}
                  label={t('messages')}
                  logs={logs}
                  allLogs={allLogs}
                />
              ),
              sx: { gridColumnEnd: 'span 2' },
              initialValue: reportForm?.vlifeReportForm?.logs.map(
                (element: VLifeReportLogMessage): Partial<VLifeReportLogMessage> => {
                  return {
                    date: element.date,
                    message: element.message,
                  };
                }
              ),
            },
          ],
        },
        {
          name: 'people',
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'approver',
              label: t('approver'),
              isSearchable: true,
              sortAlphabetical: true,
              component: componentTypes.SELECT, // Person
              initialValue: reportForm?.vlifeReportForm?.approver,
              options: people?.map((person) => ({
                id: person.id,
                name: formatPerson(person),
              })),
              isRequired: true,
              validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
            },
            {
              name: 'suggestedReviewer',
              label: t('suggested-reviewer'),
              isSearchable: true,
              sortAlphabetical: true,
              component: componentTypes.SELECT,
              isClearable: true,
              initialValue: reportForm?.vlifeReportForm?.suggestedReviewer,
              options: people?.map((person) => ({
                id: person.id,
                name: formatPerson(person),
              })),
            },
          ],
        },
        {
          name: 'recommendations',
          label: t('recommendations'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'noChangeRequired',
              label: t('no-change-required'),
              component: componentTypes.CHECKBOX,
              initialValue: reportForm?.vlifeReportForm?.noChangeRequired,
              type: 'boolean',
              sx: { gridColumnEnd: 'span 2' },
              resolveProps: (_props: unknown, _field: unknown, formOptions: FormOptions) => {
                if (formOptions.getState().values.noChangeRequired === true) {
                  [
                    'optimiseVLifeProcess',
                    'increaseSamplingFrequency',
                    'decreaseSamplingFrequency',
                    'changeAlarmSettings',
                    'changeCommunicationsSettings',
                    'furtherInvestigationRequired',
                    'increaseFrequencyOfDataDownload',
                  ].forEach((field) => {
                    formOptions.change(field, false);
                  });
                }
              },
            },
            {
              name: 'updateConfiguration',
              label: t('update-configuration'),
              component: extraComponents.LABEL,
              condition: onNoChange,
            },
            {
              name: 'optimiseVLifeProcess',
              label: t('optimise-v-life-process'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              initialValue: reportForm?.vlifeReportForm?.optimiseTheVLifeProcess,
            },
            {
              name: 'increaseSamplingFrequency',
              label: t('increase-sampling-frequency'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              initialValue: reportForm?.vlifeReportForm?.increaseSamplingFrequency,
            },
            {
              name: 'decreaseSamplingFrequency',
              label: t('decrease-sampling-frequency'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              initialValue: reportForm?.vlifeReportForm?.decreaseSamplingFrequency,
            },
            {
              name: 'changeAlarmSettings',
              label: t('change-alarm-settings'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              initialValue: reportForm?.vlifeReportForm?.changeAlarmSettings,
            },
            {
              name: 'changeCommunicationsSettings',
              label: t('change-communications-settings'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              initialValue: reportForm?.vlifeReportForm?.changeCommunicationSettings,
            },
            {
              name: 'furtherInvestigationRequired',
              label: t('further-investigation-required'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              initialValue: reportForm?.vlifeReportForm?.furtherInvestigationRequired,
            },
            {
              name: 'increaseFrequencyOfDataDownload',
              label: t('increase-frequency-of-data-download'),
              component: componentTypes.CHECKBOX,
              type: 'boolean',
              condition: onNoChange,
              sx: { gridColumnEnd: 'span 2' },
              initialValue: reportForm?.vlifeReportForm?.increaseInFrequencyOfDataDownloads,
            },
            {
              name: 'recommendedFirmwareRevision',
              label: t('update-firmware-to'),
              component: componentTypes.SELECT,
              options: firmwareRevisions?.firmwareRevisions,
              initialValue: reportForm?.vlifeReportForm?.updateFirmwareTo,
              isClearable: true,
            },
            {
              name: 'currentFirmwareRevision',
              label: t('current-firmware-revision'),
              component: componentTypes.PLAIN_TEXT,
              initialValue: reportForm?.vlifeReportForm?.currentFirmwareRevision,
            },
            {
              name: 'externalSummaryForClient',
              label: t('external-summary-for-client'),
              component: componentTypes.TEXTAREA,
              sx: { gridColumnEnd: 'span 2' },
              initialValue: reportForm?.vlifeReportForm?.externalSummaryForClient,
            },
          ],
        },
        {
          name: 'internalDiscussion',
          label: t('internal-discussion'),
          component: extraComponents.SUB_FORM,
          fields: [
            {
              name: 'comments',
              component: extraComponents.CUSTOM,
              child: <Comments />,
              initialValue: reportForm?.vlifeReportForm?.comments,
              sx: { gridColumnEnd: 'span 2' },
            },
            {
              name: 'comment',
              label: t('comment'),
              component: componentTypes.TEXTAREA,
              sx: { gridColumnEnd: 'span 2' },
            },
          ],
        },
      ],
    };
  }, [
    t,
    reportForm?.vlifeReportForm?.overallIRIncrease,
    reportForm?.vlifeReportForm?.logs,
    reportForm?.vlifeReportForm?.approver,
    reportForm?.vlifeReportForm?.suggestedReviewer,
    reportForm?.vlifeReportForm?.noChangeRequired,
    reportForm?.vlifeReportForm?.optimiseTheVLifeProcess,
    reportForm?.vlifeReportForm?.increaseSamplingFrequency,
    reportForm?.vlifeReportForm?.decreaseSamplingFrequency,
    reportForm?.vlifeReportForm?.changeAlarmSettings,
    reportForm?.vlifeReportForm?.changeCommunicationSettings,
    reportForm?.vlifeReportForm?.furtherInvestigationRequired,
    reportForm?.vlifeReportForm?.increaseInFrequencyOfDataDownloads,
    reportForm?.vlifeReportForm?.updateFirmwareTo,
    reportForm?.vlifeReportForm?.currentFirmwareRevision,
    reportForm?.vlifeReportForm?.externalSummaryForClient,
    reportForm?.vlifeReportForm?.comments,
    logs,
    allLogs,
    people,
    firmwareRevisions?.firmwareRevisions,
  ]);

  return {
    schema,
    reportForm,
    loading: reportFormLoading || firmwareRevisionsLoading || contactsLoading || loadingEvents,
  };
}
