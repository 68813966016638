import React from 'react';
import { useFieldApi, UseFieldApiConfig } from '@data-driven-forms/react-form-renderer';
import { Box, Typography } from '@mui/material';

export const CustomFormField: React.FC<UseFieldApiConfig> = (props) => {
  const { child, label, sx } = useFieldApi(props);

  return (
    <Box display='flex' flexDirection='column' sx={{ ...sx }}>
      {label && (
        <Typography variant='h3' component='h3' sx={{ mb: 2 }}>
          {label}
        </Typography>
      )}
      <Box>{child}</Box>
    </Box>
  );
};
