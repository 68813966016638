import { GetViperAssetQuery, LifecycleEventType } from '~/graphql/generated/asset/graphql';
import { buildPath, Path } from '~/routes/paths';
import { Highlight } from '~/features/viper-asset/components/Highlights';
import { useTranslation } from 'react-i18next';

export const useLifecycleViewSchema = ({
  channel,
}: {
  // @ts-expect-error type is too deep for TS
  channel?: GetViperAssetQuery['viperAsset']['latestLifecycleEvent']['channel'];
}) => {
  const { t } = useTranslation('viper-asset-forms');
  const path = buildPath(Path.CUSTOMER_ASSET, { id: channel?.id });
  return {
    [LifecycleEventType.Installation]: [
      {
        title: t('status'),
        description: t('installed'),
        icon: 'secondary' as Highlight['icon'],
      },
      { title: t('field'), description: channel?.field?.name },
      {
        title: t('channel'),
        description: channel?.name,
        to: path,
      },
    ],
    [LifecycleEventType.Commissioning]: [
      {
        title: t('status'),
        description: t('commissioned'),
        icon: 'success' as Highlight['icon'],
      },
      { title: t('field'), description: channel?.field?.name },
      {
        title: t('channel'),
        description: channel?.name,
        to: path,
      },
    ],
    [LifecycleEventType.Decommissioning]: [
      {
        title: t('status'),
        description: t('decommissioned'),
        icon: 'warning' as Highlight['icon'],
      },
      { title: t('field'), description: channel?.field?.name },
      {
        title: t('channel'),
        description: channel?.name,
        to: path,
      },
    ],
    [LifecycleEventType.Uninstallation]: [
      {
        title: t('status'),
        description: t('uninstalled'),
        icon: 'neutral' as Highlight['icon'],
      },
    ],
  };
};
