import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateEditEntityDialog } from '~/components/CreateEditEntityDialog/CreateEditEntityDialog';
import { getMaintenanceLogCreateSchema } from '~/features/viper-asset/form-schemas/create/maintenanceLogCreateSchema';
import {
  useEditMaintenanceLog,
  useGetMaintenanceLogOptions,
} from '~/features/viper-asset/queries/viper-asset';
import { formatDate } from '~/utils/date-utils';
import { GetViperAssetQuery } from '~/graphql/generated/asset/graphql';
import { mapValuesToVariables } from '~/components/Form/utils/map-variables';

export const UpdateMaintenanceLogDialog: React.FC<{
  id: string;
  open: boolean;
  // @ts-expect-error type too deep for TS
  maintenanceLogs?: GetViperAssetQuery['viperAsset']['maintenanceLogs'];
  onClose: () => void;
  editId?: string;
}> = ({ open, onClose, id, maintenanceLogs, editId }) => {
  const { t } = useTranslation('viper-asset');
  const maintenanceLog = maintenanceLogs?.find(
    // @ts-expect-error type too deep for TS
    (log: GetViperAssetQuery['viperAsset']['maintenanceLogs']) => log.id === editId
  );

  return (
    <CreateEditEntityDialog
      open={open}
      onClose={onClose}
      title={t('edit-maintenance-log')}
      errorMessage={t(`error-editing-maintenance-log`)}
      successMessage={() => t('maintenance-log-edited-successfully')}
      useGetOptions={useGetMaintenanceLogOptions}
      useMutate={useEditMaintenanceLog}
      useMutateOptions={{ id }}
      getSchema={({ t, options }) =>
        getMaintenanceLogCreateSchema({ t, options, maintenanceLog }).fields[0].fields[0]
          .fields
      }
      handleMutate={async (update, values, fields) => {
        await update({
          variables: {
            id: editId as string,
            maintenanceLogInput: mapValuesToVariables(fields, values),
          },
        });
        return formatDate(values.maintenanceEndDate as string) as string;
      }}
      validate={({ maintenanceEndDate, maintenanceStartDate }) => {
        if (
          maintenanceEndDate &&
          maintenanceStartDate &&
          maintenanceEndDate < maintenanceStartDate
        ) {
          return {
            maintenanceEndDate: t('end-date-must-be-after-start-date'),
          };
        }
        return undefined;
      }}
    />
  );
};
