import {
  FormSpy,
  useFieldApi,
  UseFieldApiConfig,
} from '@data-driven-forms/react-form-renderer';
import { validationError } from '../validationError';
import { Box } from '@mui/material';
import { LabelledTextField } from '../LabelledTextField/LabelledTextField';

export const TextField = (props: UseFieldApiConfig) => {
  const { isDisabled, isRequired, validateOnMount, meta, sx, helperText, description, input } =
    useFieldApi(props);

  const invalid = validationError(meta, validateOnMount);

  return (
    <Box sx={sx}>
      <LabelledTextField
        {...input}
        {...props}
        error={!!invalid}
        required={isRequired}
        disabled={isDisabled}
        sx={sx}
        helperText={
          invalid ||
          ((meta.touched || validateOnMount) && meta.warning) ||
          helperText ||
          description
        }
      />
    </Box>
  );
};

export const EnhancedTextField = (props: UseFieldApiConfig) => (
  <FormSpy subscription={{ values: true, validating: true }}>
    {() => <TextField clearedValue={null} {...props} />}
  </FormSpy>
);
