import React, { useCallback } from 'react';
import { useCreateVLifeReportFormSchema } from '~/features/v-life-reports/form-schemas/create/useCreateVLifeReportFormSchema';
import { useTranslation } from 'react-i18next';
import { Path } from '~/routes/paths';
import { useNavigate, useParams } from 'react-router-dom';
import { FormGroup } from '~/components/Form/FormGroup';
import { Dialog } from '~/components/Dialog/Dialog';
import { SaveError, withErrorHandling } from '~/components/Form/SaveError/SaveError';
import { ReportFormGraphs } from '~/features/v-life-reports/components/ReportFormGraphs';
import { WarningList } from '~/components/WarningList/WarningList';
import { useVLifeReportsHistory } from '~/features/v-life-reports/hooks/useVLifeReportsHistory';
import { useCreateVLifeReportForm } from '~/features/v-life-reports/queries/v-life-reports';

export const CreateVLifeReport: React.FC = () => {
  const { t } = useTranslation('create-v-life-report-form');
  const { channelId } = useParams();
  const navigate = useNavigate();

  const {
    schema,
    loading,
    startDate,
    endDate,
    warnings,
    mostRecentViperAssetInstallationDate,
  } = useCreateVLifeReportFormSchema();
  const { previousStartDate, previousEndDate } = useVLifeReportsHistory(channelId);
  const [showPopup, setShowPopup] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [values, setValues] = React.useState<Record<string, unknown>>({});

  const onCancel = useCallback(() => navigate(Path.V_LIFE_REPORTS), [navigate]);
  const onSubmit = () => {
    setShowPopup(true);
  };

  const [createVLifeReportForm, createReportFormError] = withErrorHandling(
    useCreateVLifeReportForm()
  );

  const handleSave = useCallback(async () => {
    setSaving(true);

    try {
      const result = await createVLifeReportForm({
        variables: {
          vlifeReportFormInput: {
            channelId: channelId as string,
            customerName: values.customerName as string,
            platformName: values.platformName as string,
            fieldName: values.fieldName as string,
            channelName: values.channelName as string,
            insulationResistanceAtCommissioning: parseInt(
              values.insulationResistanceAtCommissioning as string
            ),
            insulationResistanceAtEndOfReportingPeriod:
              parseInt(values.insulationResistanceAtEndOfReportingPeriod as string) ?? 0,
            vlifeFirstAppliedDate: values.vlifeFirstAppliedDate as string,
            vlifeLicenceExpiryDate: values.vlifeLicenceExpiryDate as string,
            mostRecentViperAssetInstallationDate:
              mostRecentViperAssetInstallationDate as string,
            unitSerialNumbers: values.unitSerialNumbers as string[],
            currentFirmwareRevision: values.currentFirmwareRevision as string,
            overallIRIncrease: parseInt(values.overallIRIncrease as string),
            reportPeriodStartDate: values.reportPeriodStartDate as string,
            reportPeriodEndDate: values.reportPeriodEndDate as string,
            projectCode: values.projectCode as string,
            nextReportDue: values.nextReportDue as string,
            approverId: values.approver as string,
            suggestedReviewerId: values.suggestedReviewer as string,
            monthCovered: values.monthCovered as string,
            reportingPeriodChart: '',
            lifetimeDataChart: '',
            comment: values.comment as string,
            logs: [],
          },
        },
      });
      if (!result.errors) {
        navigate(Path.V_LIFE_REPORTS);
      }
    } finally {
      setShowPopup(false);
      setSaving(false);
    }
  }, [
    channelId,
    createVLifeReportForm,
    mostRecentViperAssetInstallationDate,
    navigate,
    values.approver,
    values.channelName,
    values.comment,
    values.currentFirmwareRevision,
    values.customerName,
    values.fieldName,
    values.insulationResistanceAtCommissioning,
    values.insulationResistanceAtEndOfReportingPeriod,
    values.monthCovered,
    values.nextReportDue,
    values.overallIRIncrease,
    values.platformName,
    values.projectCode,
    values.reportPeriodEndDate,
    values.reportPeriodStartDate,
    values.suggestedReviewer,
    values.unitSerialNumbers,
    values.vlifeFirstAppliedDate,
    values.vlifeLicenceExpiryDate,
  ]);

  const validator = useCallback(
    (values: Record<string, unknown>): undefined | Record<string, string> => {
      const errors: Record<string, string> = {};
      if ((values.reportPeriodStartDate as string) > (values.reportPeriodEndDate as string)) {
        errors.reportPeriodEndDate = t('end-date-must-be-after-start-date');
      }
      if ((values.reportPeriodEndDate as string) > (values.nextReportDue as string)) {
        errors.nextReportDue = t('next-report-date-must-be-after-end-date');
      }
      if (Object.keys(errors).length > 0) {
        return errors;
      }
    },
    [t]
  );

  return (
    <>
      <WarningList messages={warnings} sx={{ mb: warnings.length > 0 ? 3 : 0 }} />
      <ReportFormGraphs
        channelId={channelId}
        currentDates={{ startDate, endDate }}
        previousDates={{ startDate: previousStartDate, endDate: previousEndDate }}
      />
      <FormGroup
        data={schema}
        loading={loading}
        validator={validator}
        onCancel={onCancel}
        onSubmit={onSubmit}
        setValues={(values) => setValues(values)}
        saving={saving}
        submitButtonText={t('viper::create')}
      />
      <Dialog
        open={showPopup}
        onClose={() => setShowPopup(false)}
        title={t('confirm-title')}
        content={t('confirm-content')}
        confirmText={t('viper::sign')}
        cancelText={t('viper::cancel')}
        onConfirm={handleSave}
        loading={saving}
      />
      <SaveError errorState={createReportFormError}>
        {t('error-creating-v-life-report')}
      </SaveError>
    </>
  );
};
